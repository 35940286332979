import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library }         from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(far, fas, fab);

import { useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';
import PSAPIClient from './common/PSAPIClient';
import { enlargeImageTo1024, cropedImageTo1024, blankImage1024, getIcomImage1024 } from './common/PSCanvasUtil';

// 画像表示の幅
//  （スマフォ等で画面幅がこのサイズ以下なら、そのサイズが優先される）
const ComponentMaxWidth = 500;

const apiErrorMessage = (error) => {
  switch (error.response?.status) {
    case 400:
      return "入力値が正しくありません";
    case 401:
      return "ログインしてない、または確認パスワードが正しくありません";
    default:
      return "通信またはサーバーでエラーが発生しました";
  }
}

function IconLink({text, icon, onClick}) {
  return (
    <a href="#" onClick={e => {e.preventDefault();  onClick();}}
      style={{
        color: "#1676cf", fontWeight: "normal",
        fontSize: 12, marginLeft: 20} }>
      <FontAwesomeIcon icon={icon} /> {text}
    </a>
  );
}

function CropView({srcImage, imageSize, closeView}) {
  const ZoomMin = 1;
  const ZoomMax = 3;

  const [iconImage, setIconImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [cropSize, setCropSize] = useState(1024);
  const [area, setArea] = useState({x: 0, y: 0, width: 0, height: 0, zoom: ZoomMin});
  const [rangeZoom, setRangeZoom] = useState(0);

  const onCropComplete = (_croppedArea, croppedAreaPixels) => {
    setArea({...croppedAreaPixels, zoom});
  }

  const onMediaLoaded = (mediaSize) => {
    const h = mediaSize.height / (mediaSize.naturalHeight / 1024);

    setCropSize(h);
  }

  const onCloseButton = async (ok, e) => {
    e?.preventDefault();
    closeView(ok ? area : null);
  }

  const onChangeZoom = (e) => {
    const rangeValue = e.target.value;
    const zoom = (ZoomMax - ZoomMin) * rangeValue / 100 + ZoomMin;
    setRangeZoom(rangeValue);
    setZoom(zoom);
  }

  useEffect(() => {
    setIconImage(srcImage);
  }, [srcImage]);

  return (
    <>
      <Modal.Header closeButton className=" p-5 pb-4 border-bottom-0">
        <Modal.Title as="h3" className="fw-bold"><FontAwesomeIcon icon="fa-solid fa-store" /> ロゴ画像の切り取り</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{padding: 1}}>
      <div style={{position: 'relative', height: imageSize}}>
        <Cropper
          image={iconImage}
          crop={crop}
          zoom={zoom}
          aspect={1}
          cropShape="round"
          cropSize={{width: cropSize, height: cropSize}}
          showGrid={false}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          onMediaLoaded={onMediaLoaded}
        />
      </div>
      <Form.Range value={rangeZoom} onChange={onChangeZoom} className="mt-3 px-4"/>
      </Modal.Body>
      <Modal.Footer className="p-5 pt-2 pb-4 border-top-0 text-center">
        <Button variant="success" className="btn-lg w-100" onClick={e => {onCloseButton(true, e)}} >切り取り</Button>
      </Modal.Footer>
    </>
  );
}


const ImageUploader = ({setIconImage, setCropShow, backgroudImage, imageSize}) => {

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#fff',
    borderStyle: 'solid',
    outline: 'none',
    transition: 'border .3s ease-in-out',
    zIndex: 10,
    opacity: 0.7,
    position: 'absolute',
    width: '100%',
    height: '100%'
  };

  const acceptStyle = {
    borderColor: '#333'
  };

  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      if (!(file.type === "image/jpeg" || file.type === "image/png" )) {
        alert("Please use .jpg or .png");
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload =  async () => {
        setIconImage(reader.result);
        setIconImage(await enlargeImageTo1024(reader.result));
        setCropShow(true);
      };
    });//
  }

  const {getRootProps, getInputProps, isDragAccept} = useDropzone({onDrop})
  const style = {
    ...baseStyle,
    ...(isDragAccept ? acceptStyle : {})
  };
  const formStyle = {
    position: 'relative',
    height: imageSize, width: imageSize,
    backgroundColor: '#7f7f7f'
  };
  const backgroundImageStyle = {
    position: 'absolute',
    width: '100%', padding: 80,
    borderRadius: '50%', opacity: 1.0
  };
  const cameraIconStyle = {
    position: 'absolute',
    top: 'calc(50% - 20px)', left: 'calc(50% - 20px)'
  };

  return (
    <Form style={formStyle} >
     <img src={backgroudImage} style={backgroundImageStyle} />
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
      </div>
      <div style={cameraIconStyle}><FontAwesomeIcon size="3x" fixedWidth icon="fa-solid fa-camera" /></div>
    </Form>
  );
}


function ImageChangeDialog({show, onHide, imageUrl, apiBaseUrl, reloadPageIfSuccessful}) {
  const [iconImage, setIconImage] = useState(null);
  const [cropedImage, setCropedImage] = useState(null);
  const [currentIconImage, setCurrentIconImage] = useState(null);
  const [blankImage, setBlankImage] = useState(null);
  const [cropShow, setCropShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [componentWidth, setComponentWidth] = useState(ComponentMaxWidth);

  const onCropClose = async(area) => {
    setCropShow(false);
    if (area) {
      setCropedImage(await cropedImageTo1024(iconImage, area));
    }
  }

  const handleHide = () => {
    if (cropShow) {
      setCropShow(false);
    } else {
      onHide();
    }
  }
  const closeView = (e) => {
    e?.preventDefault();
    onHide();
  }

  const onSubmitImage = async (e) => {
    e.preventDefault();
    try {
      await PSAPIClient.put({url: `${apiBaseUrl}/company/logo`, data: {image: cropedImage}});
      closeView();
      if (reloadPageIfSuccessful) { window.location.reload(); }
    } catch (e) {
      console.error(e);
      setErrorMessage(apiErrorMessage(e));
    }
  }

  const resizeWindowHandler = () => {
    setComponentWidth(window.innerWidth < ComponentMaxWidth ? window.innerWidth : ComponentMaxWidth);
  }

  useEffect(() => {
    (async () => {
        if (imageUrl)
          setCurrentIconImage(await getIcomImage1024(imageUrl));
        else
          setBlankImage(await blankImage1024());
    })();

    resizeWindowHandler();
    window.addEventListener('resize', resizeWindowHandler);
    return () => {
      window.removeEventListener('resize', resizeWindowHandler);
    };
  }, [imageUrl]);


  const imageSize = componentWidth - 2;
  return (
    <Modal show={show} onHide={handleHide} animation={true} size="md">
      {cropShow ? <CropView srcImage={iconImage} imageSize={imageSize} closeView={onCropClose} />  :
        <>
          <Modal.Header closeButton className="p-5 pb-4 border-bottom-0">
            <Modal.Title as="h3" className="fw-bold"><FontAwesomeIcon icon="fa-solid fa-store" /> ロゴ画像の変更</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{padding: 0}}>
            {errorMessage.length > 0 && <Alert variant="warning">{errorMessage}</Alert>
            }
            <ImageUploader setIconImage={setIconImage} setCropShow={setCropShow}
                  imageSize={imageSize}
                  backgroudImage={cropedImage || currentIconImage || blankImage} />
          </Modal.Body>
          <Modal.Footer className="p-5 pt-2 pb-4 border-top-0 text-center">
            <Button variant="primary" disabled={cropedImage === null} className="btn-lg w-100" onClick={onSubmitImage} >変更する</Button>
          </Modal.Footer>
        </>
      }
    </Modal>
  )
}

export default function PSPublishLogoImageComponent({ company_id, api_base_url, s3_icon_image_url, reload_page_if_successful }) {
  const [show, setShow] = useState(false);
  const [existLogo, setExistLogo] = useState(false);

  const closeDialog = () => {
    setShow(false);
  }
  const showDialog = () => {
    setShow(true);
  }

  const deleteIconImage = async (e) => {
    if (!confirm("本当に削除して良いですか？")) return;

    try {
      const _response = await PSAPIClient.delete({url: `${api_base_url}/company/logo`});
      if (reload_page_if_successful) { window.location.reload(); }
    } catch (e) {
      console.error(e);
      setErrorMessage(apiErrorMessage(e));
    }
  }

  useEffect(() => {
    (async() => {
      const response = await PSAPIClient.get({url: `${api_base_url}/companies/`});
      setExistLogo(response.body.design?.exist_logo);
    })();
  }, []);

  if (existLogo) {
    const iconImageSRC = `${s3_icon_image_url}/${company_id}_400_400.jpg`;
    return (
      <>
        <img src={iconImageSRC} crossOrigin="anonymous" style={{width: 40, borderRadius: '50%'}}/>
        <IconLink text="ロゴ画像の変更" icon="fa-solid fa-circle-arrow-right" onClick={showDialog} />
        <IconLink text="ロゴ画像の削除" icon="fa-solid fa-trash-can" onClick={deleteIconImage} />
        <ImageChangeDialog  show={show} onHide={closeDialog} imageUrl={iconImageSRC} apiBaseUrl={api_base_url} reloadPageIfSuccessful={reload_page_if_successful} />
      </>
    );
  } else {
    return (
      <>
        <span style={{fontSize:10, padding:"3px 4px", borderRadius:3, color:"#FFFFFF", background:"#d36b00", fontWeight:"normal"}}>未設定</span>
        <span style={{margin:"0 0 0 3px", color:"#cc0000", fontSize:12, fontWeight:"normal"}}>設定されていません</span>
        <IconLink text="ロゴ画像の登録" icon="fa-solid fa-circle-arrow-right" onClick={showDialog} />
        <ImageChangeDialog  show={show} onHide={closeDialog} imageUrl={null} apiBaseUrl={api_base_url} reloadPageIfSuccessful={reload_page_if_successful} />
      </>
    );
  }
}

import { useState } from 'react'
//import axios from 'axios'

import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Table,
  Modal
} from 'react-bootstrap'

import React from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, far, fab)

import { FileUploader } from 'react-drag-drop-files'
const fileTypes = ['xlsx']

import PSAPIClient from '../common/PSAPIClient.js'

import styles from './PSPublishCopyrightUploadComponent.module.scss'

const PROCESS_STATUS_BRIGHT_NOTE_MUSIC = 2010
const PROCESS_STATUS_STUDIO_GHIBLI = 2020
const PROCESS_STATUS_NICHION_FOREIGN = 2035
const PROCESS_STATUS_YAMAHA_FOREIGN = 2045
const PROCESS_STATUS_YAMAHA_DISNEY = 2047
const PROCESS_STATUS_DWANGO = 2050
const PROCESS_STATUS_WATANABE = 2060
const PROCESS_STATUS_BUSHIROAD = 2070
const PROCESS_STATUS_ZENON = 2080
const PROCESS_STATUS_KYOKU_GEIZYUSU = 2090
const PROCESS_STATUS_SEVEN_SEAS = 2100
const PROCESS_STATUS_NHK = 2110
const PROCESS_STATUS_CENTRAL = 2120
const PROCESS_STATUS_SPACESHOWER = 2130
const PROCESS_STATUS_CRAYONHOUSE = 2140
const PROCESS_STATUS_SQUAREENIX = 2150
const PROCESS_STATUS_SONY_MUSIC_ARTISTS = 2160 // ソニーミュージックアーティスツ
const PROCESS_STATUS_MY_MUSIC = 2170    // マイミュージック / My Music
const PROCESS_STATUS_TV_ASAHI = 2180    // テレビ朝日
const PROCESS_STATUS_WONDERCITY = 2190  // ワンダーシティ

const OPERATION_TYPE_OVERWRITE = 10
const OPERATION_TYPE_ADD = 20
const OPERATION_TYPE_SKIP = 30
const OPERATION_TYPE_OVERWRITE_TEXT = '✨ 新規・上書き'
const OPERATION_TYPE_ADD_TEXT = '➕ 追記'
const OPERATION_TYPE_SKIP_TEXT = '❌ なにもしない'
const OPERATION_TYPE_OVERWRITE_LABEL = 'overwrite'
const OPERATION_TYPE_ADD_LABEL = 'add'
const OPERATION_TYPE_SKIP_LABEL = 'skip'
const OPERATION_TYPES = {
  [OPERATION_TYPE_OVERWRITE]: {
    text: OPERATION_TYPE_OVERWRITE_TEXT,
    label: OPERATION_TYPE_OVERWRITE_LABEL,
  },
  [OPERATION_TYPE_ADD]: {
    text: OPERATION_TYPE_ADD_TEXT,
    label: OPERATION_TYPE_ADD_LABEL,
  },
  [OPERATION_TYPE_SKIP]: {
    text: OPERATION_TYPE_SKIP_TEXT,
    label: OPERATION_TYPE_SKIP_LABEL,
  },
}

const apiOptions = {
  //baseURL: process.env.NEXT_PUBLIC_API_ENDPOINT + '/admin/copyright',
  responseType: 'json',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
}

export default function PSPublishCopyrightUploadComponent({ api_base_url }) {
  const [processStatus, setProcessStatus] = useState(PROCESS_STATUS_BRIGHT_NOTE_MUSIC)
  const [file, setFile] = useState(null)
  const [scores, setScores] = useState([])
  const [updateResults, setUpdateResults] = useState({
    [OPERATION_TYPE_ADD_LABEL]: 0,
    [OPERATION_TYPE_OVERWRITE_LABEL]: 0,
    [OPERATION_TYPE_SKIP_LABEL]: 0,
  })
  const [showCompleteModal, setShowCompleteModal] = useState(false)

  //apiOptions.baseURL = api_base_url + '/admin/copyright'

  const handleCloseCompleteModal = () => setShowCompleteModal(false)

  const onChangeProcessStatus = (e) => setProcessStatus(e.target.value)
  const onChangeFile = (file) => {
    //console.log(file.name)

    // ファイル名から出版社を推定
    {
      const fn = file.name.normalize('NFC') // macOSの場合、ファイル名がNFDなので、NFCに変換
      console.log(fn);

      if (fn.includes("ブライト")) { setProcessStatus(PROCESS_STATUS_BRIGHT_NOTE_MUSIC) }
      if (fn.includes("ジブリ")) { setProcessStatus(PROCESS_STATUS_STUDIO_GHIBLI) }
      if (fn.includes("日音")) { setProcessStatus(PROCESS_STATUS_NICHION_FOREIGN) }
      if (fn.includes("申請受付")) { setProcessStatus(PROCESS_STATUS_YAMAHA_FOREIGN) }
      if (fn.toLowerCase().includes("disney") || fn.includes("ディズニー")) { setProcessStatus(PROCESS_STATUS_YAMAHA_DISNEY) }
      if (fn.includes("ドワンゴ")) { setProcessStatus(PROCESS_STATUS_DWANGO) }
      if (fn.includes("ワタナベ")) { setProcessStatus(PROCESS_STATUS_WATANABE) }
      if (fn.includes("ブシロード")) { setProcessStatus(PROCESS_STATUS_BUSHIROAD) }
      if (fn.includes("全音")) { setProcessStatus(PROCESS_STATUS_ZENON) }
      if (fn.includes("教育芸術")) { setProcessStatus(PROCESS_STATUS_KYOKU_GEIZYUSU) }
      //if(fn.includes("セブン") || fn.includes("電子楽譜販売申請許諾表")) { setProcessStatus(PROCESS_STATUS_SEVEN_SEAS) }
      if (fn.includes("NHK")) { setProcessStatus(PROCESS_STATUS_NHK) }
      //if(fn.includes("セントラル")) { setProcessStatus(PROCESS_STATUS_CENTRAL) }
      if (fn.includes("シャワー")) { setProcessStatus(PROCESS_STATUS_SPACESHOWER) }
      if (fn.includes("クレヨン")) { setProcessStatus(PROCESS_STATUS_CRAYONHOUSE) }
      //if(fn.includes("エニクス")) { setProcessStatus(PROCESS_STATUS_SQUAREENIX)
      if (fn.includes("ソニー")) { setProcessStatus(PROCESS_STATUS_SONY_MUSIC_ARTISTS) }
      if (fn.includes("マイ")) { setProcessStatus(PROCESS_STATUS_MY_MUSIC) }
      if (fn.includes("朝日")) { setProcessStatus(PROCESS_STATUS_TV_ASAHI) }
      if (fn.includes("ワンダー")) { setProcessStatus(PROCESS_STATUS_WONDERCITY) }
    }

    setFile(file)
  }

  const handleValidateSubmit = async (e) => {
    e.preventDefault()

    let apiResponse = null

    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('processStatus', processStatus)

      const url = api_base_url + '/admin/copyright/validate'
      apiResponse = await PSAPIClient.postMultipart({ url, formData: formData })
      //console.log(apiResponse)
    } catch (e) {
      console.error(e)
      //console.info(e.response.data)
      //console.info(e.response)
      //console.info(e.message)

      window.alert(`エラーが発生しました：\n${e.message}`)

      throw e
    }

    // TODO: Error の場合の対応

    const resBody = apiResponse.body
    //console.log(resBody)      

    const DO_NOTHING_WORDS = ["権利", "。", "出版社"]
    let operationType = function (score) {
      const oldCPText = score.score.rightsLicensedText
      const newCPText = score.copyright.rightsLicensedText

      // 何もしない
      if (!newCPText) { return OPERATION_TYPE_SKIP }
      if (DO_NOTHING_WORDS.some(word => newCPText.includes(word))) { return OPERATION_TYPE_SKIP }
      if ((oldCPText && newCPText) && (oldCPText === newCPText)) { return OPERATION_TYPE_SKIP }

      // 新規・上書き
      if (!oldCPText && newCPText) { return OPERATION_TYPE_OVERWRITE }

      // 追記
      if ((oldCPText && newCPText) && (oldCPText != newCPText)) {
        return OPERATION_TYPE_ADD
      }

      return OPERATION_TYPE_SKIP
    }

    let message = function (score) {
      const oldCPText = score.score.rightsLicensedText
      const newCPText = score.copyright.rightsLicensedText

      // 何もしない
      if (!newCPText) { return "ファイルにコピーライトがありませんでした" }
      if (DO_NOTHING_WORDS.some(word => newCPText.includes(word))) { return "コピーライトではないテキストです" }
      if ((oldCPText && newCPText) && (oldCPText === newCPText)) { return "DB上のコピーライトと同じです" }

      // 新規・上書き
      if (!oldCPText && newCPText) { return "" }

      // 追記
      if (oldCPText && newCPText) {
        if (oldCPText != newCPText) {

          // if(oldCPText.includes(newCPText) || newCPText.includes(oldCPText)) {
          //   return "コピーライトが変更された可能性があります"
          // }

          return (<>⚠️ DB上のコピーライトと異なります<br />・変更→「新規・上書き」<br />・追加→「追記」<br />・後で検討→「何もしない」</>)
        }
      }

      return ""
    }

    //const scores = apiResponse.data.scores.map(score => ({
    const scores = resBody.scores.map(score => ({
      ...score,
      operationType: operationType(score),
      message: message(score),
    }))

    setScores(scores)
    setFile(null)
  }

  const handleUpdateSubmit = async (event) => {
    (async () => {
      event.preventDefault()

      try {
        const formData = new FormData()
        formData.append('processStatus', processStatus)
        formData.append('file', file)

        const data = {
          processStatus,
          scores: scores.map(score => ({
            scoreId: score.score.scoreId,
            operationType: score.operationType,
            copyright: {
              rightsLicensedText: score.copyright.rightsLicensedText,
              comment: score.copyright.comment,
            }
          }))
        }

        // const apiClient = axios.create(apiOptions)
        const url = api_base_url + '/admin/copyright/update'
        const apiResponse = await PSAPIClient.post({ url, data })

        // const apiResponse = await apiClient.post('/update', {
        //   processStatus,
        //   scores: scores.map(score => ({
        //     scoreId: score.score.scoreId,
        //     operationType: score.operationType,
        //     copyright: {
        //       rightsLicensedText: score.copyright.rightsLicensedText,
        //       comment: score.copyright.comment,
        //     }
        //   }))
        // })

        // TODO: Error の場合の対応

        //console.log(apiResponse)
        const resBody = apiResponse.body

        setScores([])
        setUpdateResults(resBody.resultNumber)
        setShowCompleteModal(true)
      } catch (e) {
        console.error(e)
        console.info(e.response.data)
        throw e
      }
    })()
  }

  const BASIC_SHEET_NAME = '申請用'
  const BASIC_COPYRIGHT_EXCEL_COLUMN = {
    rightsLicensedText: "E",		// コピーライト
    comment: "F",			// コメント・備考
    managementId: "O"		// Piascore 楽譜ID（t_score.management_id）
  }
  const processStatuses = [
    { id: PROCESS_STATUS_BRIGHT_NOTE_MUSIC, name: 'ブライト・ノート・ミュージック', sheetName: "ブライトノートミュージック様申請用", firstRow: 2, column: BASIC_COPYRIGHT_EXCEL_COLUMN },
    { id: PROCESS_STATUS_STUDIO_GHIBLI, name: 'スタジオジブリ', sheetName: BASIC_SHEET_NAME, firstRow: 2, column: BASIC_COPYRIGHT_EXCEL_COLUMN },
    { id: PROCESS_STATUS_NICHION_FOREIGN, name: '日音（外国曲）', sheetName: BASIC_SHEET_NAME, firstRow: 2, column: { rightsLicensedText: "I", comment: "J", managementId: "U" } },
    { id: PROCESS_STATUS_YAMAHA_FOREIGN, name: 'ヤマハ（外国曲）', sheetName: BASIC_SHEET_NAME, firstRow: 2, column: { rightsLicensedText: "J", comment: "K", managementId: "T" } },
    { id: PROCESS_STATUS_YAMAHA_DISNEY, name: 'ヤマハ（ディズニー）', sheetName: "titles", firstRow: 2, column: { rightsLicensedText: "F", comment: null, managementId: "G" } },
    { id: PROCESS_STATUS_DWANGO, name: 'ドワンゴ', sheetName: BASIC_SHEET_NAME, firstRow: 2, column: { rightsLicensedText: "G", comment: "H", managementId: "Q" } },
    { id: PROCESS_STATUS_WATANABE, name: 'ワタナベエンターテインメント', sheetName: BASIC_SHEET_NAME, firstRow: 2, column: BASIC_COPYRIGHT_EXCEL_COLUMN },
    { id: PROCESS_STATUS_BUSHIROAD, name: 'ブシロード', sheetName: BASIC_SHEET_NAME, firstRow: 2, column: BASIC_COPYRIGHT_EXCEL_COLUMN },
    { id: PROCESS_STATUS_ZENON, name: '全音', sheetName: BASIC_SHEET_NAME, firstRow: 2, column: BASIC_COPYRIGHT_EXCEL_COLUMN },
    { id: PROCESS_STATUS_KYOKU_GEIZYUSU, name: '教育芸術社', sheetName: BASIC_SHEET_NAME, firstRow: 2, column: BASIC_COPYRIGHT_EXCEL_COLUMN },
    { id: PROCESS_STATUS_SEVEN_SEAS, name: 'セブンシーズ', sheetName: "Piascore様許諾申請リスト", firstRow: 4, column: { rightsLicensedText: "Z", comment: null, managementId: null } },
    { id: PROCESS_STATUS_NHK, name: 'NHK出版', sheetName: "シート1", firstRow: 2, column: { rightsLicensedText: "S", comment: "T", managementId: "O" } },
    { id: PROCESS_STATUS_CENTRAL, name: 'セントラル', sheetName: BASIC_SHEET_NAME, firstRow: 2, column: BASIC_COPYRIGHT_EXCEL_COLUMN },
    { id: PROCESS_STATUS_SPACESHOWER, name: 'スペースシャワーネットワーク', sheetName: BASIC_SHEET_NAME, firstRow: 2, column: BASIC_COPYRIGHT_EXCEL_COLUMN },
    { id: PROCESS_STATUS_CRAYONHOUSE, name: 'クレヨンハウス', sheetName: "シート1", firstRow: 2, column: { rightsLicensedText: "V", comment: "U", managementId: null } },
    { id: PROCESS_STATUS_SQUAREENIX, name: 'スクウェア・エニックス', sheetName: BASIC_SHEET_NAME, firstRow: 2, column: BASIC_COPYRIGHT_EXCEL_COLUMN },
    { id: PROCESS_STATUS_SONY_MUSIC_ARTISTS, name: 'ソニーミュージックアーティスツ', sheetName: BASIC_SHEET_NAME, firstRow: 2, column: BASIC_COPYRIGHT_EXCEL_COLUMN },
    { id: PROCESS_STATUS_MY_MUSIC, name: 'マイミュージック', sheetName: BASIC_SHEET_NAME, firstRow: 2, column: BASIC_COPYRIGHT_EXCEL_COLUMN },
    { id: PROCESS_STATUS_TV_ASAHI, name: 'テレビ朝日', sheetName: BASIC_SHEET_NAME, firstRow: 2, column: BASIC_COPYRIGHT_EXCEL_COLUMN },
    { id: PROCESS_STATUS_WONDERCITY, name: 'ワンダーシティ', sheetName: BASIC_SHEET_NAME, firstRow: 2, column: BASIC_COPYRIGHT_EXCEL_COLUMN },
  ]

  return (
    <Container>
      <div className="align-items-center justify-content-center">
        <Row>
          <Col>
            <Card style={{ maxWidth: '100rem' }}>

              <Card.Body>
                <Form onSubmit={handleValidateSubmit} style={{ width: 500, margin: "0 auto" }}>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <FileUploader
                          multiple={false}
                          handleChange={onChangeFile}
                          name="file"
                          types={fileTypes}
                          required={true}
                          width="100%"
                          classes={styles.dropArea}
                          hoverTitle="ここにファイルをドロップ"
                        >
                          <div style={{ padding: "80px 0", background: "#F6F6F6" }}>
                            <p className={styles.bold}>コピーライトxlsxを選択</p>
                            <p>または</p>
                            <p className={styles.bold}>ドラッグ＆ドロップ</p>
                            <p>
                              {
                                file ? (
                                  <span>{file.name}を選択中</span>
                                ) : null
                              }
                            </p>
                          </div>
                        </FileUploader>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group className="mb-3">

                        <Form.Label>音楽出版社（ファイル名から音楽出版社を自動推定します）</Form.Label>
                        <Form.Select value={processStatus} onChange={onChangeProcessStatus} style={{ padding: 15 }}>
                          {
                            processStatuses.map((processStatus) => (
                              <option key={processStatus.id} value={processStatus.id}>{processStatus.id}：{processStatus.name}</option>
                            ))
                          }
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>

                  {
                    processStatuses.filter(p => { return p.id == processStatus }).length > 0 ? (
                      <Row style={{ marginTop: 10 }}>
                        <Col>
                          <div style={{ }}>必要な Excel ファイルの形式（{ processStatuses.filter(p => { return p.id == processStatus })[0].name }）</div>
                          <Table bordered hover style={{ width: "100%" }}>
                            <thead>
                              <tr style={{ background: "#EEEEEE" }}>
                                <th>シート名</th>
                                <th>データ開始行</th>
                                <th>コピーライト列</th>
                                <th>コメント列</th>
                                <th>管理ID列</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                processStatuses.filter(p => { return p.id == processStatus }).map((p) => (
                                  <tr>
                                    <td>{ p.sheetName}</td>
                                    <td>{ p.firstRow}行目から</td>
                                    <td>{ p.column.rightsLicensedText}列</td>
                                    <td>{ p.column.comment ? `${p.column.comment}列` : "なし"}</td>
                                    <td>{ p.column.managementId ? `${p.column.managementId}列` : "なし"}</td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    ) : ""
                  }

                  <Row>
                    <Col>
                      <div className="text-center" style={{ margin: "50px 0 0 0" }}>
                        <Button
                          disabled={!processStatus || !file}
                          onClick={handleValidateSubmit}
                          variant="primary"
                          size="lg"
                          style={{ padding: 20, width: 400 }}
                        >
                          アップロードする
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>

            </Card>
          </Col>
        </Row>
        {
          scores.length ? (
            <Row className='mt-4'>
              <Col>
                <Card style={{ maxWidth: '100rem' }}>
                  <Card.Body>
                    <Form>
                      <Table className={styles.scores}>
                        <thead>
                          <tr>
                            <th style={{ width: 40 }}></th>
                            <th style={{ width: 80 }}>楽譜ID</th>
                            <th style={{ minWidth: '200px' }}>楽譜名</th>
                            <th style={{ width: 110 }}>作品コード</th>
                            <th style={{ width: 250 }}>DB上のコピーライト</th>
                            <th style={{ width: 250 }}>Excel上のコピーライト</th>
                            <th style={{ width: 200 }}>コメント</th>
                            <th style={{ minWidth: '160px' }}>操作</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            scores.map((score, index) => (
                              <tr
                                key={`score-${score.score.scoreId}`}
                                className={`${OPERATION_TYPE_OVERWRITE === parseInt(score.operationType) ? styles.overwrite : ''} ${OPERATION_TYPE_SKIP === parseInt(score.operationType) ? styles.skip : ''} ${OPERATION_TYPE_ADD === parseInt(score.operationType) ? styles.add : ''}`}
                              >
                                <td style={{ fontSize: 12 }}>{index + 1}. </td>
                                <td>{score.score.managementId}</td>
                                <td><a className="link" target="_blank" href={`/scores/${score.score.scoreId}`}>{score.score.regions.ja.name}</a></td>
                                <td>{score.score.rightsManagementNumber.split("/").map(n => (<>{n}<br /></>))}</td>
                                <td>
                                  <div style={{ fontSize: 12 }} className={`${score.operationType == OPERATION_TYPE_SKIP ? styles.none : ""}`}>
                                    {
                                      score.score.rightsLicensedText
                                        ? score.score.rightsLicensedText.split('\n').map((row, index2) => {
                                          return <span key={`old-${index}-${index2}`}>{row}<br /></span>
                                        })
                                        : <div className={styles.none}>（空白）</div>
                                    }
                                  </div>
                                </td>
                                <td>
                                  <div style={{ fontSize: 12 }} className={`${score.operationType == OPERATION_TYPE_SKIP ? styles.none : ""}`}>
                                    {
                                      score.copyright.rightsLicensedText
                                        ? score.copyright.rightsLicensedText.split('\n').map((row, index2) => {
                                          return <span key={`new-${index}-${index2}`}>{row}<br /></span>
                                        })
                                        : <div className={styles.none}>（空白）</div>
                                    }
                                  </div>
                                  <div style={{ margin: "10px 0 0 0", color: "#CC0000", fontSize: 12 }}>
                                    {score.message}
                                  </div>
                                </td>
                                <td>{
                                  score.copyright.comment
                                    ? score.copyright.comment.split('\n').map((row, index2) => {
                                      return <div key={`comment-${index}-${index2}`} className={`${styles.comment} ${score.operationType == OPERATION_TYPE_SKIP ? styles.none : ""}`}>{row}<br /></div>
                                    })
                                    : <div className={styles.none}>（なし）</div>
                                }</td>
                                <td>
                                  <Form.Select
                                    onChange={(e) => {
                                      const newScores = [...scores]
                                      newScores[index].operationType = parseInt(e.target.value)
                                      setScores(newScores)
                                    }}
                                    defaultValue={score.operationType} style={{ fontSize: 12 }}
                                  >
                                    {
                                      Object.entries(OPERATION_TYPES).map(([key, value]) => (
                                        <option key={`score-${score.score.scoreId}-operationType-${key}`} value={key}>{value.text}</option>
                                      ))
                                    }
                                  </Form.Select>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                      <div className="text-center" style={{ margin: "30px 0 0 0" }}>
                        <Button
                          onClick={handleUpdateSubmit}
                          variant="danger"
                          size="lg"
                          style={{ padding: 20, width: 400 }}
                        >
                          更新する
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : null
        }
        <Modal
          show={showCompleteModal}
          onHide={handleCloseCompleteModal}
          size='lg'
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span className={styles.bold}>{Object.values(updateResults).reduce((sum, num) => sum + num, 0)}楽譜</span>のコピーライトの登録が完了しました🎉
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {
                Object.entries(updateResults).map(([key, value]) => (
                  <div key={`updateResults-${key}`}>
                    <p>{Object.values(OPERATION_TYPES).find(value => value.label === key).text}: <span className={styles.bold}>{value}件</span></p>
                  </div>
                ))
              }
            </div>

            <div>
              <a target="_blank" href={`/scores?process_status=${processStatus}`}>承認ページへ <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" /></a>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseCompleteModal}>
              閉じる
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Container>
  )
}

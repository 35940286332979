import { Container, Row, Col, Form, Button, Card, Table } from 'react-bootstrap'
import { useEffect, useState, useRef } from 'react'

import React from 'react';

import styles from './PSPublishAccountAddressFormComponent.module.scss'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library }         from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(far, fas, fab)

import PSAPIClient  from './common/PSAPIClient.js'
import PSTextUtil   from './common/PSTextUtil.js'
import PSURLUtil    from './common/PSURLUtil.js'

import PSRequiredIcomComponent from './common/PSRequiredIcomComponent.js'


export default function PSPublishAccountAddressFormComponent({ api_base_url, account_id }) {

  const [address, setAddress] = useState({
    postcode1:"",
    postcode2:"",
    prefecture_code:1,

    address1:"",
    address2:"",
    address3:"",
  })
  const [copiedAddress, setCopiedAddress] = useState({
    postcode1:"",
    postcode2:"",
    prefecture_code:1,

    address1:"",
    address2:"",
    address3:"",
  })
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(true);
  
  const addressModal = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        let url = api_base_url + "/accounts/address/"
        url = account_id ? url + account_id : url
        //console.log("url=" + url)

        const res = await PSAPIClient.get({url})
        //console.info(res)
        const body = res.body        
        //console.info(res.body)
        if (body.postcode1 != undefined) {
          for (let key in address) {
            if (body[key] === undefined) {
              body[key] = "";
            }
          }
          setAddress(body)
          setCopiedAddress(structuredClone(body));          
        }

        // モーダル表示
        {
          if(PSURLUtil.getParam("address_modal") == 1) {
            window.$(addressModal.current).modal("show");
          }
        }
      } catch (e) {
        console.error(e)
        console.info(e.response.data)
        throw e
      }
    })()
  }, [])

  const onSubmit = async (event) => {
    event.preventDefault();
    //console.log(address)
    
    setIsLoading(true)
    
    address.postcode1 = PSTextUtil.zenkaku2Hankaku({text:address.postcode1})
    address.postcode2 = PSTextUtil.zenkaku2Hankaku({text:address.postcode2})
    
    // PUT
    {
      let url = api_base_url + "/accounts/address/"
      url = account_id ? url + account_id : url
      const res = await PSAPIClient.put({url, data:address})
    }
        
    //
    //window.location.href = "/accounts";
    window.location.href = window.location.pathname; // パラメータを削除して同じURLに遷移する
  }
  
 const PrefectureCode = {
    1: "北海道",
    2: "青森県",
    3: "岩手県",
    4: "宮城県",
    5: "秋田県",
    6: "山形県",
    7: "福島県",
    8: "茨城県",
    9: "栃木県",
    10: "群馬県",
    11: "埼玉県",
    12: "千葉県",
    13: "東京都",
    14: "神奈川県",
    15: "新潟県",
    16: "富山県",
    17: "石川県",
    18: "福井県",
    19: "山梨県",
    20: "長野県",
    21: "岐阜県",
    22: "静岡県",
    23: "愛知県",
    24: "三重県",
    25: "滋賀県",
    26: "京都府",
    27: "大阪府",
    28: "兵庫県",
    29: "奈良県",
    30: "和歌山県",
    31: "鳥取県",
    32: "島根県",
    33: "岡山県",
    34: "広島県",
    35: "山口県",
    36: "徳島県",
    37: "香川県",
    38: "愛媛県",
    39: "高知県",
    40: "福岡県",
    41: "佐賀県",
    42: "長崎県",
    43: "熊本県",
    44: "大分県",
    45: "宮崎県",
    46: "鹿児島県",
    47: "沖縄県"
  };
 
  return (
    <>
      <PSPublishAccountAddressComponent address={copiedAddress} />

      <div ref={addressModal} id="address-modal" className="modal fade modal-sheet py-5" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content rounded-6 shadow">
            <div className="modal-header p-5 pb-4 border-bottom-0">
              <h3 className="fw-bold mb-0">
                <FontAwesomeIcon icon="fa-solid fa-store" />
                <span style={{ padding: "0 0 0 3px" }}>住所の変更</span>
              </h3>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />              
            </div>
  
            <form onSubmit={onSubmit}>
          
              <div className="modal-body p-5 pt-3 pb-3">

                <div className="mt-0 mb-4 text-center w-100 text-black-50" style={{ fontSize: 11 }} >
                  支払調書など法的書類に記載する場合もありますので、正確にご入力ください。
                </div>
    
                {/* 郵便番号 */}
                <div style={{"margin":"0px 0 0 0"}}>
                  <div>郵便番号 <PSRequiredIcomComponent /></div>
                  <div style={{"display":"flex", "justifyContent":"flex-start", "margin":"5px 0 0 0"}}>
                    <div className="mb-3">
                      <input type="input" className="form-control form-control-lg rounded-4" id="postcode1" minLength="3" maxLength="3" style={{width:90}} value={address.postcode1} onChange={e => { setAddress({...address, postcode1: e.target.value}) }} placeholder="123" required />
                    </div>
                    <div style={{padding:"15px 0 0 0"}}>－</div>
                    <div className="mb-3" style={{"margin":"0 0 0 0px"}} >
                      <input type="input" className="form-control form-control-lg rounded-4" id="postcode2" minLength="4" maxLength="4" style={{width:100}} value={address.postcode2} onChange={e => { setAddress({...address, postcode2: e.target.value}) }} placeholder="4567" required />
                    </div>
                  </div>
                </div>

                {/* 都道府県 */}
                <div style={{"margin":"15px 0 0 0"}}>
                  <div>都道府県 <PSRequiredIcomComponent /></div>
                  <div style={{"display":"flex", "justifyContent": "flex-start", "margin":"5px 0 0 0"}}>
                    <div className="mb-3">
                      <select className="form-select form-select-lg" value={address.prefecture_code} style={{"width":150}} onChange={e => { setAddress({...address, prefecture_code: e.target.value}) }}>
                        {
                          Object.keys(PrefectureCode).sort().map( key => (<option key={key} value={key}>{PrefectureCode[key]}</option>) )
                        }
                      </select>                      
                    </div>
                    
                  </div>
                </div>

                {/* 市町村区 */}
                <div style={{"margin":"15px 0 0 0"}}>
                  <div>市町村区 <PSRequiredIcomComponent /></div>
                  <div style={{"margin":"5px 0 0 0"}}>
                    <div className="mb-3">
                      <input type="input" className="form-control form-control-lg rounded-4" id="address1" value={address.address1} onChange={e => { setAddress({...address, address1: e.target.value}) }} placeholder="" required />
                    </div>
                  </div>
                </div>

                {/* 丁目・番地など */}
                <div style={{"margin":"10px 0 0 0"}}>
                  <div>丁目・番地など <PSRequiredIcomComponent /></div>
                  <div style={{"margin":"5px 0 0 0"}}>

                    <div className="mb-3">
                      <input type="input" className="form-control form-control-lg rounded-4" id="address2" value={address.address2} onChange={e => { setAddress({...address, address2: e.target.value}) }} placeholder="" required />
                    </div>
                  </div>
                </div>

                {/* マンション名・部屋番号など */}
                <div style={{"margin":"10px 0 0 0"}}>
                  <div>マンション名・部屋番号など</div>
                  <div style={{"margin":"5px 0 0 0"}}>

                    <div className="mb-3">
                      <input type="input" className="form-control form-control-lg rounded-4" id="address3" value={address.address3} onChange={e => { setAddress({...address, address3: e.target.value}) }} placeholder=""/>
                    </div>
                  </div>
                </div>

              </div>
              
              <div className="modal-footer border-top-0 p-5 pt-2 pb-6">
                { /* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">キャンセル</button> */ }
                <button type="submit" className="btn-lg w-100 btn-primary" id="submit_btn" disabled={isLoading} style={{background:"#0083EA", padding:15}} >変更する</button>
              </div>
              
            </form>
            
          </div>
        </div>
      </div>

    </>
  )      
}


function PSPublishAccountAddressComponent({ address }) {

  return (
    <div style={{display:"flex", alignItems:"center"}}>
      <div>
        { 
          address.postcode1 && address.postcode2 
          ? 
          (
            <div style={{fontWeight:"bold"}}>
              〒{address.postcode1}-{address.postcode2}<br />{address.prefecture_name} {address.address1} ...
            </div>
          )
          :
          (
            <>
              <span style={{fontSize:10, padding:"3px 4px", borderRadius:3, color:"#FFFFFF", background:"#d36b00" }}>未設定</span>
              <span style={{margin:"0 0 0 3px", color:"#cc0000", fontSize:12}}>設定されていません</span>
            </>
          )
        }
      </div>
      <div style={{margin:"0 0 0 20px"}}>
        <a className="" style={{color:"#1676cf", fontWeight:"normal", fontSize:12}} data-bs-target="#address-modal" data-bs-toggle="modal" href=""><FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" /> 住所の変更</a>
      </div>
    </div>
  ) 
}


import { Container, Row, Col, Form, Button, Card, Table } from 'react-bootstrap'
import { useEffect, useState, useRef } from 'react'

import React from 'react';

import styles from './PSPublishPaymentsRequestFormComponent.module.scss'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library }         from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(far, fas, fab)

import PSAPIClient  from '../common/PSAPIClient.js'
// import PSTextUtil   from '../common/PSTextUtil.js'
// import PSURLUtil    from '../common/PSURLUtil.js'

// import PSRequiredIcomComponent from '../common/PSRequiredIcomComponent.js'

export default function PSPublishPaymentsRequestFormComponent({ api_base_url, account_id }) {

  // const [messages, setMessages] = useState([
  //   {text:"担当者情報に不足があります", url:"/accounts?charge_modal=1"}
  // ])
  const [messages, setMessages] = useState([])
  
  const modal = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        let url1 = api_base_url + "/accounts/charge/"
        let url2 = api_base_url + "/accounts/address/"
        let url3 = api_base_url + "/accounts/tax_withholding/"
        url1 = account_id ? url1 + account_id : url1
        url2 = account_id ? url2 + account_id : url2
        url3 = account_id ? url3 + account_id : url3
        // console.log("url1=" + url1)
        // console.log("url2=" + url2)
        // console.log("url3=" + url3)

        const ress = await Promise.all([
          PSAPIClient.get({url:url1}),
          PSAPIClient.get({url:url2}),
          PSAPIClient.get({url:url3}),
        ])

        const body1 = ress[0].body        
        const body2 = ress[1].body        
        const body3 = ress[2].body        

        // console.log("body1=" + body1)
        // console.log("body2=" + body2)
        // console.log("body3=" + body3)

        const msgs = []
        if (body1 && !body1.family_name) { msgs.push({text:"担当者情報に不足があります", url:"/accounts?charge_modal=1"}) }
        if (body2 && !body2.address1) { msgs.push({text:"住所情報に不足があります", url:"/accounts?address_modal=1"}) }
        if (body3 && !body3.biz_type) { msgs.push({text:"源泉徴収情報に不足があります", url:"/accounts?tax_withholding_modal=1"}) }
        //console.log("messages[0]=" + messages[0].text)
        
        if (msgs.length > 0) {
          // console.log(msgs.length)
          setMessages(msgs)
          window.$(modal.current).modal("show");
        }
      } catch (e) {
        console.error(e)
        console.info(e.response.data)
        throw e
      }
    })()
  }, [])
   
  return (
    <>

      <div ref={modal} id="payment-warning-modal" data-bs-backdrop="static" className="modal fade modal-sheet py-5" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content rounded-6 shadow">
            <div className="modal-header p-5 pb-4 border-bottom-0">
              <h3 className="fw-bold mb-0" style={{fontSize:22}}>
                <span style={{color:"#CC0000"}}><FontAwesomeIcon icon="fa-solid fa-exclamation-triangle" /></span>
                <span style={{ padding: "0 0 0 3px" }}>お振込申請の前に、情報が必要です</span>
              </h3>
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />               */}
            </div>

            <div className="modal-body p-5 pt-0 pb-0">
              {
                messages.map((message, index) => {
                  return (
                    <div key={index} className="alert alert-warning" role="alert" style={{display:"flex", alignItems:"center"}}>
                      <div style={{width:240}}>
                        <span style={{ padding: "0 0 0 3px" }}>{message.text}</span>
                      </div>
                      <div>
                        <a target="_blank" href={message.url} className="btn btn-sm btn-outline-primary" style={{ marginLeft: 10 }}>情報を入力する</a>
                      </div>
                    </div>
                  )
                })
              }
            </div>

            <div className="modal-footer border-top-0 p-5 pt-2 pb-6">
              { /* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">キャンセル</button> */ }
              <a href="/payments" className="btn-lg w-100 btn-primary" style={{textAlign:"center", background:"#0083EA", padding:25}} >収支レポートに戻る</a>
            </div>

          </div>
        </div>
      </div>

    </>
  )      
}


import { Container, Row, Col, Form, Button, Card, Table } from 'react-bootstrap'
import { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'

import React from 'react';

import styles from './PSPublishScoreListComponent.module.scss'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(far, fas, fab)

import PSAPIClient  from '../common/PSAPIClient.js'

export default function PSPublishScoreListComponent({ scores, isAdmin=false, apiBaseURL }) {

  /*
  const [score, setScore] = useState({
    has_rights: false,
    rights_enable_publish: false,
    rights_enable_arrange: false,
    rights_group: '0',
    rights_management_number: '',
    rights_ivt: '',
    rights_translation: '0',
    rights_foreign: false,
    rights_licensed_text: ''
  });
  const [rightsChecked, setRightsChecked] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        let url = `${apiBaseURL}/accounts/score/${scoreId}`
        console.log("url=" + url)

        const res = await PSAPIClient.get({url})
        //console.info(res)
        const score = res.body
        console.info(score)
        
        setScore({
          has_rights: score.has_rights || false,
          rights_enable_publish: score.rights_enable_publish || false,
          rights_enable_arrange: score.rights_enable_arrange || false,
          rights_group: score.rights_group || '0',
          rights_management_number: score.rights_management_number || '',
          rights_ivt: score.rights_ivt || '',
          rights_translation: score.rights_translation || '0',
          rights_foreign: score.rights_foreign || false,
          rights_licensed_text: score.rights_licensed_text || ''
        })
        setRightsChecked(score.rights_enable_publish && score.rights_enable_arrange)
      } catch (e) {
        console.error(e)
        console.info(e.response.data)
        throw e
      }
    })()
  }, [])
  */
 
  const check = () => {
    const checkCount = document.querySelectorAll('.table :checked').length;
    if (checkCount === 0) {
      window.alert('チェックが入っていません');
      return false;
    } else {
      if (window.confirm(`${checkCount}曲の販売承認をしてもよろしいですか？`)) {
        return true;
      } else {
        window.alert('キャンセルされました');
        return false;
      }
    }
  };

  const openNewTabs = (ids) => {
    ids.forEach(id => window.open(`https://publish.piascore.com/scores/${id}`));
  };

  return (
    <div className="App" style={{ background: '#f0f1f4' }}>
      {/* <ScoreDetail id={id} /> */}
      <nav className="navbar navbar-expand-lg fixed-top bg-white" style={{ flexWrap: 'wrap', height: '62px', borderBottom: '1px solid #EEE' }}>
        <div className="container-fluid" style={{ width: '980px' }}>
          <a className="navbar-brand" style={{ display: 'flex', textDecoration: 'none' }} href="/">
            <img style={{ height: '41px', width: '41px', margin: 'auto' }} src="/images/logo/logo_152.png" alt="Logo 152" />
            <div style={{ margin: 'auto', fontWeight: 'bold', color: '#0083EA' }}>
              <div style={{ fontSize: '15px' }}>Piascore</div>
              <div style={{ fontSize: '10px' }}>楽譜販売</div>
            </div>
          </a>
          <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{ justifyContent: 'flex-start', flexDirection: 'row', marginBottom: '0!important' }}>
            <li className="nav-item"><a className="nav-link" href="https://publish.piascore.com/dashboard">ダッシュボード</a></li>
            <li className="nav-item"><a className="nav-link active" aria-current="page" href="https://publish.piascore.com/scores">楽譜一覧</a></li>
            <li className="nav-item"><a className="nav-link" href="https://publish.piascore.com/sales">売上レポート</a></li>
            <li className="nav-item" style={{ marginRight: '10px' }}><a className="nav-link" href="/payments">収支レポート</a></li>
          </ul>
          <ul className="d-flex navbar-nav" style={{ paddingTop: '8px', justifyContent: 'flex-start', flexDirection: 'row', marginBottom: '0!important' }}>
            <li style={{ padding: '10px 10px 0 0' }}><span style={{ background: '#00b50e', padding: '3px 5px', borderRadius: '3px', color: '#FFFFFF', fontSize: '10px' }}>管理者</span></li>
            <li className="nav-item dropdown">
              <span style={{ fontSize: '11px', lineHeight: '1.1em' }}>管理者<br /></span>
              <a aria-expanded="false" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" style={{ height: '20px', padding: '1px 1px 24px 0' }}>Piascore 楽譜管理さん</a>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="https://publish.piascore.com/accounts">アカウント情報</a></li>
                <li><a className="dropdown-item" href="/logout">ログアウト</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
      <div className="container" style={{ width: '100%', maxWidth: '100%' }}>
        <div style={{ marginTop: '100px' }}></div>
        <div className="breadcrumb-link" style={{ width: '980px', margin: '20px auto' }}></div>
        <div style={{ width: '980px', margin: '0px auto' }}>
          <div className="headline"><h1>楽譜一覧</h1></div>
          <div className="card" style={{ margin: '10px 0 0 0px' }}>
            <div className="card-header">
              <div style={{ height: '40px' }}>
                <div style={{ float: 'left', padding: '7px 0 0 0' }}>全楽譜管理
                  <span style={{ padding: '0 0 0 40px', fontSize: '10px' }}>表示 : </span>
                  <a className="view_item selected" href="https://publish.piascore.com/scores">全て</a>
                  <span className="status_delimiter">/</span>
                  <a className="view_item" href="https://publish.piascore.com/scores?process_status=0">新規</a>
                  <span className="status_delimiter">/</span>
                  <a className="view_item" href="https://publish.piascore.com/scores?process_status=10">承認待ち</a>
                  <span className="status_delimiter">/</span>
                  <a className="view_item" href="https://publish.piascore.com/scores?process_status=30">配信完了</a>
                  <span className="status_delimiter">/</span>
                  <a className="view_item" href="https://publish.piascore.com/scores?process_status=50">一時停止</a>
                  <span className="status_delimiter">/</span>
                  <a className="view_item" href="https://publish.piascore.com/scores?process_status=40">配信保留</a>
                  <span className="status_delimiter">/</span>
                  <a className="view_item" href="https://publish.piascore.com/scores?process_status=1000">申請却下</a>
                  <span style={{ padding: '0 0 0 30px', fontSize: '10px' }}>代理申請 : </span>
                </div>
                <div style={{ float: 'left', width: '200px', padding: '0 0 0 5px' }}>
                  <select className="form-select" name="select" onChange={(e) => window.location.href = e.target.value} style={{ height: '30px', fontSize: '14px' }}>
                    <option value="#">選択してください</option>
                    <option value="/scores?process_status=2010">2010：ブライト・ノート・ミュージック（旧ジャニーズ）</option>
                    <option value="/scores?process_status=2020">2020：スタジオジブリ</option>
                    <option value="/scores?process_status=2035">2035：日音（外国曲）</option>
                    <option value="/scores?process_status=2045">2045：ヤマハ（ディズニー以外の外国曲）</option>
                    <option value="/scores?process_status=2047">2047：ヤマハ（ディズニー作品）</option>
                    <option value="/scores?process_status=2050">2050：ドワンゴ</option>
                    <option value="/scores?process_status=2060">2060：ワタナベエンターテイメント</option>
                    <option value="/scores?process_status=2070">ブシロード</option>
                    <option value="/scores?process_status=2080">全音</option>
                    <option value="/scores?process_status=2090">教育芸術社</option>
                    <option value="/scores?process_status=2100">セブンシーズ</option>
                    <option value="/scores?process_status=2110">NHK出版</option>
                    <option value="/scores?process_status=2120">セントラルミュージック</option>
                    <option value="/scores?process_status=2130">スペースシャワーネットワーク</option>
                    <option value="/scores?process_status=2140">クレヨンハウス</option>
                    <option value="/scores?process_status=2150">スクウェアエニックス</option>
                    <option value="/scores?process_status=2160">ソニーミュージックアーティスツ</option>
                    <option value="/scores?process_status=2170">マイミュージック</option>
                    <option value="/scores?process_status=2180">テレビ朝日</option>
                    <option value="/scores?process_status=2190">ワンダーシティ</option>
                    <option value="/scores?process_status=2990">その他</option>
                  </select>
                </div>
                <span style={{ float: 'right', padding: '7px 0 0 0' }}>
                  <a name="csv" className="view_item" style={{ margin: '0px 5px' }} href="/scores.csv">
                    <span className="fa fa-download"></span>
                    <span style={{ margin: '0 2px' }}>ダウンロード</span>
                  </a>
                </span>
              </div>
              <div style={{ padding: '10px 0 0' }}>
                <form action="/scores" acceptCharset="UTF-8" method="get">
                  <input name="utf8" type="hidden" value="&#x2713;" />
                  <div style={{ padding: '0px', margin: '0px auto', width: '400px' }}>
                    <div style={{ float: 'left' }}>
                      <input type="text" name="keyword" id="keyword" className="form-control" style={{ width: '300px', height: '40px' }} placeholder="キーワード" />
                    </div>
                    <div style={{ float: 'left', margin: '0 0 0 5px' }}>
                      <input type="submit" value="検索" className="btn btn-primary" style={{ width: '70px', height: '40px' }} />
                    </div>
                    <div style={{ clear: 'both' }}></div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-body">
              <div style={{ fontSize: '12px', color: '#666' }}>楽譜数：261,580</div>
              <div style={{ textAlign: 'center' }}>
                <ul className="pagination">
                  <li className="page-item active"><a className="page-link" href="#">1</a></li>
                  <li className="page-item"><a rel="next" className="page-link" href="/scores?page=2">2</a></li>
                  <li className="page-item"><a className="page-link" href="/scores?page=3">3</a></li>
                  <li className="page-item"><a className="page-link" href="/scores?page=4">4</a></li>
                  <li className="page-item"><a className="page-link" href="/scores?page=5">5</a></li>
                  <li className="page-item disabled"><a className="page-link" href="#">...</a></li>
                  <li className="page-item"><a rel="next" className="page-link" href="/scores?page=2">›</a></li>
                  <li className="page-item"><a className="page-link" href="/scores?page=5232">»</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div style={{ padding: '0 20px 20px 20px', lineHeight: '2em', fontSize: '12px' }}>
            <div style={{ fontWeight: 'bold', color: '#BBB' }}>ストア一覧</div>
            <div>
              <div style={{ float: 'left' }}>
                <a href="/scores?company_id=2828&amp;process_status=10" style={{ color: '#0083EA' }} target="_blank">新田ピアノ教室</a> (14)
                <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
              </div>
              <div style={{ float: 'left' }}>
                <a href="/scores?company_id=6415&amp;process_status=10" style={{ color: '#0083EA' }} target="_blank">Shiki Music</a> (2)
                <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
              </div>
              <div style={{ float: 'left' }}>
                <a href="/scores?company_id=3732&amp;process_status=10" style={{ color: '#0083EA' }} target="_blank">Polepole Music</a> (2)
                <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
              </div>
              <div style={{ float: 'left' }}>
                <a href="/scores?company_id=5362&amp;process_status=10" style={{ color: '#0083EA' }} target="_blank">ドラム９２１</a> (1)
                <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
              </div>
              <div style={{ float: 'left' }}>
                <a href="/scores?company_id=5734&amp;process_status=10" style={{ color: '#0083EA' }} target="_blank">OneNote</a> (1)
                <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
              </div>
              <div style={{ float: 'left' }}>
                <a href="/scores?company_id=873&amp;process_status=10" style={{ color: '#0083EA' }} target="_blank">アカペラ同人楽譜屋さん</a> (2)
                <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
              </div>
              <div style={{ float: 'left' }}>
                <a href="/scores?company_id=1754&amp;process_status=10" style={{ color: '#0083EA' }} target="_blank">ukulelepapa　store</a> (1)
                <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
              </div>
              <div style={{ float: 'left' }}>
                <a href="/scores?company_id=4321&amp;process_status=10" style={{ color: '#0083EA' }} target="_blank">Arkadia Drums</a> (8)
                <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
              </div>
              <div style={{ float: 'left' }}>
                <a href="/scores?company_id=5015&amp;process_status=10" style={{ color: '#0083EA' }} target="_blank">ぴぽスコ</a> (2)
                <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
              </div>
              <div style={{ float: 'left' }}>
                <a href="/scores?company_id=5446&amp;process_status=10" style={{ color: '#0083EA' }} target="_blank">Hagetaka Drums</a> (1)
                <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
              </div>
              <div style={{ float: 'left' }}>
                <a href="/scores?company_id=5805&amp;process_status=10" style={{ color: '#0083EA' }} target="_blank">ゆにたぶ</a> (1)
                <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
              </div>
              <div style={{ float: 'left' }}>
                <a href="/scores?company_id=3348&amp;process_status=10" style={{ color: '#0083EA' }} target="_blank">GuitarTAB STORE</a> (1)
                <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
              </div>
              <div style={{ float: 'left' }}>
                <a href="/scores?company_id=5423&amp;process_status=10" style={{ color: '#0083EA' }} target="_blank">OZAKI SOUND CREATE</a> (2)
                <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
              </div>
              <div style={{ float: 'left' }}>
                <a href="/scores?company_id=6149&amp;process_status=10" style={{ color: '#0083EA' }} target="_blank">はちみつヨーグルト</a> (1)
                <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
              </div>
              <div style={{ float: 'left' }}>
                <a href="/scores?company_id=4068&amp;process_status=10" style={{ color: '#0083EA' }} target="_blank">sa-ke</a> (1)
                <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
              </div>
              <div style={{ float: 'left' }}>
                <a href="/scores?company_id=164&amp;process_status=10" style={{ color: '#0083EA' }} target="_blank">ドラムが好き！</a> (1)
                <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
              </div>
              <div style={{ float: 'left' }}>
                <a href="/scores?company_id=7105&amp;process_status=10" style={{ color: '#0083EA' }} target="_blank">Takako Jennings</a> (1)
                <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
              </div>
              <div style={{ float: 'left' }}>
                <a href="/scores?company_id=6112&amp;process_status=10" style={{ color: '#0083EA' }} target="_blank">tomoshibi no oto</a> (1)
                <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
              </div>
              <div style={{ float: 'left' }}>
                <a href="/scores?company_id=5513&amp;process_status=10" style={{ color: '#0083EA' }} target="_blank">Taiki Mizumoto</a> (1)
                <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
              </div>
              <div style={{ float: 'left' }}>
                <a href="/scores?company_id=1136&amp;process_status=10" style={{ color: '#0083EA' }} target="_blank">ギタースコア</a> (1)
                <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
              </div>
              <div style={{ float: 'left' }}>
                <a href="/scores?company_id=4964&amp;process_status=10" style={{ color: '#0083EA' }} target="_blank">はまなす</a> (5)
              </div>
              <div style={{ clear: 'both' }}></div>
            </div>
            <div style={{ padding: '0 20px 20px 20px', lineHeight: '2em', fontSize: '12px' }}>
              <div style={{ fontWeight: 'bold', color: '#BBB' }}>未登録作品</div>
              <div>
                <div style={{ float: 'left' }}></div>
                <div style={{ float: 'left' }}>
                  <a href="https://www2.jasrac.or.jp/eJwid/main?trxID=F20101&amp;WORKS_CD=02003279&amp;subSessionID=001&amp;subSession=start" style={{}} target="_blank">02003279</a>
                  <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
                </div>
                <div style={{ float: 'left' }}>
                  <a href="https://www2.jasrac.or.jp/eJwid/main?trxID=F20101&amp;WORKS_CD=02732823&amp;subSessionID=001&amp;subSession=start" style={{}} target="_blank">02732823</a>
                  <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
                </div>
                <div style={{ float: 'left' }}>
                  <a href="https://www2.jasrac.or.jp/eJwid/main?trxID=F20101&amp;WORKS_CD=04574265&amp;subSessionID=001&amp;subSession=start" style={{}} target="_blank">04574265</a>
                  <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
                </div>
                <div style={{ float: 'left' }}></div>
                <div style={{ float: 'left' }}>
                  <a href="https://www2.jasrac.or.jp/eJwid/main?trxID=F20101&amp;WORKS_CD=29701376&amp;subSessionID=001&amp;subSession=start" style={{}} target="_blank">29701376</a>
                  <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
                </div>
                <div style={{ float: 'left' }}>
                  <a href="https://www2.jasrac.or.jp/eJwid/main?trxID=F20101&amp;WORKS_CD=72045248&amp;subSessionID=001&amp;subSession=start" style={{}} target="_blank">72045248</a>
                  <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
                </div>
                <div style={{ float: 'left' }}>
                  <a href="https://www2.jasrac.or.jp/eJwid/main?trxID=F20101&amp;WORKS_CD=78029112&amp;subSessionID=001&amp;subSession=start" style={{}} target="_blank">78029112</a>
                  <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
                </div>
                <div style={{ float: 'left' }}>
                  <a href="https://www2.jasrac.or.jp/eJwid/main?trxID=F20101&amp;WORKS_CD=78082030&amp;subSessionID=001&amp;subSession=start" style={{}} target="_blank">78082030</a>
                  <span style={{ padding: '0 10px', color: '#CCC' }}> / </span>
                </div>
                <div style={{ float: 'left' }}></div>
                <div style={{ clear: 'both' }}></div>
              </div>
            </div>
          </div>

          <form onSubmit={check} action="/scores/multi_approve" acceptCharset="UTF-8" method="post">
            <input name="utf8" type="hidden" value="&#x2713;" />
            <input type="hidden" name="authenticity_token" value="Iism0yd39TIJUsSO7f7k+pA+pAWkxr/HljBgsFFZbQPhGGWSvfGl/Fd6gAIlIf8eVBshbPWv9Ux9Kx4P9JiAxw==" />
            <table className="table" style={{ width: '100%', tableLayout: 'fixed' }}>
              <thead>
                <tr>
                  <th className="table_order"></th>
                  <th style={{ width: '45px' }}></th>
                  <th className="table_management_id">楽譜ID</th>
                  <th>曲名</th>
                  <th className="table_movie"></th>
                  <th className="table_person">著作者</th>
                  <th className="table_price">価格</th>
                  <th className="table_rights_info" style={{ textAlign: 'center' }}>管理</th>
                  <th style={{ width: '85px' }}>ステータス</th>
                  <th className="table_store">ストア名</th>
                  <th className="table_edit">編集<br /><a onClick={() => openNewTabs([277048, 277047, 277046, 277045, 277044, 277043, 277042, 277041, 277040, 277039, 277038, 277037, 277036, 277035, 277034, 277033, 277032, 277031, 277030, 277029, 277028, 277027, 277026, 277025, 277024, 277023, 277022, 277021, 277020, 277019, 277018, 277017, 277016, 277015, 277014, 277013, 277012, 277011, 277008, 277007, 277006, 277004, 277003, 277001, 276999, 276998, 276997, 276996, 276995, 276994])} style={{ color: '#0083EA' }}>全て開く</a></th>
                  <th style={{ width: '50px', textAlign: 'center' }}><input type="submit" name="commit" value="承認" className="" style={{ width: '100%' }} /></th>
                </tr>
              </thead>
              <tbody style={{ borderTop: '2px solid #CCC' }}>
                <tr className="record-column">
                  <td className="table_order" style={{ textAlign: 'right', fontSize: '10px', verticalAlign: 'middle' }}>1.</td>
                  <td style={{ verticalAlign: 'middle', textAlign: 'center', padding: '10px 0px' }}>
                    <img className="bookcover" style={{ height: '50px', width: '36px', border: '1px solid #DDD' }} src="https://s3-ap-northeast-1.amazonaws.com/image.piascore.com/store/coverimage/app_s/hanon-part1-E.png" alt="Hanon part1 e" />
                    <br />
                    <span style={{ fontSize: '10px', padding: '3px 0 0 0', color: '#888' }}>277048</span>
                  </td>
                  <td className="table_management_id" style={{ verticalAlign: 'middle', fontSize: '10px', wordWrap: 'break-word' }}>hanon-part1-E</td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <div style={{ minHeight: '19px' }}><a style={{ color: '#0083EA', textDecoration: 'underline' }} href="/scores/277048">移調 ハノン 第1部 ホ長調</a></div>
                  </td>
                  <td className="table_movie"></td>
                  <td className="table_person" style={{ verticalAlign: 'middle', fontSize: '10px' }}>
                    <div>
                      <div className="item_header">曲：</div>
                      <div className="item_body">シャルル＝ルイ・ハノン</div>
                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <div>
                      <div className="item_header">詞：</div>
                      <div className="item_body"></div>
                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <div>
                      <div className="item_header">ア：</div>
                      <div className="item_body"></div>
                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <div>
                      <div className="item_header">編：</div>
                      <div className="item_body"></div>
                      <div style={{ clear: 'both' }}></div>
                    </div>
                  </td>
                  <td className="table_price" style={{ verticalAlign: 'middle', fontSize: '11px' }}><span style={{ padding: '4px' }}>¥120</span></td>
                  <td className="table_rights_info" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                    <div>
                      <div style={{ float: 'left' }}><span style={{ color: '#ffffff', backgroundColor: '#3dbb68', padding: '5px', borderRadius: '3px', fontSize: '11px', fontWeight: 'bold' }}>なし</span></div>
                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <div className="work_code" style={{ textAlign: 'left', padding: '8px 3px 3px 3px', fontSize: '8px', color: '#AAAAAA', width: '80px', wordBreak: 'break-all' }}></div>
                  </td>
                  <td style={{ verticalAlign: 'middle', fontSize: '11px', fontWeight: 'bold' }}><span>新規</span></td>
                  <td className="table_store" style={{ verticalAlign: 'middle', fontSize: '12px', lineHeight: '1.2em' }}>
                    <div><a href="/scores?company_id=2828" style={{ color: '#0083EA', fontSize: '10px' }}>新田ピアノ教室</a></div>
                    <div></div>
                  </td>
                  <td className="table_edit"><a className="edit_btn" href="/scores/277048"><span className="fa fa-edit" style={{ fontSize: '18px', color: '#0083EA' }}></span></a></td>
                  <td style={{ verticalAlign: 'middle', textAlign: 'center' }}><label className="approve_label"><input type="hidden" name="approve_score_0" id="approve_score_0" value="277048" /><input type="hidden" name="approve_score[0][score_id]" id="approve_score_0_score_id" value="277048" /><input type="hidden" name="approve_score[0][approve_flag]" id="approve_score_0_approve_flag" value="0" /><input type="checkbox" name="approve_score[0][approve_flag]" id="approve_score_0_approve_flag" value="1" /></label></td>
                </tr>
                <tr className="record-column">
                  <td className="table_order" style={{ textAlign: 'right', fontSize: '10px', verticalAlign: 'middle' }}>2.</td>
                  <td style={{ verticalAlign: 'middle', textAlign: 'center', padding: '10px 0px' }}>
                    <img className="bookcover" style={{ height: '50px', width: '36px', border: '1px solid #DDD' }} src="https://s3-ap-northeast-1.amazonaws.com/image.piascore.com/store/coverimage/app_s/hanon-part1-Des.png" alt="Hanon part1 des" />
                    <br />
                    <span style={{ fontSize: '10px', padding: '3px 0 0 0', color: '#888' }}>277047</span>
                  </td>
                  <td className="table_management_id" style={{ verticalAlign: 'middle', fontSize: '10px', wordWrap: 'break-word' }}>hanon-part1-Des</td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <div style={{ minHeight: '19px' }}><a style={{ color: '#0083EA', textDecoration: 'underline' }} href="/scores/277047">移調 ハノン 第1部 変ニ長調</a></div>
                  </td>
                  <td className="table_movie"></td>
                  <td className="table_person" style={{ verticalAlign: 'middle', fontSize: '10px' }}>
                    <div>
                      <div className="item_header">曲：</div>
                      <div className="item_body">シャルル＝ルイ・ハノン</div>
                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <div>
                      <div className="item_header">詞：</div>
                      <div className="item_body"></div>
                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <div>
                      <div className="item_header">ア：</div>
                      <div className="item_body"></div>
                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <div>
                      <div className="item_header">編：</div>
                      <div className="item_body"></div>
                      <div style={{ clear: 'both' }}></div>
                    </div>
                  </td>
                  <td className="table_price" style={{ verticalAlign: 'middle', fontSize: '11px' }}><span style={{ padding: '4px' }}>¥120</span></td>
                  <td className="table_rights_info" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                    <div>
                      <div style={{ float: 'left' }}><span style={{ color: '#ffffff', backgroundColor: '#3dbb68', padding: '5px', borderRadius: '3px', fontSize: '11px', fontWeight: 'bold' }}>なし</span></div>
                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <div className="work_code" style={{ textAlign: 'left', padding: '8px 3px 3px 3px', fontSize: '8px', color: '#AAAAAA', width: '80px', wordBreak: 'break-all' }}></div>
                  </td>
                  <td style={{ verticalAlign: 'middle', fontSize: '11px', fontWeight: 'bold' }}><span>新規</span></td>
                  <td className="table_store" style={{ verticalAlign: 'middle', fontSize: '12px', lineHeight: '1.2em' }}>
                    <div><a href="/scores?company_id=2828" style={{ color: '#0083EA', fontSize: '10px' }}>新田ピアノ教室</a></div>
                    <div></div>
                  </td>
                  <td className="table_edit"><a className="edit_btn" href="/scores/277047"><span className="fa fa-edit" style={{ fontSize: '18px', color: '#0083EA' }}></span></a></td>
                  <td style={{ verticalAlign: 'middle', textAlign: 'center' }}><label className="approve_label"><input type="hidden" name="approve_score_1" id="approve_score_1" value="277047" /><input type="hidden" name="approve_score[1][score_id]" id="approve_score_1_score_id" value="277047" /><input type="hidden" name="approve_score[1][approve_flag]" id="approve_score_1_approve_flag" value="0" /><input type="checkbox" name="approve_score[1][approve_flag]" id="approve_score_1_approve_flag" value="1" /></label></td>
                </tr>
                <tr className="record-column">
                  <td className="table_order" style={{ textAlign: 'right', fontSize: '10px', verticalAlign: 'middle' }}>3.</td>
                  <td style={{ verticalAlign: 'middle', textAlign: 'center', padding: '10px 0px' }}>
                    <img className="bookcover" style={{ height: '50px', width: '36px', border: '1px solid #DDD' }} src="https://s3-ap-northeast-1.amazonaws.com/image.piascore.com/store/coverimage/app_s/hanon-part1-D.png" alt="Hanon part1 d" />
                    <br />
                    <span style={{ fontSize: '10px', padding: '3px 0 0 0', color: '#888' }}>277046</span>
                  </td>
                  <td className="table_management_id" style={{ verticalAlign: 'middle', fontSize: '10px', wordWrap: 'break-word' }}>hanon-part1-D</td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <div style={{ minHeight: '19px' }}><a style={{ color: '#0083EA', textDecoration: 'underline' }} href="/scores/277046">移調 ハノン 第1部 ニ長調</a></div>
                  </td>
                  <td className="table_movie"></td>
                  <td className="table_person" style={{ verticalAlign: 'middle', fontSize: '10px' }}>
                    <div>
                      <div className="item_header">曲：</div>
                      <div className="item_body">シャルル＝ルイ・ハノン</div>
                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <div>
                      <div className="item_header">詞：</div>
                      <div className="item_body"></div>
                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <div>
                      <div className="item_header">ア：</div>
                      <div className="item_body"></div>
                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <div>
                      <div className="item_header">編：</div>
                      <div className="item_body"></div>
                      <div style={{ clear: 'both' }}></div>
                    </div>
                  </td>
                  <td className="table_price" style={{ verticalAlign: 'middle', fontSize: '11px' }}><span style={{ padding: '4px' }}>¥120</span></td>
                  <td className="table_rights_info" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                    <div>
                      <div style={{ float: 'left' }}><span style={{ color: '#ffffff', backgroundColor: '#3dbb68', padding: '5px', borderRadius: '3px', fontSize: '11px', fontWeight: 'bold' }}>なし</span></div>
                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <div className="work_code" style={{ textAlign: 'left', padding: '8px 3px 3px 3px', fontSize: '8px', color: '#AAAAAA', width: '80px', wordBreak: 'break-all' }}></div>
                  </td>
                  <td style={{ verticalAlign: 'middle', fontSize: '11px', fontWeight: 'bold' }}><span>新規</span></td>
                  <td className="table_store" style={{ verticalAlign: 'middle', fontSize: '12px', lineHeight: '1.2em' }}>
                    <div><a href="/scores?company_id=2828" style={{ color: '#0083EA', fontSize: '10px' }}>新田ピアノ教室</a></div>
                    <div></div>
                  </td>
                  <td className="table_edit"><a className="edit_btn" href="/scores/277046"><span className="fa fa-edit" style={{ fontSize: '18px', color: '#0083EA' }}></span></a></td>
                  <td style={{ verticalAlign: 'middle', textAlign: 'center' }}><label className="approve_label"><input type="hidden" name="approve_score_2" id="approve_score_2" value="277046" /><input type="hidden" name="approve_score[2][score_id]" id="approve_score_2_score_id" value="277046" /><input type="hidden" name="approve_score[2][approve_flag]" id="approve_score_2_approve_flag" value="0" /><input type="checkbox" name="approve_score[2][approve_flag]" id="approve_score_2_approve_flag" value="1" /></label></td>
                </tr>
                <tr className="record-column">
                  <td className="table_order" style={{ textAlign: 'right', fontSize: '10px', verticalAlign: 'middle' }}>50.</td>
                  <td style={{ verticalAlign: 'middle', textAlign: 'center', padding: '10px 0px' }}>
                    <img className="bookcover" style={{ height: '50px', width: '36px', border: '1px solid #DDD' }} src="https://s3-ap-northeast-1.amazonaws.com/image.piascore.com/store/coverimage/app_s/HB546.png" alt="Hb546" />
                    <br />
                    <span style={{ fontSize: '10px', padding: '3px 0 0 0', color: '#888' }}>276994</span>
                  </td>
                  <td className="table_management_id" style={{ verticalAlign: 'middle', fontSize: '10px', wordWrap: 'break-word' }}>HB546
                    <div style={{ color: '#888', margin: '3px 0 0 0' }}><i className="fas fa-eye" style={{ margin: '0 2px 0 0', color: '#CCC' }}></i>1</div>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <div style={{ minHeight: '19px' }}><a style={{ color: '#0083EA', textDecoration: 'underline' }} href="/scores/276994">旦那様とのラブラブ・ラブソング  / in B♭</a></div>
                  </td>
                  <td className="table_movie"></td>
                  <td className="table_person" style={{ verticalAlign: 'middle', fontSize: '10px' }}>
                    <div>
                      <div className="item_header">曲：</div>
                      <div className="item_body">金子 麻友美、他1人</div>
                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <div>
                      <div className="item_header">詞：</div>
                      <div className="item_body">金子 麻友美</div>
                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <div>
                      <div className="item_header">ア：</div>
                      <div className="item_body">釘宮 理恵</div>
                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <div>
                      <div className="item_header">編：</div>
                      <div className="item_body"></div>
                      <div style={{ clear: 'both' }}></div>
                    </div>
                  </td>
                  <td className="table_price" style={{ verticalAlign: 'middle', fontSize: '11px' }}><span style={{ padding: '4px' }}>¥180</span></td>
                  <td className="table_rights_info" style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                    <div>
                      <div style={{ float: 'left' }}><span style={{ color: '#ffffff', backgroundColor: '#faa94a', padding: '5px', borderRadius: '3px', fontSize: '11px', fontWeight: 'bold' }}>内国</span></div>
                      <div style={{ padding: '1px 0 1px 0', textAlign: 'center', width: '17px', height: '17px', borderRadius: '10px', margin: '1px 0 0 6px', fontSize: '10px', fontWeight: 'bold', color: '#0066CC', background: '#d4e9ff', float: 'left' }} title="JASRAC">J</div>
                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <div className="work_code" style={{ textAlign: 'left', padding: '8px 3px 3px 3px', fontSize: '8px', color: '#AAAAAA', width: '80px', wordBreak: 'break-all' }}>78239974</div>
                  </td>
                  <td style={{ verticalAlign: 'middle', fontSize: '11px', fontWeight: 'bold' }}><span style={{ color: '#00b5eb' }}>配信完了</span><br /><a href="https://store.piascore.com/scores/276994" style={{ color: '#0083EA', fontSize: '10px', fontWeight: 'normal', padding: '10px 0px' }} target="_blank">ストアへ <i className="fa fa-arrow-right"></i></a></td>
                  <td className="table_store" style={{ verticalAlign: 'middle', fontSize: '12px', lineHeight: '1.2em' }}>
                    <div><a href="/scores?company_id=4964" style={{ color: '#0083EA', fontSize: '10px' }}>はまなす</a></div>
                    <div></div>
                  </td>
                  <td className="table_edit"><a className="edit_btn" href="/scores/276994"><span className="fa fa-edit" style={{ fontSize: '18px', color: '#0083EA' }}></span></a></td>
                  <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>-</td>
                </tr>
              </tbody>
            </table>
          </form>
          <div style={{ textAlign: 'center' }}>
            <ul className="pagination">
              <li className="page-item active"><a className="page-link" href="#">1</a></li>
              <li className="page-item"><a rel="next" className="page-link" href="/scores?page=2">2</a></li>
              <li className="page-item"><a className="page-link" href="/scores?page=3">3</a></li>
              <li className="page-item"><a className="page-link" href="/scores?page=4">4</a></li>
              <li className="page-item"><a className="page-link" href="/scores?page=5">5</a></li>
              <li className="page-item disabled"><a className="page-link" href="#">...</a></li>
              <li className="page-item"><a rel="next" className="page-link" href="/scores?page=2">›</a></li>
              <li className="page-item"><a className="page-link" href="/scores?page=5232">»</a></li>
            </ul>
          </div>
        </div>
      </div>
     </div>
  );
};

import React, { useEffect, useState } from "react";
import { Modal, Form, Alert } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import PSAPIClient from './common/PSAPIClient';
import PSRequiredIcomComponent from './common/PSRequiredIcomComponent';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library }         from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, fab)


const bankType = {10: "普通", 20: "当座", 30: "定期"};

function StatusTextIcon({status}) {
  const statusText = {null: "", 10: "新規", 20: "確認中", 30: "承認済", 40: "却下"};
  const statusColor = {null: "", 10: "#d36b00", 20: "#c5aa00", 30: "#0a7d00", 40: "#cc0000"};

  if (!status) return (<></>);

  const iconStyle = {
    fontSize: 10,
    borderRadius: 3,
    background: statusColor[status],
    padding: '3px 4px',
    color:'#FFFFFF'
  };
  return (<span style={iconStyle}>{statusText[status]}</span>);
}

function ArrowLink({text, onClick}) {
  return (
    <a href="#" onClick={e => {e.preventDefault();  onClick();}}
      style={{
        color: "#1676cf", textDecoration: 'none !important',
        fontSize: 12, fontWeight: 'normal', marginLeft: 20} }>
      <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" /> {text}
    </a>
  );
}

export default function PSPublishAccountBankAccountFormComponent({ api_base_url }) {
  const [bankInfo, setBankInfo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [bankSelected, setBankSelected] = useState(null);
  const [branchSelected, setBranchSelected] = useState(null);
  const [accountType, setAccountType] = useState(null);
  const [accountNumber, setAccountNumber] = useState("");
  const [accountHolder, setAccountHolder] = useState("");

  const apiErrorMessage = (errorMessage = "") => {
    switch (errorMessage) {
      case 'Bad Request':
        return "入力値が正しくありません";
        case 'Unauthorized':
          return "ログインしてない、または確認パスワードが正しくありません";
        case 'Forbidden':
          return "処理が拒否されました";
        case 'Not Found':
          return "リソースが発見できませんでした";
        default:
          return "通信またはサーバーでエラーが発生しました";
    }
  }

  // 日本語入力中の全角英字を弾く
  const isRejectChar = (s) => s.match(/^[ａ-ｚＡ-Ｚ]+$/u);

  const getBankOptions = async (name) => {
    if (isRejectChar(name)) return [];

    const {body: banks} =  await PSAPIClient.get(
      {url: `${api_base_url}/accounts/bank/search_bank?keyword=${name}`});
    // console.log("+++ getBankOptions: ", name, banks);
    return  banks.map(b => ({value: b.code, label: b.name}));
  }

  const getBranchOptions = async (name) => {
    if (isRejectChar(name)) return [];

    const {body: branches} = await PSAPIClient.get(
      {url: `${api_base_url}/accounts/bank/search_branch?keyword=${name}&bankCode=${bankSelected.value}`});
    // console.log("+++ getBranchOptions: ", name, branches);
    return branches.map(b => ({value: b.code, label: b.name}));
  }

  const onChangeBank = (v) => {
    setBranchSelected(null)
    setBankSelected(v);
  }

  const onSubmitBankInfo = async () => {
    const bankInfo = {
      bankName: bankSelected.label,
      bankCode: bankSelected.value,
      branchName: branchSelected.label,
      branchCode: branchSelected.value,
      type: accountType,
      number: accountNumber,
      holder: accountHolder
    };

    const res = await PSAPIClient.put({url: api_base_url + '/accounts/bank', data: bankInfo});
    console.log("+++ put /accounts/bank ", bankInfo, res);
    if (res.body) {
      setBankInfo(res.body);
      setErrorMessage("");
      modalClose();
    } else if (res.message) {
      setErrorMessage(apiErrorMessage(res.message));
    } else {
      setErrorMessage(apiErrorMessage());
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const res = await PSAPIClient.get({url: api_base_url + '/accounts/bank'});
        console.log("+++ get /accounts/bank ", res);
        if (res.body) {
          setBankInfo(Object.keys(res.body).length > 0 ? res.body : null);
          setErrorMessage("");
        } else if (res.message) {
          setErrorMessage(apiErrorMessage(res.message));
        } else {
          setErrorMessage(apiErrorMessage());
        }
      } catch (e) {
        setErrorMessage(apiErrorMessage());
        console.error(e);
      }
    })();
  }, []);

  const modalShow = () => {
    if (bankInfo) {
      setBankSelected({label: bankInfo.bankName, value: bankInfo.bankCode});
      setBranchSelected({label: bankInfo.branchName, value: bankInfo.branchCode});
      setAccountType(bankInfo.type);
      setAccountNumber(bankInfo.number);
      setAccountHolder(bankInfo.holder);
    }
    setShowModal(true);
  }
  const modalClose = () => {
    setShowModal(false);
  }

  const selectStype = {
    control: (base) => ({
      ...base,
      paddingTop: 2,
      paddingBottom: 2,
      fontSize: 16
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: 15
    })
  };

  const remarkClassStyle = {
    className: "mt-2 mb-4 text-center w-100 text-black-50",
    style: {fontSize: 11}
  };

  return (
    <>
      <Modal show={showModal} onHide={modalClose} fullscreen="md-down" animation={true} contentClassName="rounded-4 shadow h-auto fs-6" centered>
        <Modal.Header closeButton  className="p-5 pb-4 border-bottom-0">
          <Modal.Title as="h3" className="fw-bold">
            <FontAwesomeIcon icon="fa-solid fa-building-columns" className="pe-2"/>
            銀行口座情報の変更
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={e => { e.preventDefault(); onSubmitBankInfo()}}>
          <Modal.Body className="px-5">
            {errorMessage.length > 0 &&
              <Alert variant="warning">{errorMessage}</Alert>
            }
            <div {...remarkClassStyle}>
              間違った情報が入力されますと、お振込ができない場合がございます。
              正確にご入力ください。
            </div>

            <Form.Group className="my-4">
              <Form.Label className="fw-bold">
                銀行名 <PSRequiredIcomComponent />
              </Form.Label>
              <AsyncSelect
                value={bankSelected}
                onChange={onChangeBank}
                cacheOptions
                loadOptions={getBankOptions}
                isClearable={true}
                required={true}
                placeholder="銀行名を入力、選択"
                noOptionsMessage={() => null}
                styles={selectStype}
              />
              <div {...remarkClassStyle}>
                銀行名の先頭文字を漢字、または読みがなをひらがなで入力すると候補が表示されるので選択して下さい。
              </div>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label className="fw-bold">
                支店名 <PSRequiredIcomComponent />
              </Form.Label>
              <AsyncSelect
                value={branchSelected}
                onChange={setBranchSelected}
                cacheOptions
                loadOptions={getBranchOptions}
                isClearable={true}
                required={true}
                placeholder="支店名を入力、選択"
                noOptionsMessage={() => null}
                styles={selectStype}
                isDisabled={bankSelected === null}
              />
              <div {...remarkClassStyle}>
              支店名の先頭文字を漢字、または読みがなをひらがなで入力すると候補が表示されるので選択して下さい。
              </div>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label className="fw-bold">
                種別  <PSRequiredIcomComponent />
              </Form.Label>
              <div>
                <Form.Check inline type="radio"  name="accountType"  label="普通"
                  defaultChecked={accountType == 10}  required
                  onChange={e => e.target.checked ? setAccountType(10) : null} />
                <Form.Check inline type="radio"  name="accountType" label="当座" className="ms-3"
                  defaultChecked={accountType == 20}  required
                  onChange={e => e.target.checked ? setAccountType(20) : null} />
              </div>
             </Form.Group>


            <Form.Group className="mb-4">
              <Form.Label className="fw-bold">
                口座番号  <PSRequiredIcomComponent />
              </Form.Label>
              <Form.Control type="text" className="fs-6 py-3 rounded-2 h-auto"
                required
                pattern="\d{6}"
                placeholder="口座番号"
                value={accountNumber} onChange={(e) => setAccountNumber(e.currentTarget.value)} />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label className="fw-bold">
                口座名義名  <PSRequiredIcomComponent />
              </Form.Label>
              <Form.Control type="text" className="fs-6 py-3 rounded-2 h-auto"
                required
                placeholder="口座名義名"
                  value={accountHolder} onChange={(e) => setAccountHolder(e.currentTarget.value)} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="p-5 pt-2 pb-4 border-top-0 text-center">
            <button type="submit" className="btn-lg w-100 btn-primary" id="submit_btn"  style={{background:"#0083EA", padding:15}}>変更する</button>
          </Modal.Footer>
        </Form>
      </Modal>

      <div>
        {errorMessage.length > 0 &&
          <Alert variant="warning">{errorMessage}</Alert>
        }
        {bankInfo ?
        <div style={{fontWeight: 'bold'}}>
          <div><StatusTextIcon status={bankInfo.status} /></div>
          <div>
            <span>{bankInfo.bankName}</span>
            <span style={{marginLeft: 20}}>{bankInfo.branchName}</span>
            <ArrowLink text="銀行口座の変更" onClick={modalShow} />
          </div>
          <div>{bankType[bankInfo.type]}</div>
          <div>{bankInfo.number}</div>
          <div>{bankInfo.holder}</div>
        </div> :
        <>
          <span style={{color: '#999', fontWeight: 'normal'}}>（登録がありません）</span>
          <ArrowLink text="銀行口座の変更" onClick={modalShow} />
        </>}
       </div>
    </>
  );
}
import { Container, Row, Col, Form, Button, Card, Table } from 'react-bootstrap'
import { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'

import React from 'react';

import styles from './PSPublishPurchaseEarningRateComponent.module.scss'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library }         from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(far, fas, fab)


export default function PSPublishPurchaseEarningRateComponent({ company_id, api_base_url }) {

  //console.log(company_id)

  const [company,            setCompany]            = useState({})
  const [earningRateVersion, setEarningRateVersion] = useState("")
  const [earningRateSet,     setEarningRateSet]     = useState({})

  useEffect(() => {
    (async () => {
      try {
        let url = api_base_url + "purchase/earning_rate"
        console.log("url=" + url)

        const apiResponse = await axios.get(url, {
          params: {
            company_id: company_id
          },
          withCredentials: true
        })
        //console.info(apiResponse)
        const result = apiResponse.data.body
        console.info(result)

        setCompany(result.company)
        setEarningRateVersion(result.earning_rate_version)
        setEarningRateSet(result.earning_rate_set)
      } catch (e) {
        console.error(e)
        console.info(e.response.data)
        throw e
      }
    })()
  }, [company_id])

  return (
    <div>
      {
        Object.keys(earningRateSet).length ?
          (
            <div className={ styles.earning_rate_table }>
              <Row className={ styles.title }>
                <Col>
                  <h2>
                    <span className={styles.icon}><FontAwesomeIcon icon="fas fa-percent" /></span>
                    報酬料率
                  </h2>
                </Col>
              </Row>

              <Row className={styles.header}>
                <Col>プラットフォーム</Col>
                <Col>決済</Col>

                <Col>著作権管理外<br /><span style={{fontWeight:"normal", fontSize:10}}>（PD/オリジナル）</span></Col>
                <Col>内国曲</Col>
                <Col>外国曲</Col>
              </Row>
              
              {
                earningRateSet.app ? (
                  <Row className={ styles.earning_rate } style={{borderBottom:"1px solid #dee2e6"}}>
                    <Col>アプリ</Col>
                    <Col>iOS</Col>
                    <Col>{ (earningRateSet.app.iOS.earning_rate.unmanaged * 100).toFixed(2) } %</Col>
                    <Col>{ (earningRateSet.app.iOS.earning_rate.japan * 100).toFixed(2) } %</Col>
                    <Col>{ (earningRateSet.app.iOS.earning_rate.foreign * 100).toFixed(2) } %</Col>
                  </Row>
                ) : ""
              }

              {
                earningRateSet.card ? (
                  <Row className={ styles.earning_rate }>
                    <Col>Web</Col>
                    <Col>クレジットカード</Col>
                    <Col>{ (earningRateSet.card.default.earning_rate.unmanaged * 100).toFixed(2) } %</Col>
                    <Col>{ (earningRateSet.card.default.earning_rate.japan * 100).toFixed(2) } %</Col>
                    <Col>{ (earningRateSet.card.default.earning_rate.foreign * 100).toFixed(2) } %</Col>
                  </Row>
                ) : ""
              }

              {
                earningRateSet.carrier ? (
                  <Row className={ styles.earning_rate }>
                    <Col></Col>
                    <Col>キャリア</Col>
                    <Col>{ (earningRateSet.carrier.default.earning_rate.unmanaged * 100).toFixed(2) } %</Col>
                    <Col>{ (earningRateSet.carrier.default.earning_rate.japan * 100).toFixed(2) } %</Col>
                    <Col>{ (earningRateSet.carrier.default.earning_rate.foreign * 100).toFixed(2) } %</Col>
                  </Row>
                ) : ""
              }

              {
                earningRateSet.id ? (
                  <Row className={ styles.earning_rate }>
                    <Col></Col>
                    <Col>PayPay/LinePay</Col>
                    <Col>{ (earningRateSet.id.default.earning_rate.unmanaged * 100).toFixed(2) } %</Col>
                    <Col>{ (earningRateSet.id.default.earning_rate.japan * 100).toFixed(2) } %</Col>
                    <Col>{ (earningRateSet.id.default.earning_rate.foreign * 100).toFixed(2) } %</Col>
                  </Row>
                ) : ""
              }

              {
                earningRateSet.cvs ? (
                  <Row className={ styles.earning_rate }>
                    <Col></Col>
                    <Col>コンビニ</Col>
                    <Col>{ (earningRateSet.cvs.default.earning_rate.unmanaged * 100).toFixed(2) } %</Col>
                    <Col>{ (earningRateSet.cvs.default.earning_rate.japan * 100).toFixed(2) } %</Col>
                    <Col>{ (earningRateSet.cvs.default.earning_rate.foreign * 100).toFixed(2) } %</Col>
                  </Row>
                ) : ""
              }
              <Row>
                <Col style={{fontSize:10, color:"#CCC", textAlign:"right"}}>{ earningRateVersion }</Col>
              </Row>
            </div>
          )
        : 
        (
          <div>
          </div>
        )
      }
   </div>      
  )      
}

import { Container, Row, Col, Form, Button, Card, Table } from 'react-bootstrap'
import { useEffect, useState, useRef } from 'react'

import React from 'react';

import styles from './PSPublishPaymentLogListComponent.module.scss'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library }         from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(far, fas, fab)

import PSAPIClient  from '../common/PSAPIClient.js'
import PSTextUtil   from '../common/PSTextUtil.js'
import PSTimeUtil   from '../common/PSTimeUtil.js'
import PSURLUtil    from '../common/PSURLUtil.js'

import PSRequiredIcomComponent from '../common/PSRequiredIcomComponent.js'


export default function PSPublishPaymentLogListComponent({ api_base_url, payment_logs, is_admin }) {

  const [address, setAddress] = useState({})

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       let url = api_base_url + "/accounts/address/"
  //       url = account_id ? url + account_id : url
  //       //console.log("url=" + url)

  //       const res = await PSAPIClient.get({url})
  //       //console.info(res)
  //       const body = res.body        
  //       //console.info(res.body)
  //       if (body.postcode1 != undefined) {
  //         for (let key in address) {
  //           if (body[key] === undefined) {
  //             body[key] = "";
  //           }
  //         }
  //         setAddress(body)
  //         setCopiedAddress(structuredClone(body));          
  //       }

  //       // モーダル表示
  //       {
  //         if(PSURLUtil.getParam("address_modal") == 1) {
  //           window.$(addressModal.current).modal("show");
  //         }
  //       }
  //     } catch (e) {
  //       console.error(e)
  //       console.info(e.response.data)
  //       throw e
  //     }
  //   })()
  // }, [])

  
  return (
    <>
      <link rel="stylesheet" href="https://unpkg.com/bootstrap-table@1.22.2/dist/bootstrap-table.min.css" />

      {/* <div>{payment_logs[0].payment_log_id}</div> */}
      <table className="table /*table-striped*/ table-hover payment_log" style={{"width":"100%"}}>
        <thead className={`table-light`} style={{ position:"sticky", top:60}}>
          <tr style={{height:80}}>
            <th style={{"width":100}}></th>
            <th className={`text-center`} style={{"width":170}}>振込申請時刻</th>
            { is_admin ?
              (
                <th style={{}}>ストア</th>
              ) : ""
            }
            <th className={`text-center`} style={{"width":120}}>報酬（税抜）</th>
            <th className={`text-center`} style={{"width":120}}>消費税</th>
            <th className={`text-center`} style={{"width":120}}>源泉徴収税額</th>
            <th className={`text-center`} style={{"width":100}}>手数料（税込）</th>
            <th className={`text-center`} style={{"width":120}}>お振込額</th>
            <th className={`text-center`} style={{"width":100}}></th>{ /* ステータス */ }
            <th className={`text-center`} style={{"width":78}}></th>{ /* 請求書 */ }
            { is_admin ?
              (
                <th className={`text-center`} style={{"width":78}}></th> 
              ) : ""
            }
          </tr>
        </thead>

        <tbody className="">
          {
            payment_logs.map((payment_log, index) => (
              <PSPublishPaymentLogListRowComponent payment_log={payment_log} is_admin={is_admin} api_base_url={api_base_url} key={index} />
            ))
          }
        </tbody>

      </table>
    </>
  )      
}

function PSPublishPaymentLogListRowComponent({payment_log, is_admin, api_base_url}) {
  
  const ready_to_send_mail  = is_admin && (payment_log.payment_status == 20 || payment_log.payment_status == 30 || payment_log.payment_status == 40)
  const sent_mail           = is_admin && (payment_log.payment_status == 50)
  const show_publisher_memo = (payment_log.payment_status == 20 || payment_log.payment_status == 30 || payment_log.payment_status == 40 || payment_log.payment_status == 5) && payment_log.publisher_memo
  const show_admin_memo     = payment_log.admin_memo
  
  let status_text      = ""
  let status_bg_color  = ""
  switch (payment_log.payment_status) {
    case null:
      // status_text      = "振込処理済"
      // status_bg_color  = "#0a7d00"
      break
    case 10: // unprocessed:          10, # 未処理
      status_text      = "未処理"
      status_bg_color  = "#a26e00"
      break
    case 20: // confirmed:            20, # 金額確定済み
      status_text      = "金額確定済"
      status_bg_color  = "#0060cc"
      break
    case 30: //transfer_processed:   30, # 振込手続き済
      status_text      = "振込手続済"
      status_bg_color  = "#5d007a"
      break
    case 40: // transfered:           40, # 振込済
      status_text      = "振込済"
      status_bg_color  = "#5d007a"
      break
    case 50: // sent_transfered_mail: 50  # 振込メール送信済
      status_text      = "振込済"
      status_bg_color  = "#0a7d00"
      break
  }

  return (
    <tr style={{ height:60, background:(ready_to_send_mail ? "rgb(247, 235, 240)" : "inherit") }}>
      <td className={`text-end`}>{ payment_log.payment_log_id }</td>
      <td className={`text-center`}>{ PSTimeUtil.toYYYYMMDDHHMM({timeString:payment_log.insert_time}) }</td>
      { is_admin ?
        (
          <td style={{fontSize:11}}><a target="_blank" href={`/payments?company_id=${payment_log.company.company_id}`}>{ payment_log.company.company_regions.filter(r => { return r.region_id==360})[0].name }</a></td>
        ) : ""
      }
      <td className={`text-end`}>{ (payment_log.consumption_tax != null) ? PSTextUtil.int2JPY({intValue:payment_log.unit_revenue_without_tax}) : "-" }</td>
      <td className={`text-end`}>{ (payment_log.consumption_tax != null) ? PSTextUtil.int2JPY({intValue:payment_log.consumption_tax}) : "-" }</td>
      <td className={`text-end`}>{ (payment_log.withholding != null && payment_log.withholding != 0) ? PSTextUtil.int2JPY({intValue:payment_log.withholding}) : "-" }</td>
      <td className={`text-end`}>{ (payment_log.commission != null  && payment_log.commission != 0)  ? PSTextUtil.int2JPY({intValue:payment_log.commission}) : "-"}</td>
      <td className={`text-end`}>{ PSTextUtil.int2JPY({intValue:-1 * payment_log.payment_value}) }</td>
      <td className={`text-center`}>
        <div>
          <span style={{padding:"4px 5px", fontSize:10, borderRadius:3, color:"#FFFFFF", background:status_bg_color}}>
            { status_text }
          </span>
        </div>
        {
          show_publisher_memo ?
          (
            <div style={{margin:"3px 0 0 0"}}>
              <span className="c-tooltip" style={{padding:"1px 5px", fontSize:10, borderRadius:3, color:"#FFFFFF", background:"#880000"}} data-tooltip={`${payment_log.publisher_memo}`}>お知らせ</span>
            </div>
          ) : ""
        }
        {
          show_admin_memo ?
          (
            <div style={{margin:"3px 0 0 0"}}>
              <span className="c-tooltip" style={{padding:"1px 5px", fontSize:10, borderRadius:3, color:"#FFFFFF", background:"#000088"}} data-tooltip={`${payment_log.admin_memo}`}>管理者メモ</span>
            </div>
          ) : ""
        }
      </td>      
      <td className={`text-center`}>
        { payment_log.can_download_invoice ?
          (
            <div style={{color:"#0083EA", fontSize:11}}>
              <FontAwesomeIcon icon="fa-solid fa-cloud-arrow-down" />
              <a href={`/payments/download_invoice?pid=${payment_log.payment_log_id}`} target="_blank" style={{marginLeft:3}}>請求書</a>
            </div>
          ) : ""
        }
      </td>
      
      { 
        is_admin ?
        (
          <td style={{fontSize:11}}>
            {  
              ready_to_send_mail ?
                (
                  <div style={{margin:"0 0 0 0"}}>
                    <a href={`${api_base_url}/accounts/payment/${payment_log.payment_log_id}/send_transfered_mail`} target="_blank" style={{background:"#CC0000", color:"#FFF", padding:"6px 6px", borderRadius:3}}>
                      <FontAwesomeIcon icon="fa-solid fa-envelope" />
                      <span style={{marginLeft:3}}>送信</span>
                    </a>
                  </div>                  
                )
              : ""
            }
            {
              sent_mail ?
              (
                <span style={{color:"#DDD"}}>
                  <FontAwesomeIcon icon="fa-solid fa-envelope" />
                  <span style={{marginLeft:3}}>送信済</span>
                </span>
              ) 
              : ""
            }
          </td>
        ) : ""
      }
    </tr>  
  )
}

import { Container, Row, Col, Form, Button, Card, Table } from 'react-bootstrap'
import { useEffect } from 'react'
import { useState } from 'react'

import React from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library }         from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(far, fas, fab)

import PSAPIClient  from './common/PSAPIClient.js'

import styles from './PSPublishPaymentLogComponent.module.scss'

export default function PSPublishPaymentLogComponent({ payment_log_id, api_base_url }) {

  //console.log(payment_log_id)

  const [paymentLog,  setPaymentLog]            = useState(null)

  useEffect(() => {
    (async () => {
      try {
        let url = api_base_url + "/accounts/payment/" + payment_log_id
        //console.log("url=" + url)

        const res = await PSAPIClient.get({url})
        //console.info(res)
        const result = res.body
        //console.info(result)

        setPaymentLog(result)
      } catch (e) {
        console.error(e)
        console.info(e.response.data)
        throw e
      }
    })()
  }, [])

  const PaymenTypes = {
    10: "購入",
    20: "振込",
  }
  
  const TaxTypes = {
    // 2023-10-01以前
    1010: "8%、インボイス制度開始前",
    1020: "10%、インボイス制度開始前",

    // 適格請求書発行事業者 (2023-10-01以降)
    2010: "10%、適格請求書発行事業者",

    // 非適格請求書発行事業者
    3010: "10%、非適格請求書発行事業者、80%控除",
    3020: "10%、非適格請求書発行事業者、50%控除",
    3030: "10%、非適格請求書発行事業者、控除なし"
  }
    
  return (
    <>
    { 
      (paymentLog == null) ?
      "" :
      (
        <div className={styles.payment_log} style={{background:"#FFF", padding:20, border:"1px solid #CCC", lineHeight:"1.8em"}}>
          <div style={{fontSize:16}}>
            <div>{ paymentLog.payment_log_id }</div>
            <div><a href={`https://store.piascore.com/publishers/${paymentLog.company.company_id}`} target="_blank">{ paymentLog.company.regions.ja.name }</a></div>

            <div>{ PaymenTypes[paymentLog.payment_type] }</div>
            <div>{ paymentLog.is_transfered }</div>
            <div>{ paymentLog.payment_status }</div>

            <div style={{display:"flex", fontSize:11, color:"#888"}}>
              <div>作成：</div>
              <div>{ new Date(paymentLog.insert_time).toLocaleString() }</div>
              <div style={{margin:"0 0 0 20px"}}>更新：</div>
              <div>{ new Date(paymentLog.update_time).toLocaleString() }</div>
            </div>

            <div style={{margin:"20px 0 0 0"}} className={styles.header}>
              <div className={`${styles.row}`}>
                <div className={styles.tax_type}>税率タイプ</div>
                <div className={styles.sum_revenue_with_tax}>報酬（税込）</div>
                <div className={styles.sum_revenue_without_tax}>報酬（税抜）</div>
                <div className={styles.tax_value}>消費税</div>

                <div className={styles.withholding}>源泉徴収税額</div>
                <div className={styles.commission}>手数料</div>
                <div className={styles.payment_value}>お振込み額</div>
              </div>
            </div>

            <div className={styles.payment_tax}>
            { 
              paymentLog.payment_taxes.map((payment_tax) => {
                return (
                  <div className={styles.row}>
                    <div className={styles.tax_type}>{ TaxTypes[payment_tax.tax_type] }</div>
                    <div className={styles.sum_revenue_with_tax}>¥{ payment_tax.sum_revenue_with_tax.toLocaleString() }</div>
                    <div className={styles.sum_revenue_without_tax}>¥{ payment_tax.sum_revenue_without_tax.toLocaleString() }</div>
                    <div className={styles.tax_value}>¥{ payment_tax.tax_value.toLocaleString() }</div>
                  </div>
              )
              })
            }
            </div>

            <div style={{margin:"5px 0 0 0"}} className={styles.sum}>
              <div className={styles.row}>
                <div className={styles.tax_type}>計</div>
                <div className={styles.sum_revenue_with_tax}>¥{ paymentLog.unit_revenue_with_tax.toLocaleString() }</div>
                <div className={styles.sum_revenue_without_tax}>¥{ paymentLog.unit_revenue_without_tax.toLocaleString() }</div>
                <div className={styles.tax_value}>¥{ paymentLog.consumption_tax.toLocaleString() }</div>

                <div className={styles.withholding}>¥{ paymentLog.withholding.toLocaleString() }</div>
                <div className={styles.commission}>¥{ paymentLog.commission.toLocaleString() }</div>
                <div className={styles.payment_value}>¥{ (-1 * paymentLog.payment_value).toLocaleString() }</div>
              </div>
            </div>
            
            
            <div>¥{ paymentLog.balance.toLocaleString() }</div>
            <div>¥{ paymentLog.balance_with_tax.toLocaleString() }</div>
            <div>¥{ paymentLog.balance_without_tax.toLocaleString() }</div>
                        
            <div>{ paymentLog.publisher_memo }</div>
            <div>{ paymentLog.admin_memo }</div>
          </div>      
        </div>      
      )
    }
    </>
  )


}
import { Container, Row, Col, Form, Button, Card, Table } from 'react-bootstrap'
import { useEffect, useState, useRef } from 'react'

import React from 'react';

import styles from './PSPublishScoreDuplicateFormComponent.module.scss'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library }         from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(far, fas, fab)

import PSAPIClient  from '../common/PSAPIClient.js'
import PSURLUtil    from '../common/PSURLUtil.js'

import PSRequiredIcomComponent from '../common/PSRequiredIcomComponent.js'

export default function PSPublishScoreDuplicateFormComponent({ api_base_url, scoreId, managementId, title, jpyPriceWithTax }) {

  const validateManagementId = async (value) => {
    //console.log(value)
  
    // GET
    {
      let url = api_base_url + "/scores/check_management_id/" + encodeURIComponent(value)
      console.log(url)
      
      let valid = false
      try {
        const res = await PSAPIClient.get({url, data:formData})
        //console.log(res)
        
        valid = (res.body?.can_use_management_id == true)
      }catch(e){
        console.error(e)
      }
  
      return valid ? ValidManagementIdStatus.valid : ValidManagementIdStatus.invalid
      
      // setShowValidManagementIdMessage(valid)
      // setShowInvalidManagementIdMessage(!valid)
  
      // setDisableSubmit(!valid || showInvalidJPYPriceWithTaxMessage)
    }
  }
  
  const validateJPYPrice = (value) => {
    //console.log(value)
  
    let valid = false
    if(value==0) { 
      valid = true
    }else if(value >= 120 && value < 10000) {
      valid = true
    }
    
    return valid
    
    // setShowInvalidJPYPriceWithTaxMessage(!valid)
  
    // //setDisableSubmit(showInvalidManagementIdMessage || showInvalidJPYPriceWithTaxMessage)
    // setDisableSubmit(showInvalidManagementIdMessage || !valid)
  }
  
  const ValidManagementIdStatus = {
    unknown: 0,
    valid: 10,
    invalid: 20
  }
  
  const [formData, setFormData] = useState({
    management_id: managementId,
    jpy_price_with_tax: jpyPriceWithTax
  })
  const [enableSubmit, setEnableSubmit] = useState(true)

  // managementId
  // const [showValidManagementIdMessage,   setShowValidManagementIdMessage]   = useState(false)
  // const [showInvalidManagementIdMessage, setShowInvalidManagementIdMessage] = useState(false)
  const [validManagementIdStatus, setValidManagementIdStatus] = useState(ValidManagementIdStatus.unknown)

  // price
  const [validJPYPriceWithTax, setValidJPYPriceWithTax] = useState(validateJPYPrice(jpyPriceWithTax))
  
  const [newScore, setNewScore] = useState({})
    
  const duplicateScoreModal       = useRef(null);
  const duplicateScoreStatusModal = useRef(null);
  
  useEffect(() => {
    (async () => {
      try {
        setFormData(formData)
      } catch (e) {
        console.error(e)
        throw e
      }
    })()
  }, [])

  const onSubmit = async (event) => {
    event.preventDefault();
    
    if(!managementId) {
      alert("ストアの楽譜IDは必須です")
      return
    }

    setEnableSubmit(false)
    
    // PUT
    {
      let url = api_base_url + "/scores/" + scoreId + "/duplicate"
      try {
        const res = await PSAPIClient.post({url, data:formData})
        console.log(res)

        setNewScore(res.body)

        setEnableSubmit(true)
        setValidManagementIdStatus(ValidManagementIdStatus.unknown)
        
        window.$(duplicateScoreModal.current).modal("hide");
        window.$(duplicateScoreStatusModal.current).modal("show");
    
      }catch(e){
        console.error(e)
        alert("エラーが発生しました")
      }
    }
  }

  // const isEnableSubmit = () => {
    
  //   return validateJPYPrice
  // }

  return (
    <>
      <PSPublishScoreDuplicateComponent />

      {/* <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Show a second modal and hide this one with the button below.
            </div>
            <div className="modal-footer">
              <button className="btn btn-primary" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal">Open second modal</button>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div id="duplicateScore-modal" className="modal fade modal-sheet py-5" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true"> */}
      <div ref={duplicateScoreStatusModal} className="modal fade modal-sheet py-5" id="duplicateScoreStatus-modal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content rounded-6 shadow">
            
            <div className="modal-header p-5 pb-4 border-bottom-0">
              <h3 className="fw-bold mb-0" style={{color:"#008800"}}>
              <FontAwesomeIcon icon="fa-solid fa-circle-check" />
                <span style={{ padding: "0 0 0 3px" }}>複製に成功しました</span>
              </h3>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            
            <div className="modal-body p-5 pt-3 pb-3" style={{lineHeight:"1.6em", fontSize:16}}>
              <div style={{fontWeight:"bold"}}> {newScore.regions?.ja?.name}</div>
              <div>楽譜ID：{newScore.management_id}</div>
            </div>
            
            <div className="modal-footer border-top-0 p-5 pt-3 pb-6" style={{justifyContent:"center"}}>
              <div>
                <button className="btn btn-success" data-bs-target="#duplicateScore-modal" data-bs-toggle="modal">更に複製する</button>
              </div>
              <div>
                <a className="btn btn-primary" target="_blank" href={ "/scores/" + newScore.score_id }>複製した楽譜を表示する</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div ref={duplicateScoreModal} id="duplicateScore-modal" className="modal fade modal-sheet py-5" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content rounded-6 shadow">
            <div className="modal-header p-5 pb-4 border-bottom-0">
              <h3 className="fw-bold mb-0">
                <FontAwesomeIcon icon="fa-solid fa-clone" />
                <span style={{ padding: "0 0 0 3px" }}>楽譜データの複製</span>
              </h3>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />              
            </div>
  
            <form onSubmit={onSubmit}>
          
              <div className="modal-body p-5 pt-3 pb-3" style={{lineHeight:"1.6em", fontSize:15}}>

                <div style={{fontSize:13}}>
                  「<a target="_blank" href={"/scores/" + scoreId}>{ title }（{ managementId }） <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" /></a>」を複製します。
                  <br />
                  （楽譜名や人名、ジャンルなど楽譜情報のみ。PDFや表紙画像は複製しません）
                </div>

                <div style={{margin:"20px 0 0 0", padding:"20px 10px", background:"#f6f5f2"}}>
                  <div style={{margin:"0 0 0 10px"}}>
                    <h4 style={{fontWeight:"bold", fontSize:14}}>複製先の楽譜情報</h4>
                  </div>
                  
                  <div style={{margin:"0px 0 0 0", display:"flex"}}>
                    <div style={{width:160, textAlign:"right", padding:"10px 0 0 0"}}>
                      <label htmlFor="managementId" className="form-check-label" style={{margin:"0 3px 0 0"}}>ストアの楽譜ID</label>
                      <PSRequiredIcomComponent />
                    </div>
                    <div style={{margin:"0 0 0 10px", width:210}}>
                      <div>
                        <input id="managementId" className="form-control form-control-lg rounded-4" type="input" name="managementId" style={{fontSize:16}} value={formData.management_id ? formData.management_id : ""} 
                          onBlur={ async e => setValidManagementIdStatus(await validateManagementId(e.target.value)) } 
                          onChange={e => { setFormData({...formData, management_id: e.target.value}) }} 
                          placeholder='PS000001'
                        />
                      </div>

                      <div style={{display:((validManagementIdStatus == ValidManagementIdStatus.valid) ? "block" : "none"), color:"#00AA00", fontWeight:"bold", fontSize:12 }}>
                        <FontAwesomeIcon icon="fa-solid fa-circle-check" /> 使用できます
                      </div>

                      <div style={{display:((validManagementIdStatus == ValidManagementIdStatus.invalid) ? "block" : "none"), color:"#CC0000", fontWeight:"bold", fontSize:12 }}>
                        使用できません。<br />使えるのは、システム上重複しないID、英数大文字・小文字、「-」「_」のみ。「piascore」を含めることはできません。
                      </div>
                    </div>                    
                  </div>                        

                  <div style={{margin:"20px 0 0 0", display:"flex"}}>
                    <div style={{width:160, textAlign:"right", padding:"10px 0 0 0"}}>
                      <label htmlFor="jpyPriceWithTax" className="form-check-label" style={{margin:"0 3px 0 0"}}>販売価格（税込）</label>
                      <PSRequiredIcomComponent />
                    </div>
                    <div style={{margin:"0 0 0 10px", width:210}}>
                      <div>
                        <input id="jpyPriceWithTax" className="form-control form-control-lg rounded-4" type="input" name="jpyPriceWithTax" style={{textAlign:"right", fontSize:16, width:100}} value={formData.jpy_price_with_tax ? formData.jpy_price_with_tax : ""} onBlur={ e => setValidJPYPriceWithTax(validateJPYPrice(e.target.value)) } onChange={e => { setFormData({...formData, jpy_price_with_tax: e.target.value}) }} placeholder='例：480'/>
                      </div>
                      <div style={{display:(validJPYPriceWithTax ? "none" : "block"), color:"#CC0000", fontWeight:"bold", fontSize:12 }}>
                        無効です。<br />¥120〜¥9999の範囲内、または¥0（無料）のみ有効です。
                      </div>

                    </div>                    
                  </div>                        

                </div>
              </div>
              
              <div className="modal-footer border-top-0 p-5 pt-3 pb-6">
                { /* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">キャンセル</button> */ }
                {/* <button type="submit" className={`btn-lg w-100 ${(enableSubmit && validJPYPriceWithTax && (validManagementIdStatus == ValidManagementIdStatus.valid)) ? "btn-primary" : ""}`} id="submit_btn" disabled={!(enableSubmit && validJPYPriceWithTax && (validManagementIdStatus == ValidManagementIdStatus.valid))} style={{padding:20}} data-bs-target="#exampleModalToggle2" data-bs-toggle="modal">複製する</button> */}
                <button type="submit" className={`btn-lg w-100 ${(enableSubmit && validJPYPriceWithTax && (validManagementIdStatus == ValidManagementIdStatus.valid)) ? "btn-primary" : ""}`} id="submit_btn" disabled={!(enableSubmit && validJPYPriceWithTax && (validManagementIdStatus == ValidManagementIdStatus.valid))} style={{padding:20}} >複製する</button>
              </div>
              
            </form>
            
          </div>
        </div>
      </div>

    </>
  )      
}

function PSPublishScoreDuplicateComponent({ }) {

  return (
    <>
      <div style={{margin:"0 0 0 20px"}}>
        <a className="btn btn-light" style={{border:"1px solid #AAA", padding:"9px 20px", fontSize:12}} data-bs-target="#duplicateScore-modal" data-bs-toggle="modal" href=""><FontAwesomeIcon icon="fa-solid fa-clone" /> 複製する</a>
      </div>
    </>
  ) 
}


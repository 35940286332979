import { Container, Row, Col, Form, Button, Card, Table } from 'react-bootstrap'
import { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'

import React from 'react';

import styles from './PSPublishInvoiceComponent.module.scss'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library }         from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(far, fas, fab)

import PSPublishAccountInvoiceInputComponent from './account/invoice/PSPublishAccountInvoiceInputComponent.tsx'

//export default function PSPublishInvoiceComponent({ company_id, api_base_url }) {
export default function PSPublishInvoiceComponent({ company_id, authority, store_name, apiBaseURL }) {

  //console.log(company_id)

  //const [form_url,            setFormURL]            = useState({})
  const form_url = "https://docs.google.com/forms/d/e/1FAIpQLSe_O4htS6PKna6496C8G4CYhaLCiITZYhi5kFGwCKAs4amfzw/viewform?usp=pp_url&entry.873533429=" + company_id + "&entry.940176280=" + encodeURI(store_name)

  /*
  useEffect(() => {
    (async () => {
      try {
        // let url = api_base_url + "purchase/earning_rate"
        // console.log("url=" + url)

        // const apiResponse = await axios.get(url, {
        //   params: {
        //     company_id: company_id
        //   },
        //   withCredentials: true
        // })
        // //console.info(apiResponse)
        // const result = apiResponse.data.body
        // console.info(result)

        const url = "https://docs.google.com/forms/d/e/1FAIpQLSe_O4htS6PKna6496C8G4CYhaLCiITZYhi5kFGwCKAs4amfzw/viewform?usp=pp_url&entry.873533429=" + company_id + "&entry.940176280=" + encodeURI(store_name)
        setFormURL(url)
      } catch (e) {
        console.error(e)
        console.info(e.response.data)
        throw e
      }
    })()
  }, [form_url])
  */

  return (
    <div className={styles.invoice}>
      <h1>インボイス制度への対応につきまして（2023年8月16日）</h1>
      <div className={styles.body} style={{marginTop:30}}>2023年10月1日より導入されます「インボイス制度」につきまして、Piascore 楽譜販売における対応についてお知らせいたします。</div>

      <h2><FontAwesomeIcon className={styles.icon} icon="fa-solid fa-grip-vertical" />目次</h2>
      <div className={styles.body}>
        <ul>
          <li>インボイス制度について</li>
          <li>インボイス制度への当社の対応について</li>
          {
            authority == 2 ?
            (<li>インボイス制度適格請求書発行事業者の情報登録のお願い</li>)
            : ""
          }
        </ul>
      </div>

      <h2><FontAwesomeIcon className={styles.icon} icon="fa-solid fa-grip-vertical" />インボイス制度について</h2>
      <div className={styles.body}>
        2023年10月1日から、消費税の仕入税額控除の方式としてインボイス制度が開始されます。<br /><br />
        インボイス制度は、売手が買手に対して正確な適用税率や消費税額等を伝えるためのインボイス（適格請求書）を発行・保存することで、消費税に関する仕入税額控除の適用を受けるための制度です。<br /><br />
        インボイスを発行できるのは、「適格請求書発行事業者」に限られ、この「適格請求書発行事業者」になるためには、登録申請書を提出し、登録を受ける必要があります。<br /><br />
        詳しくは、下記の国税庁のサイトなどをご参照ください。

        <ul>
          <li><a target="_blank" href="https://www.nta.go.jp/taxes/shiraberu/zeimokubetsu/shohi/keigenzeiritsu/invoice_about.htm">インボイス制度の概要｜国税庁 <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" /></a></li>
          <li><a target="_blank" href="https://www.nta.go.jp/taxes/shiraberu/zeimokubetsu/shohi/keigenzeiritsu/invoice.htm">特集　インボイス制度 <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" /></a></li>
        </ul>
      </div>

      <h2><FontAwesomeIcon className={styles.icon} icon="fa-solid fa-grip-vertical" />インボイス制度への当社の対応について</h2>
      <div className={styles.body}>
        インボイス制度の開始に伴い、販売者様が適格請求書発行事業者か否かにより、販売者様にお支払している報酬の消費税に関わる、当社での消費税の申告における仕入税額控除額が異なります。<br /><br />

        つきましては、下記の対応を行わせて頂く予定です。

        <h3>● 仕入明細書の発行機能について</h3>
        <div>
          インボイスの要件を満たした明細書を発行する「仕入明細書の発行機能」をリリースいたします。この機能により、適格請求書発行事業者番号をご登録された販売者様につきましては、ご自身で新たにインボイスを発行頂く必要はございません。<br /><br />
          リリースは10/1を予定しておりますが、開発スケジュールなどによって前後する可能性がございますことをご了承ください。また正式なリリース日程が決まりましたら、ご案内差し上げます。<br /><br />
          なお当社が発行する仕入明細書は、一定期間内に誤りのある旨の連絡がない場合には、記載内容のとおりに確認があったものとみなし、正式なインボイスとなります。
        </div>

        {
          authority == 2 ?
          (
            <>
              <h3>● 2023年10月1日以降の報酬料率について</h3>
              <div>
                インボイス制度が開始する2023年10月1日より、適格請求書発行事業者ではない売り手（販売者様）にお支払いする報酬の消費税分につきまして、一部、ないし全額が仕入税額控除ができなくなります。また控除できない分は、買い手（当社）が負担しなければならなくなります。引き続き、サービスを運営するために、当社では正しい適格請求書発行事業者の登録番号を当社システムに登録されているかどうかに応じて、報酬料率を下記のように変更させていただきます。なお報酬料率は、1購入毎（※1）に発生する報酬を計算する際に適用されます。

                <div className={styles.annotation}>
                ※1「1購入毎」とは、1人のご購入者様が、1つの楽譜を、1度購入することを指すものとします<br /><br />
                </div>

                また今後、消費税率や免税事業者などからの仕入れに関する仕入税額控除の経過措置期間が変更された場合は、それに応じて報酬利率が変更される場合がありますことをご了承ください。 

                <ol style={{marginTop:30}}>
                  <li>
                    適格請求書発行事業者の番号を<span style={{textDecoration:"underline"}}>登録した</span>場合（※2）
                    <ul>
                      <li>報酬料率 ＝ 基本報酬料率 （※3）</li>
                    </ul>
                  </li>
                  <li>
                    適格請求書発行事業者の番号を<span style={{textDecoration:"underline"}}>登録していない</span>場合
                    <ul>
                      <li>2023年9月30日まで：報酬料率 ＝ <span style={{fontWeight:"bold"}}>基本報酬料率</span></li>
                      <li>2023年10月1日から2026年9月30日まで：報酬料率 ＝ <span style={{fontWeight:"bold"}}>基本報酬料率 × 108/110</span></li>
                      <li>2026年10月1日から2029年9月30日まで：報酬料率 ＝ <span style={{fontWeight:"bold"}}>基本報酬料率 × 105/110</span></li>
                      <li>2029年10月1日から：報酬料率 ＝ <span style={{fontWeight:"bold"}}>基本報酬料率 × 100/110</span></li>
                    </ul>
                  </li>
                </ol>

                <div className={styles.annotation}>
                  ※2「適格請求書発行事業者の番号を登録した場合」とは、楽譜販売システムに登録番号を登録し、正しいことが確認された状態を指すものとします<br />
                  ※3「基本報酬料率」とは、2023年9月30日まで、または適格請求書発行事業者の販売者様に適用される報酬利率のことです
                </div>
              </div>
            </>
          ) : ""
        }   

        <h3>● 端数処理の変更に伴う税抜ベースの計算の導入について</h3>
        <div>
          インボイス制度の導入に伴い、請求書記載の消費税額の端数処理が「1インボイスにつき、税率ごとに1回」となります。そのため、各購入の報酬額の計算につきまして、現行の「販売価格（税込）」ベースから、「販売価格（税抜）」ベースに変更されます。収益・報酬額につきましては、報酬のお振込み時に「販売価格（税抜）」ベースで再度計算されるため、管理画面に表示の収益・報酬額と実際の報酬額に僅かな誤差が出る場合がありますことをご了承ください。<br /><br />
          詳しくは、下記の国税庁のサイトなどをご参照ください。
          <ul>
            <li><a target="_blank" href="https://www.nta.go.jp/taxes/shiraberu/zeimokubetsu/shohi/keigenzeiritsu/pdf/0020006-027.pdf#page=9">「適格請求書等保存方式の概要 〜インボイス制度の理解のために〜」（国税庁）のp.8『「税率ごとに区分した消費税額等」の端数処理』<FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" /> </a>（PDF）</li>
          </ul>

          また本変更については、開発が完了次第、速やかに実施いたします。
        </div>

      </div>

      {
        authority == 2 ?
        (
          <>
            <h2><FontAwesomeIcon className={styles.icon} icon="fa-solid fa-grip-vertical" />インボイス制度適格請求書発行事業者の情報登録のお願い</h2>
            <div className={styles.body}>
              適格請求書発行事業者の登録をお済みの方は、下記のフォームより登録番号をお知らせください。<br />
              登録番号が正しいことを確認後、上記の料率「a. 適格請求書発行事業者である（基本報酬料率）」を適用させていただきます。

              <div style={{margin:30, padding:40, background:"#EEE"}}>

                <PSPublishAccountInvoiceInputComponent apiBaseURL={apiBaseURL} />
                
                {/* <a className="btn btn-primary" style={{padding:"20px 40px"}} target="_blank" href={form_url}>
                  インボイス制度適格請求書発行事業者の登録番号の申請フォーム
                  <FontAwesomeIcon style={{marginLeft:3 }} icon="fa-solid fa-circle-arrow-right" />
                </a> */}
              </div>
            </div>
          </>
        ) : ""
      }

      <div style={{marginTop:30}} className={styles.body}>
      ご不明点などございましたら、最寄りの税務署や税理士等の専門家にご相談いただけますようお願いいたします。
      </div>

    </div>      
  )      
}

import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStore } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library }         from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(far, fas, fab)

const apiConfig = {
  headers: {
    "Content-Type": "application/json",
    'X-Requested-With': 'XMLHttpRequest'
  },
  responseType: "json",
  withCredentials: true,
};


const apiErrorMessage = (error) => {
  switch (error.response?.status) {
    case 400:
      return "入力値が正しくありません";
    case 401:
      return "ログインしてない、または確認パスワードが正しくありません";
    default:
      return "通信またはサーバーでエラーが発生しました";
  }
}

function ArrowLink({text, onClick}) {
  return (
    <a href="#" onClick={e => {e.preventDefault();  onClick();}}
      style={{
        color: "#1676cf", fontWeight: "normal",
        fontSize: 12, marginLeft: 20} }>

        <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" /> {text}
    </a>
  );
}

function StoreNameWithLink({text, url, id}) {
  return (
    <>
      <a href={url} style={{ color: "#0083EA" }} target="_blank" >
        {text}
      </a>
      {" "} (ストアID : {id})
   </>
   );
}

export default function PSStoreNameComponent({ store_name_ja, store_name_en, store_id, api_base_url, reload_page_if_successful }) {
  const [storeNameJa, setStoreNameJa] = useState("");
  const [storeNameEn, setStoreNameEn] = useState("");
  const [storeId, setStoreId] = useState("");
  const [storeNameJaInput, setStoreNameJaInput] = useState("");
  const [storeNameEnInput, setStoreNameEnInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [show, setShow] = useState(false);

  const closeDialog = () => {
    setShow(false);
  }
  const showDialog = () => {
    setStoreNameJaInput(storeNameJa);
    setStoreNameEnInput(storeNameEn);
    setShow(true);
  }

  const changeChargeName = async() => {
    try {      
      await axios.post(`${api_base_url}/companies/change_store_name`,
        {name_ja: storeNameJaInput, name_en: storeNameEnInput}, apiConfig);

      setStoreNameJa(storeNameJaInput);
      setStoreNameEn(storeNameEnInput);
      setErrorMessage("");
      closeDialog();
      if (reload_page_if_successful) { window.location.reload(); }
    } catch (e) {
      setErrorMessage(apiErrorMessage(e));
    }
  }

  useEffect(() => {
    setStoreNameJa(store_name_ja);
    setStoreNameEn(store_name_en);
    setStoreId(store_id);
  }, [store_name_ja, store_name_en, store_id]);

  return (
    <>
      <StoreNameWithLink id={storeId} text={storeNameJa} url={`https://store.piascore.com/publishers/${storeId}`} />
      <ArrowLink text="ストア名の変更" onClick={showDialog} />

      <Modal show={show} onHide={closeDialog} fullscreen="md-down" animation={true} contentClassName="rounded-4 shadow h-auto" centered>
        <Modal.Header closeButton  className="p-5 pb-4 border-bottom-0">
          <Modal.Title as="h3" className="fw-bold"> <FontAwesomeIcon icon={faStore} /> ストア名の変更</Modal.Title>
        </Modal.Header>

        <Form onSubmit={e => { e.preventDefault(); changeChargeName() }}>
          <Modal.Body className="px-5">
            {errorMessage.length > 0 &&
              <Alert variant="warning">{errorMessage}</Alert>
            }
            <Form.Group className="mb-4">
              <Form.Label className="fw-bold">
                ストア名（日本語）
              </Form.Label>
              <Form.Control type="text" className="fs-6 py-3 rounded-2 h-auto"
                required
                placeholder="新しいストア名（日本語）"
                value={storeNameJaInput} onChange={(e) => setStoreNameJaInput(e.currentTarget.value)} />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="fw-bold">
                ストア名（英語）
              </Form.Label>
              <Form.Control type="text" className="fs-6 py-3 rounded-2 h-auto"
                required
                placeholder="新しいストア名（英語）"
                value={storeNameEnInput} onChange={(e) => setStoreNameEnInput(e.currentTarget.value)} />
            </Form.Group>

          </Modal.Body>
          <Modal.Footer className="p-5 pt-2 pb-4 border-top-0 text-center">
            <button type="submit" className="btn-lg w-100 btn-primary" id="submit_btn"  style={{background:"#0083EA", padding:15}}>変更する</button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

import { Container, Row, Col, Form, Button, Card, Table } from 'react-bootstrap'
import { useEffect } from 'react'
import { useState } from 'react'

import React from 'react';

//import styles from './PSPublishRightsEditComponent.module.scss'
import ReasonText from "./PSPublishScoreSuspendReasonText"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(far, fas, fab)

export default function PSPublishScoreSuspendReasonComponent({ reasonId, type, scoreId }) {

  let r = ReasonText.getSuspendReason({ reasonId: reasonId, scoreId: scoreId })

  return (
    <>
      { type === "title" && <><span>{ r.title } </span></>}
      { type === "content" && <><span dangerouslySetInnerHTML={{ __html: r.content }} /></>}      
    </>
  )
};
  

import { Container, Row, Col, Form, Button, Card, Table } from 'react-bootstrap'
import { useEffect, useState, useRef } from 'react'

import React from 'react';

import styles from './PSPublishAccountChargeFormComponent.module.scss'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library }         from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(far, fas, fab)

import PSAPIClient  from './common/PSAPIClient.js'
import PSURLUtil    from './common/PSURLUtil.js'

import PSRequiredIcomComponent from './common/PSRequiredIcomComponent.js'

export default function PSPublishAccountChargeFormComponent({ api_base_url, account_id }) {

  const [charge, setCharge] = useState({
    family_name:"",
    first_name:"",
    family_name_yomi: "", 
    first_name_yomi: "",

    birth_year: 1970,
    birth_month: 1,
    birth_day: 1,
    sex:null,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(true);
  
  const chargeModal = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        let url = api_base_url + "/accounts/charge/"
        url = account_id ? url + account_id : url
        //console.log("url=" + url)

        const res = await PSAPIClient.get({url})
        //console.info(res)
        const body = res.body
        // console.info(res.body)
        if (body.birth_year != undefined) {
          // for (let key in charge) {
          //   if (body[key] === undefined) {
          //     body[key] = "";
          //   }
          // }
          setCharge(body);
        }
        
        // モーダル表示
        {
          if(PSURLUtil.getParam("charge_modal") == 1) {
            window.$(chargeModal.current).modal("show");
          }
        }
      } catch (e) {
        console.error(e)
        console.info(e.response.data)
        throw e
      }
    })()
  }, [])

  const onSubmit = async (event) => {
    event.preventDefault();
    //console.log(charge)

    // 検証
    let date = new Date(charge.birth_year, charge.birth_month - 1, charge.birth_day)
    // console.log(`${charge.birth_year} ${charge.birth_month} ${charge.birth_day}` )
    // console.log(`${date.getFullYear()} ${date.getMonth() + 1} ${date.getDate()}` )
    if((charge.birth_year != date.getFullYear()) || (charge.birth_month != date.getMonth() + 1) || (charge.birth_day != date.getDate())) {
      return alert("生年月日が正しくありません。")
    }
    
    setIsLoading(true)
    
    // PUT
    {
      let url = api_base_url + "/accounts/charge/"
      url = account_id ? url + account_id : url
      const res = await PSAPIClient.put({url, data:charge})
    }
        
    //
    //window.location.href = "/accounts";
    window.location.href = window.location.pathname; // パラメータを削除して同じURLに遷移する
  }
  
  const startYear = 1930
  const endYear   = 2020

  return (
    <>
      <PSPublishAccountChargeComponent charge={charge} />

      <div ref={chargeModal} id="charge-modal" className="modal fade modal-sheet py-5" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content rounded-6 shadow">
            <div className="modal-header p-5 pb-4 border-bottom-0">
              <h3 className="fw-bold mb-0">
                <FontAwesomeIcon icon="fa-solid fa-user-pen" />
                <span style={{ padding: "0 0 0 3px" }}>担当者情報の変更</span>
              </h3>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />              
            </div>
  
            <form onSubmit={onSubmit}>
          
              <div className="modal-body p-5 pt-3 pb-3">

                <div className="mt-0 mb-4 text-center w-100 text-black-50" style={{ fontSize: 11 }} >
                  支払調書など法的書類に記載する場合もありますので、正確にご入力ください。
                </div>
    
                {/* 氏名 */}
                <div style={{"margin":"0px 0 0 0"}}>
                  <div>氏名 <PSRequiredIcomComponent /></div>
                  <div style={{"display":"flex", "justifyContent": "flex-start", "margin":"5px 0 0 0"}}>
                    <div className="form-floating mb-3">
                      <input type="input" className="form-control rounded-4" id="family_name" value={charge.family_name} onChange={e => { setCharge({...charge, family_name: e.target.value}) }} placeholder="ぴあすこあ" required />
                      <label htmlFor="family_name">氏</label>
                    </div>

                    <div className="form-floating mb-3" style={{"margin":"0 0 0 10px"}} >
                      <input type="input" className="form-control rounded-4" id="first_name" value={charge.first_name} onChange={e => { setCharge({...charge, first_name: e.target.value}) }} placeholder="薫" required />
                      <label htmlFor="first_name">名</label>
                    </div>
                  </div>
                </div>

                {/* 氏名よみ */}
                <div style={{"margin":"20px 0 0 0"}}>
                  <div>氏名フリガナ <PSRequiredIcomComponent /></div>
                  <div style={{"display":"flex", "justifyContent": "flex-start", "margin":"5px 0 0 0"}}>
                    <div className="form-floating mb-3">
                      <input type="input" className="form-control rounded-4" id="family_name_yomi" value={charge.family_name_yomi} pattern="^[ァ-ヶー・0-9]+$" onChange={e => { setCharge({...charge, family_name_yomi: e.target.value}) }} placeholder="ピアスコア" required />
                      <label htmlFor="family_name_yomi">氏（カタカナ）</label>
                    </div>

                    <div className="form-floating mb-3" style={{"margin":"0 0 0 10px"}} >
                      <input type="input" className="form-control rounded-4" id="first_name_yomi" value={charge.first_name_yomi} pattern="^[ァ-ヶー・0-9]+$" onChange={e => { setCharge({...charge, first_name_yomi: e.target.value}) }} placeholder="カオル" required />
                      <label htmlFor="first_name_yomi">名（カタカナ）</label>
                    </div>
                  </div>
                </div>

                {/* 生年月日 */}
                <div style={{"margin":"20px 0 0 0"}}>
                  <div>生年月日 <PSRequiredIcomComponent /></div>
                  <div style={{"display":"flex", "justifyContent": "flex-start", "margin":"5px 0 0 0"}}>
                    <div className="form-floating mb-3">
                      <select className="form-select" value={charge.birth_year} style={{"width":120}} onChange={e => { setCharge({...charge, birth_year: e.target.value}) }}>
                        {
                          Array.from({length: endYear - startYear + 1}, (_, i) => startYear + i).map( year => (<option key={year} value={year}>{year}</option>) )
                        }
                      </select>                      
                      <label htmlFor="birth_year">年</label>
                    </div>

                    <div className="form-floating mb-3">
                      <select className="form-select" value={charge.birth_month} style={{"width":80, "margin":"0 0 0 10px"}} onChange={e => { setCharge({...charge, birth_month: e.target.value}) }}>
                        {
                          Array.from({length: 12 - 1 + 1}, (_, i) => 1 + i).map( month => (<option key={month} value={month}>{month}</option>) )
                        }
                      </select>                      
                      <label htmlFor="birth_month">月</label>
                    </div>

                    <div className="form-floating mb-3">
                      <select className="form-select" value={charge.birth_day} style={{"width":80, "margin":"0 0 0 10px"}} onChange={e => { setCharge({...charge, birth_day: e.target.value}) }}>
                        {
                          Array.from({length: 31 - 1 + 1}, (_, i) => 1 + i).map( day => (<option key={day} value={day}>{day}</option>) )
                        }
                      </select>                      
                      <label htmlFor="birth_day">日</label>
                    </div>
                    
                  </div>
                </div>

              </div>

              
              <div className="modal-footer border-top-0 p-5 pt-2 pb-6">
                { /* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">キャンセル</button> */ }
                <button type="submit" className="btn-lg w-100 btn-primary" id="submit_btn" disabled={isLoading} style={{background:"#0083EA", padding:15}} >変更する</button>
              </div>
              
            </form>
            
          </div>
        </div>
      </div>

    </>
  )      
}

function PSPublishAccountChargeComponent({ charge }) {

  return (
    <div style={{display:"flex", alignItems:"center"}}>
      <div>
        { 
          charge.family_name_yomi
          ? 
          (
            <>
            </>
          )
          :
          (
            <>
              <span style={{fontSize:10, padding:"3px 4px", borderRadius:3, color:"#FFFFFF", background:"#d36b00" }}>未設定</span>
              <span style={{margin:"0 0 0 3px", color:"#cc0000", fontSize:12}}>追加情報が必要です</span>
            </>
          )
        }
      </div>
      <div style={{margin:"0 0 0 20px"}}>
        <a className="" style={{color:"#1676cf", fontWeight:"normal", fontSize:12}} data-bs-target="#charge-modal" data-bs-toggle="modal" href=""><FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" /> 担当者情報の変更</a>
      </div>
    </div>
  ) 
}


import { Container, Row, Col, Form, Button, Card, Table } from 'react-bootstrap'
import { useEffect, useState, useRef } from 'react'

import React from 'react';

import styles from './PSPublishAccountTaxWithholdingFormComponent.module.scss'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library }         from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(far, fas, fab)

import PSAPIClient  from './common/PSAPIClient.js'
import PSURLUtil    from './common/PSURLUtil.js'

import PSRequiredIcomComponent from './common/PSRequiredIcomComponent.js'

export default function PSPublishAccountTaxWithholdingFormComponent({ api_base_url, account_id }) {

  const BizType = {
    none:       0,      // 未設定
    corporate:  10,      // 法人
    personal:   20,      // 個人  
  };

  const Status = {
    none:       0,   // 未設定
    new:       10,   // 新規
    applying:  20,   // 申請中
    confirmed: 30,   // 確認済
    rejected:  40    // 却下
  };
  
  const canEdit = (taxWithholding) => {
    return taxWithholding.status === undefined || taxWithholding.status == Status.none
  }

  const [taxWithholding, setTaxWithholding] = useState({
    biz_type: BizType.none,
    company_name: "",
    legal_number:"",
    is_oversea: 0,
    is_duty_free: null,
    status: Status.none,
  })
  const [copiedTaxWithholding, setCopiedTaxWithholding] = useState({
    biz_type: BizType.none,
    company_name: "",
    legal_number:"",
    is_oversea: 0,
    is_duty_free: null,
    status: Status.none,
  })
  const [disableSubmit, setDisableSubmit] = useState(true)
  const [showModal, setShowModal] = useState(true);

  const [disabled, setDisabled]   = useState(true)
  const [company_disabled, setCompanyDisabled]   = useState(true)
  const [dutyFreeDisabled, setDutyFreeDisabled]   = useState(true)
  const [showCompany, setShowCompany]   = useState(false)
  const [showDutyFree, setShowDutyFree]   = useState(false)
  
  const taxWithholdingModal = useRef(null);
    
  useEffect(() => {
    (async () => {
      try {
        let url = api_base_url + "/accounts/tax_withholding/"
        url = account_id ? url + account_id : url
        //console.log("url=" + url)

        const res = await PSAPIClient.get({url})
        //console.info(res)
        const body = res.body
        // console.info(res.body)
        if (canEdit(body)) {
          setDisabled(false)
          setDisableSubmit(false)
        }else{
          // for (let key in taxWithholding) {
          //   if (body[key] === undefined) {
          //     if (key == "status") {
          //       body[key] = 0                
          //     }else if (key == "is_oversea") {
          //       body[key] = false
          //     }else{
          //       body[key] = "";
          //     }
          //   }
          // }
          
          //console.log(body)
          setTaxWithholding(body);
          setCopiedTaxWithholding(structuredClone(body));          
        }
        
        setShowCompany(body.biz_type == BizType.corporate)
        setShowDutyFree(body.is_oversea == 1)

        if(account_id) { // admin
          setDisabled(false)
          setDisableSubmit(false)
        }

        // モーダル表示
        {
          if(PSURLUtil.getParam("tax_withholding_modal") == 1) {
            window.$(taxWithholdingModal.current).modal("show");
          }
        }
      } catch (e) {
        console.error(e)
        console.info(e.response.data)
        throw e
      }
    })()
  }, [])

  const onSubmit = async (event) => {
    event.preventDefault();
    
    if(taxWithholding.biz_type == BizType.corporate && (taxWithholding.company_name == "" || taxWithholding.legal_number == "")) {
      alert("法人名・法人番号を入力してください")
      return
    }

    if(taxWithholding.is_oversea == 1 && taxWithholding.is_duty_free == null) {
      alert("課税・免税を入力してください")
      return
    }

    setDisableSubmit(true)
    
    //console.log(taxWithholding)
    
    // PUT
    {
      taxWithholding.status = Status.applying
      let url = api_base_url + "/accounts/tax_withholding/"
      url = account_id ? url + account_id : url
      const res = await PSAPIClient.put({url, data:taxWithholding})
    }
        
    //
    //window.location.href = "/accounts";
    window.location.href = window.location.pathname; // パラメータを削除して同じURLに遷移する
  }
  
  return (
    <>
      <PSPublishAccounttaxWithholdingComponent taxWithholding={copiedTaxWithholding} />

      <div ref={taxWithholdingModal} id="taxWithholding-modal" className="modal fade modal-sheet py-5" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content rounded-6 shadow">
            <div className="modal-header p-5 pb-4 border-bottom-0">
              <h3 className="fw-bold mb-0">
                <FontAwesomeIcon icon="fa-solid fa-user-pen" />
                <span style={{ padding: "0 0 0 3px" }}>源泉徴収の情報の申請</span>
              </h3>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />              
            </div>
  
            <form onSubmit={onSubmit}>
          
              <div className="modal-body p-5 pt-3 pb-3" style={{lineHeight:"1.8em", fontSize:15}}>

                <div className="mt-0 w-100 text-black-50" style={{ fontSize: 11 }} >
                  支払調書など法的書類に記載する場合もありますので、正確にご入力ください。
                  <br />
                  源泉徴収については、<a href="https://help.piascore.com/hc/ja/articles/15657814399129" target="_blank">なぜ源泉徴収をするのですか？<FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" /> </a>をご参照ください。
                </div>
    
                <div className="alert alert-danger" style={{fontSize:14, margin:"20px 0 0 0", display:"flex"}}>
                  <div><FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" /></div>
                  <div style={{margin:"0 0 0 10px"}}>こちらの情報は、基本的には後から変更できません。ご注意ください。虚偽の申請があった場合、遡って源泉徴収税額の請求を行わせて頂く場合がございます。</div>
                </div>
    
                {/* 法人・個人 */}
                <div className="mt-4" style={{"margin":"0px 0 0 0"}}>
                  <div style={{fontWeight:"bold"}}><FontAwesomeIcon icon="fa-solid fa-building" /> 区分（個人、または法人） <PSRequiredIcomComponent /></div>
                  <div style={{"margin":"10px 0 0 20px"}}>                    
                    <div style={{display:"flex", alignItems:"flex-start"}}>
                      <div>
                        <input id="biz_type_20" className="form-check-input" type="radio" name="biz_type" value="20" checked={taxWithholding.biz_type == 20} onChange={e => { setTaxWithholding({...taxWithholding, biz_type: e.target.value, company_name:"", legal_number:""}); setCompanyDisabled(true);  setShowCompany(false); }} disabled={disabled} required />
                      </div>
                      <div style={{margin:"0 0 0 5px"}}>
                        <label htmlFor="biz_type_20" className="form-check-label"><span style={{fontWeight:"bold"}}>個人</span>：販売コンテンツに関わる著作権を「販売者個人」が有し、報酬（販売収益）を「個人」として受け取っている <span style={{fontSize:12, textDecoration:"underline"}}>※ 個人販売されている方の多くの場合は、こちらになります</span></label>
                      </div>
                    </div>
                    <div style={{display:"flex", alignItems:"flex-start", margin:"10px 0 0 0"}}>
                      <div>
                        <input id="biz_type_10" className="form-check-input" type="radio" name="biz_type" value="10"  checked={taxWithholding.biz_type == 10}  onChange={e => { setTaxWithholding({...taxWithholding, biz_type: e.target.value}); setCompanyDisabled(false); setShowCompany(true); }} disabled={disabled} required />
                      </div>
                      <div style={{margin:"0 0 0 5px"}}>
                        <div>
                          <label htmlFor="biz_type_10" className="form-check-label"><span style={{fontWeight:"bold"}}>法人</span>：販売コンテンツに関わる著作権を、株式会社・有限会社・NPOなどの「法人」で有し、報酬（販売収益）を「法人」として受け取っている</label>
                        </div>

                        {/* 企業情報 */}
                        <div style={{background:"#EEE", padding:"5px 1px", display:(showCompany ? "block" : "none")}}>
                          <div style={{margin:"10px 0 0 0", display:"flex", alignItems:"center"}}>
                            <div>
                              <label htmlFor="company_name" className="form-check-label" style={{width:70, textAlign:"right"}}>法人名</label> 
                            </div>
                            <div style={{margin:"0 0 0 5px"}}>
                              <input id="company_name" className="form-control form-control-lg rounded-4" type="input" name="company_name" style={{width:270, fontSize:16}} value={taxWithholding.company_name ? taxWithholding.company_name : ""} onChange={e => { setTaxWithholding({...taxWithholding, company_name: e.target.value}) }} disabled={company_disabled} placeholder='XX 株式会社'/>
                            </div>
                          </div>                        

                          <div style={{margin:"10px 0 0 0", display:"flex", alignItems:"flex-start"}}>
                            <div>
                              <label htmlFor="company_name" className="form-check-label" style={{margin:"10px 0 0 0", width:70, textAlign:"right"}}>法人番号</label> 
                            </div>
                            <div style={{margin:"0 0 0 5px"}}>
                              <div>
                                <input id="legal_number" className="form-control form-control-lg rounded-4" type="input" name="legal_number" style={{width:270, fontSize:16}} minLength="13" maxLength="13" value={taxWithholding.legal_number ? taxWithholding.legal_number : ""} onChange={e => { setTaxWithholding({...taxWithholding, legal_number: e.target.value}) }} disabled={company_disabled} placeholder='13桁の数字'/>
                              </div>
                              <div style={{fontSize:11}}>
                                <span style={{color:"#888"}}>法人番号がわからない場合</span> <a href="https://www.houjin-bangou.nta.go.jp/" target="_blank">国税庁 法人番号公表サイト<FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" /></a>
                              </div>
                            </div>
                          </div>                        
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                {/* 居住地 */}
                <div style={{"margin":"30px 0 0 0"}}>
                  <div style={{fontWeight:"bold"}}><FontAwesomeIcon icon="fa-solid fa-earth-americas" /> 居住地 <PSRequiredIcomComponent /></div>
                  <div style={{"margin":"10px 0 0 20px"}}>                    
                    <div style={{display:"flex", alignItems:"flex-start"}}>
                      <div>
                        <input id="is_oversea_0" className="form-check-input" type="radio" name="is_oversea" value="0" checked={taxWithholding.is_oversea == 0} onChange={e => { setTaxWithholding({...taxWithholding, is_oversea: e.target.value, is_duty_free:null}); setDutyFreeDisabled(true); setShowDutyFree(false) }} disabled={disabled} required />
                      </div>
                      <div style={{margin:"0 0 0 5px"}}>
                        <label htmlFor="is_oversea_0" className="form-check-label"><span style={{fontWeight:"bold"}}>日本<span style={{textDecoration:"underline"}}>国内</span>に在住（居住者）</span> <span style={{fontSize:12, textDecoration:"underline"}}>※ 多くの場合はこちらです</span></label>
                      </div>
                    </div>
                    <div style={{display:"flex", alignItems:"flex-start", margin:"10px 0 0 0"}}>
                      <div>
                        <input id="is_oversea_1" className="form-check-input" type="radio" name="is_oversea" value="1"  checked={taxWithholding.is_oversea == 1}  onChange={e => { setTaxWithholding({...taxWithholding, is_oversea: e.target.value}); setDutyFreeDisabled(false); setShowDutyFree(true) }} disabled={disabled} required />
                      </div>
                      <div style={{margin:"0 0 0 5px"}}>
                        <label htmlFor="is_oversea_1" className="form-check-label"><span style={{fontWeight:"bold"}}>日本<span style={{textDecoration:"underline"}}>国外</span>に在住（非居住者）</span></label>
                      </div>
                    </div>
                  
                    {/* 課税・免税 */}
                    <div style={{margin:"0 0 0 30px", background:"#EEE", padding:"1px 10px 11px", display:(showDutyFree ? "block" : "none")}}>
                      <div style={{display:"flex", alignItems:"center", margin:"10px 0 0 0"}}>                        
                        <div style={{fontWeight:"bold"}}>課税・免税</div>
                        <div style={{margin:"0 0 0 10px"}}>
                          <input id="is_duty_free_0" className="form-check-input" type="radio" name="is_duty_free" value="0"  checked={taxWithholding.is_duty_free == 0} disabled={dutyFreeDisabled} onChange={e => { setTaxWithholding({...taxWithholding, is_duty_free: e.target.value}) }} />
                        </div>
                        <div style={{margin:"0 0 0 5px"}}>
                          <label htmlFor="is_duty_free_0" className="form-check-label" style={{color:(dutyFreeDisabled ? "#AAA" : "inherit")}}>課税</label>
                        </div>

                        <div style={{margin:"0 0 0 20px"}}>
                          <input id="is_duty_free_1" className="form-check-input" type="radio" name="is_duty_free" value="1"  checked={taxWithholding.is_duty_free == 1} disabled={dutyFreeDisabled} onChange={e => { setTaxWithholding({...taxWithholding, is_duty_free: e.target.value}) }} />
                        </div>
                        <div style={{margin:"0 0 0 5px"}}>
                          <label htmlFor="is_duty_free_1" className="form-check-label" style={{color:(dutyFreeDisabled ? "#AAA" : "inherit")}}>免税</label>
                        </div>
                      </div>

                    </div>

                    <div style={{fontSize:12, margin:"20px 0 0 0"}}>
                      詳しくは、下記の国税庁のサイトなどをご参照ください。
                      <ul style={{margin:"10px 0 0 -10px"}}>
                        <li style={{lineHeight:"1.8em"}}><a href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/gensen/2885.htm" target="_blank">No.2885 非居住者等に対する源泉徴収のしくみ<FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" /></a></li>
                        <li style={{lineHeight:"1.8em"}}><a href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/gensen/2884.htm" target="_blank"> No.2884 非居住者等に対する源泉徴収・源泉徴収の税率<FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" /></a></li>
                        <li style={{lineHeight:"1.8em"}}><a href="https://www.nta.go.jp/law/shitsugi/gensen/01/02.htm" target="_blank">業務の都合により1年未満で帰国したり、海外勤務が1年以上となった場合の居住者・非居住者の判定<FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" /></a></li>
                      </ul>
                    </div>

                  </div>
                </div>

              </div>

              
              <div className="modal-footer border-top-0 p-5 pt-4 pb-6">
                { /* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">キャンセル</button> */ }
                <button type="submit" className={`btn-lg w-100 ${disableSubmit ? "" : "btn-primary"}`} id="submit_btn" disabled={disableSubmit} style={{background:(disableSubmit ? "#EEE" : "#0083EA"), padding:15}} >申請する</button>
              </div>
              
            </form>
            
          </div>
        </div>
      </div>

    </>
  )      
}

function PSPublishAccounttaxWithholdingComponent({ taxWithholding }) {

  const BizTypeText = {
    0:  "未設定",      // 未設定
    10: "法人",      // 法人
    20: "個人",      // 個人  
  };

  const IsOverseaText = {
    // 0:  "日本国内に在住（居住者）",      // 日本国内に在住（居住者）
    // 1:  "日本国外に在住（非居住者）",     // 日本国外に在住（非居住者）
    0:  "日本国内",      // 日本国内に在住（居住者）
    1:  "日本国外",     // 日本国外に在住（非居住者）
  };

  const IsDutyFreeText = {
    0:  "課税",
    1:  "免税",
  };

  const StatusText = {
    0:  "未設定", 
    10: "新規",
    20: "申請中",
    30: "確認済",
    40: "却下",
  };

  const StatusBGColor = {
    0:  "#d36b00",  // 未設定
    //10: "#FF67CC",  // 新規
    20: "#c5aa00",  // 申請中
    30: "#62a800",  // 確認済
    //40: "#CC0000",  // 却下
  };

  return (
    <>
      <div style={{display:"flex", alignItems:"center"}}>
        <div>
          { 
            taxWithholding.biz_type 
            ? 
            (
              <>
                <div><span style={{fontSize:10, padding:"3px 4px", borderRadius:3, color:"#FFFFFF", background:StatusBGColor[taxWithholding.status] }}>{StatusText[taxWithholding.status]}</span></div>
                <div>
                  区分：<span style={{fontWeight:"bold"}}>{BizTypeText[taxWithholding.biz_type]}</span> 
                  {
                    taxWithholding.biz_type == 10 ? <span style={{fontSize:12}}>（{taxWithholding.company_name}）</span> : ""
                  }
                </div>
                <div>
                  居住地：<span style={{fontWeight:"bold"}}>{IsOverseaText[taxWithholding.is_oversea]}</span>
                  {
                    taxWithholding.is_oversea ? <span style={{fontSize:12}}>（{IsDutyFreeText[taxWithholding.is_duty_free]}）</span> : ""
                  }
                </div>
              </>
            )
            :
            (
              <>
                <span style={{fontSize:10, padding:"3px 4px", borderRadius:3, color:"#FFFFFF", background:StatusBGColor[taxWithholding.status] }}>{StatusText[taxWithholding.status]}</span>
                <span style={{margin:"0 0 0 3px", color:"#cc0000", fontSize:12}}>設定されていません</span>
              </>
            )
          }
        </div>
        <div style={{margin:"0 0 0 20px"}}>
          <a className="" style={{color:"#1676cf", fontWeight:"normal", fontSize:12}} data-bs-target="#taxWithholding-modal" data-bs-toggle="modal" href=""><FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" /> 源泉徴収に関する情報の変更</a>
        </div>
      </div>
      
      <div style={{margin:"20px 0 0 0", padding:20, background:"#F6F6F6"}}>
        <div style={{fontWeight:"bold", fontSize:12}}>源泉徴収について</div>
        <ul style={{lineHeight:"1.8em", margin:"10px 0 0 -16px"}}>
          <li><a target="_blank" href="https://help.piascore.com/hc/ja/articles/15657814399129">なぜ源泉徴収をするのですか？<FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" /></a></li>
          <li><a target="_blank" href="https://help.piascore.com/hc/ja/articles/15657967427609">収益が減るので、源泉徴収をしないでほしい<FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" /></a></li>
          <li><a target="_blank" href="https://help.piascore.com/hc/ja/articles/15658357573017">源泉徴収後、いくら振り込まれることになりますか？<FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" /></a></li>
        </ul>
      </div>  
    </>
  ) 
}


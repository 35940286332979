// import React from "react"
// import PropTypes from "prop-types"

import React from 'react';
import axios from 'axios';

import './PSPublishTweetComponent.scss'; 

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//import { faAngleRight, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { library }         from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, fab)

import { Button, Modal, Form, Container, Row, Col, Alert } from 'react-bootstrap';


const FormHeader = props => {
  return <div className="contact_header">お問合せフォーム</div>
}

class PSPublishTweetComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tweet:"",
      copiedVisibility:"hidden"
    };
  }
  
  async componentDidMount() {
    this.fetchTweet({});
  }

  async fetchTweet({reload=false}) {
    
    let tweet = ""
    
    // fetch
    if(!reload){
      let url = this.props.api_base_url + "/scores/" + this.props.scoreId + "/get_recommend";
      let res = await axios.get(url, {
        withCredentials: true
      })
      //console.log(res.data)
      
      const data = res.data
      tweet = data.body.recommend_text
    }
    
    // create
    if (reload || tweet == null || tweet == "") {
      let url = this.props.api_base_url + "/scores/" + this.props.scoreId + "/create_recommend";
      let res = await axios.get(url, {
        withCredentials: true
      })
      //console.log(res.data)

      const data = res.data
      tweet = data.body.recommend_text
    }
    
    // modify
    {
      const headers = [
        "＼🆕新発売！🎶／",
        "＼🎶新譜のご紹介🆕／",
        "【🎼新譜のご紹介🆕】",
        "／\n📢 新譜のお知らせ🆕\n＼\n",
      ]
      const header = headers[Math.floor(Math.random() * headers.length)]
      
      tweet = header + "\n" + tweet
      
      tweet = tweet + "\n"
      tweet = tweet + "https://store.piascore.com/scores/" + this.props.scoreId
    }
    
    this.setState({tweet:tweet})
  }
  
  async copyToClipboard({text}) {
    await global.navigator.clipboard.writeText(text);
    
    this.setState({copiedVisibility:"visible"})
  };

  render() {

    let reloadButton = ""
    if (this.props.enableReload) {
      reloadButton = (
        <div style={{ marginTop:10 }}>
          <button type="button" className="btn btn-outline-secondary btn-sm" onClick={ () => this.fetchTweet({reload:true}) }>リロード</button>
        </div>
        )
    }

    return (
      <div className="tweet_container">
        <div className="header" style={{}}>
          AI 宣伝文（ベータ版）
        </div>

        <div className="body">

          <div className="body-header">
          楽譜の宣伝文を人工知能チャットボットが作成しました。Piascoreで販売する楽譜を宣伝する際にご利用ください。
          </div>

          <div>
            <div style={{float:"left", height:155}}>
              <textarea value={this.state.tweet} className="form-control tweet_area" disabled />
              
              <div style={{position:"relative", top:-54, left:603, width:80 }} >
                 {reloadButton}
              </div>
            </div>
  
            <div style={{float:"left", marginLeft:20}}>
              <div style={{ height:20, textAlign:"center", color:"#999", visibility:this.state.copiedVisibility }}>
                コピーしました
              </div>
              <div style={{  }}>
                <button onClick={() => this.copyToClipboard({text:this.state.tweet}) } className="btn btn-primary text-center rounded-pill" style={{width:220, padding:10, borderColor:"#6b2d72", background:"#6b2d72"}}  >
                  <FontAwesomeIcon icon="fas fa-copy" />
                  <span style={{paddingLeft:3}}>
                    コピーする
                  </span>
                </button>
              </div>
  
              <div style={{ marginTop:20 }}>
                <a className="btn btn-primary text-center rounded-pill" style={{width:220, padding:10, borderColor:"#1d9bf0", background:"#1d9bf0"}} href={ "https://twitter.com/intent/tweet?via=piascore_store&text=" + encodeURIComponent(this.state.tweet)}>
                  <FontAwesomeIcon icon="fab fa-twitter" />
                  <span style={{paddingLeft:3}}>
                    ツイートする
                  </span>
                </a>
              </div>

{ /*              
              <div style={{ marginTop:20 }}>
                <a className="btn btn-primary text-center rounded-pill" style={{width:220, padding:10, borderColor:"#1a77f2", background:"#1a77f2"}} href={ "https://twitter.com/intent/tweet?&text=" + encodeURIComponent(this.state.tweet)}>
                  <FontAwesomeIcon icon="fab fa-facebook" />
                  <span style={{paddingLeft:3}}>
                    シェアする
                  </span>
                </a>
              </div>
*/ } 
            </div>
            
            <div style={{clear:"both"}} />
          </div>
          
        </div>
        
        <div className="footer" style={ {} } >
          ※ 正確さについては保証いたしません。Powered by <a target="_blank" href="https://openai.com/blog/chatgpt">ChatGPT</a>
        </div>
      </div>
    );
    
  }

}

// ========================================

export default PSPublishTweetComponent;

import { Container, Row, Col, Form, Button, Card, Table } from 'react-bootstrap'
//import Form from 'react-bootstrap/Form';

import { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'

import React from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { library }         from '@fortawesome/fontawesome-svg-core'
// import { far } from '@fortawesome/free-regular-svg-icons'
// import { fas } from '@fortawesome/free-solid-svg-icons'
// import { fab } from '@fortawesome/free-brands-svg-icons'
// library.add(far, fas, fab)

import PSTimeUtil from './common/PSTimeUtil.js'
import PSTextUtil from './common/PSTextUtil.js'

import PSPublishRightsInfoConfirmComponent        from './music_rights/PSPublishRightsInfoConfirmComponent.js'
import PSPublishRightsCPHoldersComponent          from './music_rights/PSPublishRightsCPHoldersComponent.js'
import PSPublishRightsAuthorInfoConfirmComponent  from './music_rights/PSPublishRightsAuthorInfoConfirmComponent.js'

import styles from './PSPublishRightsInfoComponent.module.scss'

const AREA_DISTINGUISH_DOMESTIC = 10
const AREA_DISTINGUISH_FOREIGN_COUNTRY = 20
const AREA_DISTINGUISH_UNKNOWN = 990

const DISPLAY_AREA_DISTINGUISH = {
  [AREA_DISTINGUISH_DOMESTIC]: '内国作品',
  [AREA_DISTINGUISH_FOREIGN_COUNTRY]: '外国作品',
  [AREA_DISTINGUISH_UNKNOWN]: '不明',
}

const CLASS_AREA_DISTINGUISH = {
  [AREA_DISTINGUISH_DOMESTIC]: "domestic",
  [AREA_DISTINGUISH_FOREIGN_COUNTRY]: "foreignCountry",
  [AREA_DISTINGUISH_UNKNOWN]: "unknown",
}

const CAUTION_TRUST_LIQUIDATION_PROCEEDINGS = 10
const CAUTION_ATTENTION = 20
const CAUTION_RECORDING_PROHIBITED = 110
const CAUTION_VIDEO_PROHIBITED = 120
const CAUTION_RECORDING_AND_VIDEO_PROHIBITED = 130
const CAUTION_PUBLICATION_PROHIBITED = 140

const DISPLAY_CAUTION = {
  [CAUTION_TRUST_LIQUIDATION_PROCEEDINGS]: "信託清算手続中",
  [CAUTION_ATTENTION]: "注意作品",
  [CAUTION_RECORDING_PROHIBITED]: "録音禁止",
  [CAUTION_VIDEO_PROHIBITED]: "ビデオ禁止",
  [CAUTION_RECORDING_AND_VIDEO_PROHIBITED]: "録音・ビデオ禁止",
  [CAUTION_PUBLICATION_PROHIBITED]: "出版禁止",
}

const MANAGEMENT_MANAGEMENTED = 10
const MANAGEMENT_PARTIAL_MANAGEMENT = 20
const MANAGEMENT_UNMANAGEMENT = 30
const MANAGEMENT_INQUIRY = 40
const MANAGEMENT_UNKNOWN = 90

const DISPLAY_MANAGENTS = {
  [MANAGEMENT_MANAGEMENTED]: "○",
  [MANAGEMENT_PARTIAL_MANAGEMENT]: "△",
  [MANAGEMENT_UNMANAGEMENT]: "×",
  [MANAGEMENT_INQUIRY]: "！",
  [MANAGEMENT_UNKNOWN]: "？"
}

const CLASS_MANAGENTS = {
  [MANAGEMENT_MANAGEMENTED]: "management",
  [MANAGEMENT_PARTIAL_MANAGEMENT]: "partialManagement",
  [MANAGEMENT_UNMANAGEMENT]: "unmanagement",
  [MANAGEMENT_INQUIRY]: "inquiry",
  [MANAGEMENT_UNKNOWN]: "unknown"
}

// const NOTE_PARTIAL_CONSIGNED = 10
// const NOTE_UNCONSIGNED = 20
// const NOTE_UNDER_LIQUIDATION = 30
// const NOTE_EXCLUSIVE_CONTRACT = 40
// const NOTE_NOT_OWN_COPYRIGHT = 50
// const NOTE_CONSIGN_UNKNOWN = 990

// const DISPLAY_NOTE = {
//   [NOTE_PARTIAL_CONSIGNED]: "△",
//   [NOTE_UNCONSIGNED]: "×",
//   [NOTE_UNDER_LIQUIDATION]: "精算手続中",
//   [NOTE_EXCLUSIVE_CONTRACT]: "専属",
//   [NOTE_NOT_OWN_COPYRIGHT]: "-",
//   [NOTE_CONSIGN_UNKNOWN]: "不明",
// }


//const AUTHOR_TYPE_COMPOSER = 10 // 作曲
//const AUTHOR_TYPE_LYRICIST = 20 // 作詞
const AUTHOR_TYPE_ARRANGER = 30 // 編曲
const AUTHOR_TYPE_ARTIST = 40 // アーティスト ※ 「識別」には存在しない
const AUTHOR_TYPE_COMPLEMENTARY = 50 // 補曲
const AUTHOR_TYPE_COMPLEMENTS = 60 // 補詞
//const AUTHOR_TYPE_TRANSLATION = 70 // 訳詞
const AUTHOR_TYPE_ARRANGERWHENPUBLICATION = 80 // 公編
const AUTHOR_TYPE_UNKNOWN = 90 // 不明
const AUTHOR_TYPE_PUBLISHER = 110 // 出版者
const AUTHOR_TYPE_SUBPUBLISHING = 120 // サブ出版
const AUTHOR_TYPE_SYSTEMUNKNOWN = 990 // システム不明

// const DISPLAY_AUTHOR_TYPE = {
//   [AUTHOR_TYPE_COMPOSER]: '作曲',
//   [AUTHOR_TYPE_LYRICIST]: '作詞',
//   [AUTHOR_TYPE_ARRANGER]: '編曲',
//   [AUTHOR_TYPE_ARTIST]: 'アーティスト',
//   [AUTHOR_TYPE_COMPLEMENTARY]: '補曲',
//   [AUTHOR_TYPE_COMPLEMENTS]: '補詞',
//   [AUTHOR_TYPE_TRANSLATION]: '訳詞',
//   [AUTHOR_TYPE_ARRANGERWHENPUBLICATION]: '公編',
//   [AUTHOR_TYPE_UNKNOWN]: '不明',
//   [AUTHOR_TYPE_PUBLISHER]: '出版者',
//   [AUTHOR_TYPE_SUBPUBLISHING]: 'サブ出版',
//   [AUTHOR_TYPE_SYSTEMUNKNOWN]: 'システム不明',
// }

const RightsTranslation = {
  0:"なし",
  1: "原詞",
  2: "訳詞",
  3: "不明"
}

const LicenseStatus = {
  new:       0,  // New
  registered:10, // Registered information
  applied:   20, // Applied
  approved:  30, // Approved
  rejected:  40  // Rejected
}


const ImageModal = ({ src, style }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className={styles.imageModal} >
      <img onClick={handleOpen} src={src} style={style} alt="画像" />
      <div onClick={handleClose} className={styles.modalOverlay} style={{"zIndex":100, "visibility":(isOpen ? "visible":"hidden")}}>
        <div className={styles.modalContent}>
          <img src={src} alt="画像" style={{"background":"#FFFFFF", "border":"1px solid #000000", "boxShadow":"0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)"}}/>
        </div>
      </div>
    </div>
  );
};

export default function PSPublishRightsInfoComponent({ score_json, account_json, api_base_url, lc_nichion_foreign_json }) {

  const score   = JSON.parse(score_json);
  const account = JSON.parse(account_json);
  const lc_nichion_foreign = JSON.parse(lc_nichion_foreign_json);

  const work_codes = (score.rights_management_number != null) ? score.rights_management_number.split("/") : []

  //console.log(score.license_files)

  return (
    <>
      {
        score.has_rights ?
        (
          <div className={styles.rightsInfo}>
            { /* 上部 */ }
            <div className={styles.status}>

              <div className={styles.item}>
                <div className={styles.header}>
                  販売可能
                </div>
                <div className={ `${styles.body} ${(score.has_rights && !(score.rights_enable_publish && score.rights_enable_arrange)) ? "need_admin_check" : ""}` }>
                  {
                    score.rights_enable_publish && score.rights_enable_arrange ?
                      <span style={{"color":"#49C02B"}}>確認済</span>
                    :
                      <span style={{"color":"#C0392B"}}>未確認</span>
                  }
                </div>
              </div>

              <div className={styles.item}>
                <div className={styles.header}>
                  IVT
                </div>
                <div className={ `${styles.body} need_admin_check` }>
                  { (score.rights_ivt == "V" || score.rights_ivt == "T") && <span className={styles.label_lyricist}>詞</span> }
                  { (score.rights_ivt == "V") && <span>・</span> }
                  { (score.rights_ivt == "I" || score.rights_ivt == "V") && <span className={styles.label_composer}>曲</span> }
                </div>
              </div>

              <div className={styles.item} style={{visibility: (score.rights_ivt == "I" ? "hidden" : "visible") }}>
                <div className={styles.header}>
                  原詞訳区分
                </div>
                <div className={ `${styles.body} need_admin_check` }>
                  {
                    RightsTranslation[score.rights_translation] 
                  }
                </div>
              </div>

              <div style={{"clear":"both"}} />
            </div>

            { /* 作品コード */ }
            <div className={ styles.workInfo }>
              {
                // 作品コード
                work_codes.map((work_code, index) => {
                  return (
                    <div key={`info-${index}`}>
                      <PSPublishRightsInfoCard api_base_url={api_base_url} work_code={work_code} />
                    </div>
                  )
                })
              }
            </div>
          </div>
        ) : 
        (
          <div style={{fontSize:12}}>
            <div>
              <span style={{"color":"#333333"}}>作品コードがありません</span>
            </div>
            <div style={{"margin-top":4}}>
              <a style={{padding:3, fontSize:12}} target="_blank" href="https://www2.jasrac.or.jp/eJwid/">JASRAC<FontAwesomeIcon icon="fas fa-arrow-up-right-from-square" style={{margin:"0 0 0 3px"}} /></a>
              <span style={{margin:"0px 4px", color:"#999"}}>/</span>
              <a style={{padding:3, fontSize:12}} target="_blank" href="https://search.nex-tone.co.jp/terms?3">NexTone<FontAwesomeIcon icon="fas fa-arrow-up-right-from-square" style={{margin:"0 0 0 3px"}}/></a>
            </div>
          </div>
        )
      }

      
      { /* ライセンス情報 */ }
      <div className={ styles.license }>
        <table className={ styles.rights_table } style={{"margin":"10px 0 0 0"}}>
          <tbody>
            <tr>
              <td className={ styles.item_column }>許諾書</td>
              <td className={ styles.value_column }>
                {
                  score.license_files.length == 0 ?
                    <span className={ styles.content_none }>なし</span>
                  :
                  score.license_files.map((license_file, index) => {
                    return (
                      <div style={{"float":"left", "margin":"0 4px 0 0"}}>
                        <div>
                          <ImageModal src={`/scores/${score.score_id}/license?index=${index}`} style={{"border":"1px solid #DDD", "height":"61px", cursor:"zoom-in"}} />
                        </div>
                        <div style={{"margin":"10px auto", "textAlign":"center"}}>
                          <a href={`/scores/${score.score_id}/download_license_pdf?index=${index}`} data-turbolinks="false" style={{ "padding":"5px", "border":"1px solid #8f8f8f", "borderRadius":"3px", "background":"#f1f1f1", "color":"#8f8f8f"}}>DL</a>
                        </div>
                      </div>
                    )
                  })
                }
              </td>
            </tr>
            { /* PSM-318 */ }
            {
              score.has_rights ?
              account.authority == 10000 || account.authority == "authority_admin" ? // account.authority == "authority_admin" is for Activerecords
              (
                <tr>
                  <td className={ styles.item_column }>コピーライト表記</td>
                  <td className={ styles.value_column } style={{"whiteSpace":"pre-wrap"}}>{score.rights_licensed_text ? score.rights_licensed_text : "なし"}</td>
                </tr>
              )
              :
              (
                <tr>
                  <td className={ styles.item_column }>コピーライト表記</td>
                  <td className={ styles.value_column }>{score.rights_licensed_text ? "あり" : "なし"}</td>
                </tr>
              )
              : ""
            }
            {
              score.has_rights ?
              (
                <tr>
                  <td className={ styles.item_column }>代理申請出版社</td>
                  <td className={ styles.value_column }>
                    <div>
                      { (lc_nichion_foreign.status == 0 || lc_nichion_foreign.status=="status_new") && <span style={{"fontSize":"10px", "borderRadius":"3px", "background":"#d36b00", "padding":"3px 4px", "color":"#FFFFFF"}}>未登録</span> }
                      { (lc_nichion_foreign.status == 30 || lc_nichion_foreign.status=="status_approved") && <span style={{"fontSize":"10px", "borderRadius":"3px", "background":"#82c91e", "padding":"3px 4px", "color":"#FFFFFF"}}>承認済</span> }
                      <span style={{"padding":"0 5px"}}>日音（外国曲）</span>
                      { (lc_nichion_foreign.status == 30 || lc_nichion_foreign.status=="status_approved") && <span>（{lc_nichion_foreign.license_number}）</span> }
                    </div>

                    {
                    /*
                      div class="proxy-license"
                        div class="status"
                          - bgcolor=""
                          /- subtext=""
                          - case lc.status.to_sym
                          - when :status_new
                            - bgcolor="#d36b00"
                            - text="未登録"

                          span style="font-size:10px; border-radius:3px; background:#{bgcolor}; padding:3px 4px; color:#FFFFFF;"
                            = text
                          span style="margin:0 0 0 4px;"
                            = lc.license_company_name
                            - case lc.status.to_sym
                            - when :status_new
                            - when :status_registered
                              - if lc.license_company_id.to_sym == :nichion_foreign
                                br
                                span style="font-weight:normal; font-size:11px; padding:0 0 0 37px;"
                                  = "（"
                                  = "#{lc.license_number}"
                                  = "）"
                      */
                      }
                  </td>
                </tr>
              )
              : ""
            }
          </tbody>
        </table>
      </div>

    </>
  )      
}

function PSPublishRightsInfoCard({ api_base_url, work_code }) {

  const [musicRight, setMusicRight] = useState({})

  useEffect(() => {
    (async () => {
      try {
        let url = api_base_url + "/music_rights/" + work_code;
        //console.log(url);
    
        //const apiResponse = await apiClient.get('/' + workCode)
        //const apiResponse = await apiClient.get(url)
        const apiResponse = await axios.get(url, {
          withCredentials: true
        })
        //console.info(apiResponse)
        const result = apiResponse.data.body

        // アーティスト
        result.artists    = result.authors.filter(author => author.type === AUTHOR_TYPE_ARTIST)
        //result.cp_holders = result.authors.filter(author => author.type !== AUTHOR_TYPE_ARTIST)
      
        // 更新時間
        {
          // const duration = (new Date() - new Date(result.lastCheckTime)) / 1000
          // //console.log(duration)
          // let str = ""
          // if (duration < 3600)                              { str = "約" + Math.floor(duration/60) + "分前" }
          // if (3600 <= duration && duration < 3600*24)       { str = "約" + Math.floor(duration/(60*60)) + "時間前" }
          // if (3600*24 <= duration && duration < 3600*24*30) { str = "約" + Math.floor(duration/(60*60*24)) + "日前" }
          // if (3600*24*30 <= duration && duration)           { str = "約" + Math.floor(duration/(60*60*24*30)) + "ヶ月前" }
          //console.log(str)
          result.lastCheckTimeStr = PSTimeUtil.getRelativeTime(result.lastCheckTime)
        }

        // 
        // result.confirmComment = result.confirmComment.replace(/\n/g, "<br />");
        
        const subdivs = [
          {
            name: '配信',
            value: result.subdiv.multi.interactive,
          },
          {
            name: '出版',
            value: result.subdiv.copy.publish,
          },
        ]

        setMusicRight({
          ...result,
          subdivs: [
            ...subdivs,
          ]
        })
      } catch (e) {
        console.error(e)
        console.info(e.response.data)
        throw e
      }
    })()
  },[work_code])

  const ConfirmCautionIds = [
    {
      value: 0, 
      label: 'なし',
    },
    {
      value: 1010, 
      label: '承認待機',
    },
    {
      value: 9990, 
      label: 'その他の注意',
    },
  ]

  return (
    <>
      {
        Object.keys(musicRight).length ? (
          <Card className={styles.card}>
            <Container className={styles.container}>
              <Row>
                <Col>
                  <Container className={styles.container}>
                    <Row>
                      <Col className={`${styles.lastCheckTime} ${ (new Date() - new Date(musicRight.lastCheckTime))/1000 > (3600 * 24 * 30 * 3) ? styles.warning : ""}`}>
                        最終更新：{ musicRight.lastCheckTimeStr }
                      </Col>
                    </Row>
                    <Row>
                      <Col className={styles.title}>
                        <a target="_blank" href={`https://www.google.com/search?q=${encodeURI(musicRight.title + " 曲")}`}>
                          { PSTextUtil.zenkaku2Hankaku({text:musicRight.title.replace(/　/g, " ")}) } <FontAwesomeIcon icon="fa-solid fa-earth-americas" />
                        </a>
                      </Col>
                      <Col xs={4} className={styles.caution}>
                        {
                          musicRight.caution ? (
                            <div className={styles.badge}>
                              { DISPLAY_CAUTION[musicRight.caution] }
                            </div>
                          ) : null
                        }
                      </Col>
                    </Row>

                    {/*
                    <Row>
                      <Col xs={2}>
                        <a target="_blank" href={`https://www.print-gakufu.com/search/result/score___keyword__${encodeURI(musicRight.title)}/`}>
                          ぷ
                        </a>
                      </Col>
                      <Col xs={2}>
                        <a target="_blank" href={`https://www.at-elise.com/goods/list?free_word=${encodeURI(musicRight.title)}`}>
                          @e
                        </a>
                      </Col>
                    </Row>
                    */}

                    <Row>
                      <Col xs={2}>
                        <div className={`${styles.areaDistinguish} ${styles[CLASS_AREA_DISTINGUISH[musicRight.areaDistinguish]]}`}>
                          { DISPLAY_AREA_DISTINGUISH[musicRight.areaDistinguish] }
                        </div>
                      </Col>
                      <Col xs={6} className={styles.workCode}>
                        <a target="_blank" href={`https://www2.jasrac.or.jp/eJwid/main?trxID=F20101&WORKS_CD=${musicRight.workCode}&subSessionID=001&subSession=start`}>{ musicRight.workCode }<FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" /></a>
                        <a target="_blank" href={`/scores?keyword=${encodeURI(musicRight.workCode)}`} style={{margin:"0 0 0 5px"}}><FontAwesomeIcon icon="fa-solid fa-magnifying-glass" /></a>
                      </Col>
                      <Col className={styles.authority}>
                        { musicRight.authority }
                      </Col>
                    </Row>

                    <Row>
                      <Col className={styles.subdivs}>
                        {
                          musicRight.subdivs.map(subdiv => {
                            return (
                              <div key={subdiv.name} className={styles.div}>
                                <Card className={`${styles.card} ${styles[CLASS_MANAGENTS[subdiv.value]]}`}>
                                  <Card.Body className={styles.body}>
                                    <Card.Title className={styles.card_title}>{ subdiv.name }</Card.Title>
                                    <Card.Text className={`${styles.mark} ${styles[CLASS_MANAGENTS[subdiv.value]]}`}>
                                      { DISPLAY_MANAGENTS[subdiv.value] }
                                    </Card.Text>
                                  </Card.Body>
                                </Card>
                              </div>
                            )
                          })
                        }
                      </Col>
                    </Row>

                    <Row className={styles.confirm}>
                      <Col className={styles.col}>
                        <PSPublishRightsInfoConfirmComponent api_base_url={api_base_url} work_code={ work_code} confirmCautionId={musicRight.confirmCautionId} confirmComment={musicRight.confirmComment} />
                      </Col>
                    </Row>

                    <Row className={styles.authors}>
                      <Col className={styles.col}>
                        <PSPublishRightsCPHoldersComponent api_base_url={api_base_url} authors={musicRight.authors} />
                      </Col>
                    </Row>

                    <Row className={styles.authors}>
                      <Col className={styles.col}>
                        <div style={{fontSize:10}}>アーティスト</div>
                        <Table bordered className={styles.table}>
                          <tbody style={{display:"flex", flexWrap:"wrap"}}>
                            {
                              musicRight.artists.slice(0,3).map((author, authorIndex) => {
                                return (
                                  <tr key={`artist-${authorIndex}`} style={{flexBasis: "50%"}}>
                                    <td style={{width:20}}>{ authorIndex + 1 }</td>
                                    <td className={styles.name} style={{width:"100%"}}>
                                      <a target="_blank" href={`https://www.google.com/search?q=${encodeURI(author.name)}`}>
                                        { PSTextUtil.zenkaku2Hankaku({text:author.name.replace(/　/g, " ")}) } <FontAwesomeIcon icon="fa-solid fa-earth-americas" />
                                      </a>
                                      <div style={{marginTop:2}}>
                                        <PSPublishRightsAuthorInfoConfirmComponent api_base_url={api_base_url} author_id={author.authorId} confirmCautionId={author.confirmCautionId} confirmComment={author.confirmComment} />
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                    
                  </Container>
                </Col>
              </Row>
            </Container>
          </Card>
        ) 
        : 
        (
          <Card className={styles.card} style={{"padding":"5px"}}>
            <Container className={styles.container}>
              <Row>
                <Col>
                  <span style={{"padding":"5px 7px", "textAlign":"center", "borderRadius":10, margin:0, "fontSize":10, "fontWeight":"bold", color:(work_code.startsWith("N") ? "#F08300" : "#0066CC"), background:(work_code.startsWith("N") ? "#ffd9ab" : "#d4e9ff") }}>
                    { work_code.startsWith("N") ? "N" : "J"}
                  </span>
                  <input type="text" className="form-control" style={{margin:"0 0 0 3px", fontSize:12, width:91, height:30, padding:5, display:"inline"}} value={work_code} readOnly onFocus={ (e) => {e.target.select()} } />
                  
                  {
                    work_code.startsWith("N") ?
                      (
                        <>
                          <a style={{padding:3, fontSize:13, color:"#0083EA"}} onClick={() => global.navigator.clipboard.writeText(work_code)}><FontAwesomeIcon icon="far fa-copy" /></a>
                          <a style={{padding:3, fontSize:13}} target="_blank" href="https://search.nex-tone.co.jp/terms?3"><FontAwesomeIcon icon="fas fa-arrow-up-right-from-square" /></a>
                        </>
                      )
                      :
                      <span> <a style={{padding:3, fontSize:13}} target="_blank" href={`https://www2.jasrac.or.jp/eJwid/main?trxID=F20101&WORKS_CD=${work_code}&subSessionID=001&subSession=start`}><FontAwesomeIcon icon="fas fa-arrow-up-right-from-square" /></a> の情報は未登録です</span>
                    }
                </Col>
              </Row>
            </Container>
          </Card>
        )
      }
    </>
  )
}

// ブラウザーの Canvas を使った画像処ライブラリー

//  imageSrcで指定された画像データ(Data URLs)を
//  1024 x 1024dotにリサイズした画像データ(Data URLs)を戻す

export const enlargeImageTo1024 = async (imageSrc) => {
  const image = new Image();
  image.src = imageSrc;
  await image.decode();

  const minImageSize = image.width < image.height ? image.width : image.height;
  if (minImageSize >= 1024)  return null;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx)  return null;

  const zoomRate = 1024 / minImageSize;
  canvas.width  = image.width  * zoomRate;
  canvas.height = image.height * zoomRate;
  ctx.scale(zoomRate, zoomRate);
  ctx.drawImage(image, 0, 0);

  const [, mimeType] = imageSrc.match(/^data:(.*?);/);
  return canvas.toDataURL(mimeType);
}

//  imageSrcで指定された画像データ(Data URLs)を
//  矩形エリアareaで指定された大きさで切り取った画像データ(Data URLs)を戻す
//  areaの型は {x: number; y: number; width: number; height: number}

export const cropedImageTo1024 = async (imageSrc, area) => {
  const image = new Image();
  image.src = imageSrc;
  await image.decode();

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx)  return null;

  canvas.width  = 1024;
  canvas.height = 1024;
  ctx.drawImage(image, area.x, area.y, area.width, area.height,
    0, 0, 1024, 1024);

  const [, mimeType] = imageSrc.match(/^data:(.*?);/);
  return canvas.toDataURL(mimeType);
}

//  1024 x 1024dotの空白画像データ(Data URLs)を戻す

export const blankImage1024 = () => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx)  return null;

  canvas.width  = 1024;
  canvas.height = 1024;
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, 1024, 1024);
  return canvas.toDataURL("image/png");
}

//  imageUrlで指定された400 x 400dotの画像を読み込み
//  1024 x 1024dotの画像データ(Data URLs)を戻す

export const getIcomImage1024 = async (imageUrl) => {
  const image = new Image();
  image.crossOrigin = 'Anonymous';
  image.src = imageUrl;

  try {
    await image.decode();
  } catch(e) {
    return null;
  }

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx)  return null;

  canvas.width  = 1024;
  canvas.height = 1024;
  ctx.drawImage(image, 0, 0, 400, 400,
                       0, 0, 1024, 1024);

  return canvas.toDataURL("image/jpg");
}

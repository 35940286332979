import React from 'react';
import { Container, Row, Col, Form, Button, Card, Table } from 'react-bootstrap'

import { useEffect } from 'react'
import { useState } from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { library }         from '@fortawesome/fontawesome-svg-core'
// import { far } from '@fortawesome/free-regular-svg-icons'
// import { fas } from '@fortawesome/free-solid-svg-icons'
// import { fab } from '@fortawesome/free-brands-svg-icons'
// library.add(far, fas, fab)

import PSAPIClient    from '../common/PSAPIClient.js'
import PSEditableText from '../common/PSEditableText.js'

import styles from './PSPublishRightsInfoConfirmComponent.module.scss'

export default function PSPublishRightsInfoConfirmComponent({ api_base_url, work_code, confirmCautionId, confirmComment }) {

  const [currentCautionId,  setCurrentCautionId] = useState(confirmCautionId)
  const [currentComment,    setCurrentComment]   = useState(confirmComment)
  const [isVisible,         setIsVisible]        = useState(false)
  const [timer, setTimer] = useState(null);

  //let timerId = null;
  
  const ConfirmCautionIds = [
    {
      value: 0, 
      label: 'なし',
    },
    {
      value: 1010, 
      label: '承認待機',
    },
    {
      value: 9990, 
      label: 'その他の注意',
    }
  ]

  const handleChangeConfirmCautionId = async (e) => {

    const cautionId = e.target.value
    //console.log(cautionId + ":" + value);

    await updateConfirmInfo({cautionId, comment:currentComment})
  }
  
  const updateConfirmComment = async (comment) => {

    //console.log(comment);
    
    if(comment == currentComment) {
      //console.log("Same");
      return;
    }
    
    await updateConfirmInfo({cautionId:currentCautionId, comment})
  }
  
  const updateConfirmInfo = async ({cautionId, comment}) => {

    cautionId = parseInt(cautionId) // 0が文字列だと true 評価になってしまうので、ここで数値に変換する

    let url = api_base_url + "/music_rights/" + work_code + "/approval_info";
    //console.log(url)
    const data = {
      confirmCautionId: cautionId,
      confirmComment:   comment
    }

    const response = await PSAPIClient.post({url, data})
    //console.debug(response);

    setCurrentCautionId(cautionId)
    setCurrentComment(comment)
    
    // notification
    {
      setIsVisible(true)
      clearTimeout(timer);
      
      setTimer(setTimeout(() => {
        setIsVisible(false);
      }, 3000)); 
    }
  };

  return (
    <div style={ 
      (currentCautionId || currentComment ) ? 
      {padding:5, "border":"3px solid #e50000", "background":"#ffefef"} 
      : 
      {padding:5, "border":"1px solid #666", "background":"#FFF"} 
    }>
      <div>
        <span style={{"fontSize":10, "fontWeight":"bold", color:"#333"}}>注意メモ</span>
      </div>
      <div>
        <Form.Select style={{"fontSize":12, "padding":4, "fontWeight":"bold"}} name="confirmCautionId" onChange={ e => handleChangeConfirmCautionId(e) } value={ currentCautionId || 0 } >
          {
            ConfirmCautionIds.map(ConfirmCautionId => (
              <option value={ ConfirmCautionId.value } key={ConfirmCautionId.value}>{ `${ConfirmCautionId.value ? `${ConfirmCautionId.value} : `: ''}${ConfirmCautionId.label}` }</option>
            ))
          }
        </Form.Select>
      </div>
      <div style={{marginTop:3}}>
        <PSEditableText text={ currentComment } didEditTextHandler={ text => { updateConfirmComment(text); } } />
      </div>
      <div>
        <span style={{ fontSize:10, color:"#008800", display: isVisible ? 'inline' : 'none' }}>更新しました</span>
      </div>
    </div>
  )
}
// import { Container, Row, Col, Form, Button, Card, Table } from 'react-bootstrap'
// import { useEffect, useState, useRef } from 'react'

import React from 'react';

// import styles from './PSPublishAccountAddressFormComponent.module.scss'

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { library }         from '@fortawesome/fontawesome-svg-core'
// import { far } from '@fortawesome/free-regular-svg-icons'
// import { fas } from '@fortawesome/free-solid-svg-icons'
// import { fab } from '@fortawesome/free-brands-svg-icons'
// library.add(far, fas, fab)

export default function PSRequiredIcomComponent({ fontSize=10 }) {

  return (
    <span style={{background:"#cc0000", color:"#FFFFFF", padding:4, borderRadius:3, fontWeight:"normal", fontSize:fontSize}}>必須</span>
  )
}
import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Row, Form, InputGroup, Alert } from 'react-bootstrap'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { library }         from '@fortawesome/fontawesome-svg-core';
// import { far } from '@fortawesome/free-regular-svg-icons';
// import { fas } from '@fortawesome/free-solid-svg-icons';
// import { fab } from '@fortawesome/free-brands-svg-icons';
// library.add(far, fas, fab);

import PSAPIClient from '../../common/PSAPIClient';

import styles from './PSPublishAccountInvoiceInputComponent.module.scss'

export default function PSPublishAccountInvoiceInputComponent({ apiBaseURL, accountId }: { apiBaseURL: string, accountId: number}) {

  const [invoiceNo, setInvoiceNo] = useState("");
  const [invoiceName, setInvoiceName] = useState("");
  //const [errorMessage, setErrorMessage] = useState("");
  const [inputDisabled, setInputDisabled]   = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  //const [accountId, setAccountId] = useState(null);
  
  const [message, setMessage] = useState(null);

  const invoiceRegisteredText = {
    0: "確認中",
    10: "登録済",
    20: "未登録",
  }
  
  useEffect(() => {
    (async () => {
      // setSubmitDisabled(true)
      // setSubmitDisabled(true)

      let url = `${apiBaseURL}/accounts/invoice/register_info/`
      url = accountId ? url + accountId : url
      try {
        const res = await PSAPIClient.get({url: url});
        const invoiceRegistered = res.body.invoice_registered;
        
        const msgJSX = (
          <>
            <Row>
              <Col>既に登録されています（{ invoiceRegisteredText[invoiceRegistered] }）</Col>
            </Row>
          </>
        )

        console.log(res)

        setInvoiceName(res.body.name);
        setInvoiceNo(res.body.invoice_number);
        setMessage(msgJSX);
        
        setInputDisabled(true)
        setSubmitDisabled(true)
      } catch (e) {        
        console.error(e);
        switch (e.statusCode) {
          case 400:
            setMessage("入力値が正しくありません");
            break;
          case 401:
            setMessage("ログインしていません");
            break;
          case 404:
            //setMessage("登録されていないインボイス番号です"); 表示しない
            break;
          case 409:
            setMessage("既にインボイス番号が登録されています");
            break;
          default:
            setMessage("通信またはサーバーでエラーが発生しました");
            break;
        }
      }
    })()
  }, []);

  const handleError = (e) => {
    switch (e.statusCode) {
      case 400:
        setMessage("入力値が正しくありません");
        break;
      case 401:
        setMessage("ログインしていません");
        break;
      case 404:
        setMessage("登録されていないインボイス番号です");
        break;
      case 409:
        setMessage("既にインボイス番号が登録されています");
        break;
      default:
        setMessage("通信またはサーバーでエラーが発生しました");
        break;
    }
  }
  
  const registerInvoiceNo = async(e) => {
    e.preventDefault();
    try {
      //const res = await PSAPIClient.post({
      const res = await PSAPIClient.put({
        url: `${apiBaseURL}/accounts/invoice/register_info`,
        data: {
          invoice_name: invoiceName,
          invoice_number: invoiceNo
        }
      });

      console.log(res.body)

      const msgJSX = (
        <>
          <Row>
            <Col>✅ 新規に登録されました</Col>
          </Row>
        </>
      )
      // setInvoiceName(res.body.name);
      // setInvoiceNo(res.body.invoice_number);
      setMessage(msgJSX);
      
      setInputDisabled(true)
      setSubmitDisabled(true)    
    } catch (e) {
      console.error(e);
      handleError(e)
    }
  }
    
  const blurInvoiceNo = async (value) => {
  
    setMessage(null);
    setSubmitDisabled(true)
    
    if(value=="") { return }
    
    if(!/^\d{13}$/.test(value)) { 
      setMessage("13文字の半角数字をご入力ください");
      return
    }

    {
      const url =`${apiBaseURL}/accounts/invoice/check_data/${value}`
      //console.log(url)
      try {
        const res = await PSAPIClient.get({url: url});
        //console.log(res.body)
        const msgJSX = (
          <>
            <Row style={{lineHeight:"1.6em"}}>
              <Col sm={1}>✅</Col>
              <Col><a target="_blank" href="https://www.invoice-kohyo.nta.go.jp/">国税庁インボイス制度適格請求書発行事業者公表サイト</a>に登録されていることが確認できました。</Col>
            </Row>
            <Row>
              <Col style={{textAlign:"right"}} sm={5}>登録番号：</Col>
              <Col style={{fontWeight:"bold"}}>T{ value }</Col>
            </Row>
            <Row>
              <Col style={{textAlign:"right"}} sm={5}>氏名または名称：</Col>
              <Col style={{fontWeight:"bold"}}>{ res.body.name }</Col>
            </Row>
          </>
        )
        setMessage(msgJSX);
        setSubmitDisabled(!invoiceName && false)
      } catch (e) {        
        console.error(e);
        handleError(e)
      }

    }
  }

  return (
    <>
      <div className="fill d-flex align-items-center justify-content-center">
        <Card style={{width:500}} className="p-3">
          <Card.Body>
            <Card.Title style={{textAlign:"center"}}>インボイス番号の登録</Card.Title>
            <Form onSubmit={ registerInvoiceNo } className="mt-4">
              <Form.Group  as={Row} className="mt-4">
                <Form.Label column sm={4} style={{textAlign:"right"}}>氏名または名称</Form.Label>
                <Col sm={7}>
                  <Form.Control size="lg" placeholder="株式会社インボイス" disabled={inputDisabled} required title="株式会社インボイス" value={invoiceName} onChange={(e) => setInvoiceName(e.currentTarget.value)} />
                </Col>
              </Form.Group>

              <Form.Group  as={Row} className="mt-4">
                <Form.Label column sm={4} style={{textAlign:"right"}}>登録番号</Form.Label>
                <Col sm={7}>
                  <div>
                    <InputGroup style={{width:"100%"}}>
                      <InputGroup.Text>T</InputGroup.Text>
                      <Form.Control type="number" size="lg" className={styles.no_spin} placeholder="1234567890123" disabled={inputDisabled} required pattern="^\d{13}$" title="数字13桁です" value={invoiceNo} onBlur={(e) => blurInvoiceNo(e.currentTarget.value) } onChange={(e) => setInvoiceNo(e.currentTarget.value)} />
                    </InputGroup>
                  </div>                  
                </Col>
              </Form.Group>

              {
                message ? 
                (
                  <Alert style={{width:340, margin:"10px auto", fontSize:13, color:"#CC0000"}} variant="warning">{ message }</Alert>
                ): ""                
              }
              
              {
                submitDisabled ? 
                "" :
                (
                  <div style={{fontSize:14}}>
                  登録番号が正しいことが確認できたら「登録する」を押してください
                  </div>
                )  
              }

              <Form.Group  as={Row} className="mt-4">
                <Button size="lg" type="submit" className="w-75 mx-auto" disabled={submitDisabled}>登録する</Button>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}


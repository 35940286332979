import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Alert, FloatingLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library }         from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(far, fas, fab);

import PSRequiredIcomComponent from '../common/PSRequiredIcomComponent.js'
import PSAPIClient from '../common/PSAPIClient';

function ArrowLink({text, onClick}) {
  return (
    <a href="#" onClick={e => {e.preventDefault();  onClick();}}
      style={{
        color: "#1676cf", fontWeight: "normal",
        fontSize: 12, marginLeft: 20} }>
      <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" /> {text}
    </a>
  );
}

export default function PSPublishAccountMailAddressChangeFormComponent({ mail_address, api_base_url }) {
  const [mailAddress, setMailAddress] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [mailAdressInput, setMailAdressInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [show, setShow] = useState(false);
  const [requested, setRequested] = useState(false);

  const closeDialog = () => {
    setShow(false);
  }
  const showDialog = () => {
    setMailAdressInput("");
    setPasswordInput("");
    setRequested(false);
    setShow(true);
  }

  const changeMailAddress = async() => {
    try {
      await PSAPIClient.post({
        url: `${api_base_url}/accounts/mail_address/change`,
        data: {password: passwordInput, mail_address: mailAdressInput},
      });
      setErrorMessage("");
      setRequested(true);
    } catch (e) {
      console.error(e);
      if (e?.message?.match(/Conflict/)) {
        setErrorMessage("新しいメールアドレスが既に登録されています");
      } else {
        setErrorMessage(PSAPIClient.errorMessage(e));
      }
    }
  }


  useEffect(() => {
    setMailAddress(mail_address);
  }, [mail_address]);

  return (
    <>
      <span style={{fontWeight:"bold"}}>{ mailAddress }</span>
      <ArrowLink text="メールアドレスの変更" onClick={showDialog} />

      <Modal show={show} onHide={closeDialog} fullscreen="md-down" animation={true} contentClassName="rounded-6 shadow h-auto" centered>
        <Modal.Header closeButton  className="p-5 pb-4 border-bottom-0">
          <Modal.Title as="h3" className="fw-bold"> <FontAwesomeIcon icon="fa-solid fa-envelope" /> メールアドレスの変更</Modal.Title>
        </Modal.Header>
        {requested ?
          <>
            <Modal.Body className="px-5" style={{fontSize: 15}}>
            <Alert variant="info" className="py-4 mb-4" style={{lineHeight:"1.8em"}}>
              確認メールを、新しいメールアドレスに送信しました。<br/>
              手続きから24時間以内に、メール文中のURLにアクセスして変更を確定して下さい。
            </Alert>
            </Modal.Body>
            <Modal.Footer className="p-5 pt-2 pb-4 border-top-0 text-center">
              <Button onClick={closeDialog} variant="secondary" className="w-100 btn-lg">
              閉じる
              </Button>
            </Modal.Footer>

          </> :
        <Form onSubmit={e => { e.preventDefault(); changeMailAddress();}} style={{fontSize: 15}}>
          {errorMessage.length > 0 &&
            <Alert variant="warning" className="px-3 py-4 m-3 mb-4">{errorMessage}</Alert>
          }
          <Modal.Body className="px-5 lh-base">
            <Form.Group className="mb-4">
              <div>
                <Form.Label>パスワード（確認のため入力してください）</Form.Label> <PSRequiredIcomComponent />
              </div>
              <FloatingLabel controlId="floatingInput" label="パスワード" className="mt-1">
                <Form.Control type="password" placeholder="name@example.com" required
                  className="rounded-4"
                  value={passwordInput} onChange={(e) => setPasswordInput(e.currentTarget.value)}/>
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-4">
              <div>
                <Form.Label>新しいメールアドレス</Form.Label> <PSRequiredIcomComponent />
              </div>
              <FloatingLabel controlId="floatingInput" label="メールアドレス" className="mt-1">
                <Form.Control type="email" placeholder="name@example.com" required
                  className="rounded-4"
                  value={mailAdressInput} onChange={(e) => setMailAdressInput(e.currentTarget.value)}/>
              </FloatingLabel>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="mb-3 p-5 pt-2 pb-4 border-top-0 text-center">
            <Button variant="primary" type="submit" className="w-100 btn-lg" style={{background:"#0083EA", padding:15}}>
              変更する
            </Button>
          </Modal.Footer>
        </Form>
        }
      </Modal>
    </>
  )
}

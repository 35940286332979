export default class PSTimeUtil {
  
  static getRelativeTime(timeString) {
    const duration = (new Date() - new Date(timeString)) / 1000
    let str = ""
    if (duration < 3600)                              { str = "約" + Math.floor(duration/60) + "分前" }
    if (3600 <= duration && duration < 3600*24)       { str = "約" + Math.floor(duration/(60*60)) + "時間前" }
    if (3600*24 <= duration && duration < 3600*24*30) { str = "約" + Math.floor(duration/(60*60*24)) + "日前" }
    if (3600*24*30 <= duration && duration)           { str = "約" + Math.floor(duration/(60*60*24*30)) + "ヶ月前" }
    return str
  }
  
  static toYYYYMMDD({timeString, separator="-"}) {
    return new Date(timeString).toLocaleDateString("ja-JP", {year: "numeric",month: "2-digit", day: "2-digit"}).replaceAll('/', separator)
  }

  static toYYYYMMDDHHMM({timeString, separator="-"}) {
    
    const yyyymmdd = new Intl.DateTimeFormat(
      undefined,
      {
        year:   'numeric',
        month:  '2-digit',
        day:    '2-digit',
        hour:   '2-digit',
        minute: '2-digit',
        // second: '2-digit',
        // fractionalSecondDigits: 3,
      }
    )
  
    //return new Date(timeString).toLocaleDateString("ja-JP", {year: "numeric",month: "2-digit", day: "2-digit"}).replaceAll('/', separator)
    return yyyymmdd.format( new Date(timeString)).replaceAll('/', separator)
  }
}
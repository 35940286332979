export default class PSTextUtil {

  static zenkaku2Hankaku({text}) {
    return text.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
  }

  static int2JPY({intValue}) {
    if (intValue == null) { return "" }
    
    return "¥" + intValue.toLocaleString()
  }

}
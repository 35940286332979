import { Container, Row, Col, Form, Button, Card, Table } from 'react-bootstrap'

import { useEffect } from 'react'
import { useState } from 'react'

import React from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library }         from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(far, fas, fab)

import PSTextUtil from '../common/PSTextUtil.js'

import PSPublishRightsAuthorInfoConfirmComponent from './PSPublishRightsAuthorInfoConfirmComponent.js'

import styles from './PSPublishRightsCPHoldersComponent.module.scss'


const NOTE_PARTIAL_CONSIGNED = 10
const NOTE_UNCONSIGNED = 20
const NOTE_UNDER_LIQUIDATION = 30
const NOTE_EXCLUSIVE_CONTRACT = 40
const NOTE_NOT_OWN_COPYRIGHT = 50
const NOTE_CONSIGN_UNKNOWN = 990

const AUTHOR_TYPE_COMPOSER = 10 // 作曲
const AUTHOR_TYPE_LYRICIST = 20 // 作詞
const AUTHOR_TYPE_ARRANGER = 30 // 編曲
const AUTHOR_TYPE_ARTIST = 40 // アーティスト ※ 「識別」には存在しない
const AUTHOR_TYPE_COMPLEMENTARY = 50 // 補曲
const AUTHOR_TYPE_COMPLEMENTS = 60 // 補詞
const AUTHOR_TYPE_TRANSLATION = 70 // 訳詞
const AUTHOR_TYPE_ARRANGERWHENPUBLICATION = 80 // 公編
const AUTHOR_TYPE_UNKNOWN = 90 // 不明
const AUTHOR_TYPE_PUBLISHER = 110 // 出版者
const AUTHOR_TYPE_SUBPUBLISHING = 120 // サブ出版
const AUTHOR_TYPE_SYSTEMUNKNOWN = 990 // システム不明

const DISPLAY_AUTHOR_TYPE = {
  [AUTHOR_TYPE_COMPOSER]: '作曲',
  [AUTHOR_TYPE_LYRICIST]: '作詞',
  [AUTHOR_TYPE_ARRANGER]: '編曲',
  [AUTHOR_TYPE_ARTIST]: 'アーティスト',
  [AUTHOR_TYPE_COMPLEMENTARY]: '補曲',
  [AUTHOR_TYPE_COMPLEMENTS]: '補詞',
  [AUTHOR_TYPE_TRANSLATION]: '訳詞',
  [AUTHOR_TYPE_ARRANGERWHENPUBLICATION]: '公編',
  [AUTHOR_TYPE_UNKNOWN]: '不明',
  [AUTHOR_TYPE_PUBLISHER]: '出版者',
  [AUTHOR_TYPE_SUBPUBLISHING]: 'サブ出版',
  [AUTHOR_TYPE_SYSTEMUNKNOWN]: 'システム不明',
}

const DISPLAY_NOTE = {
  [NOTE_PARTIAL_CONSIGNED]: "△",
  [NOTE_UNCONSIGNED]: "×",
  [NOTE_UNDER_LIQUIDATION]: "精算手続中",
  [NOTE_EXCLUSIVE_CONTRACT]: "専属",
  [NOTE_NOT_OWN_COPYRIGHT]: "-",
  [NOTE_CONSIGN_UNKNOWN]: "不明",
}

export default function PSPublishRightsCPHoldersComponent({ api_base_url, authors }) {

  const cp_holders = authors.filter(author => author.type !== AUTHOR_TYPE_ARTIST)
  //const [currentCautionId,  setCurrentCautionId] = useState(confirmCautionId)

  return (
    <>
      <Table bordered className={styles.table}>
        <thead>
          <tr>
            <th></th>
            <th>著作者/出版者</th>
            <th>識別</th>
            <th>契約</th>
            <th>所属</th>
            <th>特記</th>
          </tr>
        </thead>
        <tbody>
          {
            cp_holders.map((author, authorIndex) => {
              let authorTypeClass = ""
              switch (author.type) {
                case AUTHOR_TYPE_COMPOSER:
                  authorTypeClass = styles.type_10;
                  break
                case AUTHOR_TYPE_LYRICIST:
                case AUTHOR_TYPE_TRANSLATION:
                  authorTypeClass = styles.type_20;
                  break
                }
              return (
                <tr key={`author-${authorIndex}`}>
                  <td>{ authorIndex + 1 }</td>
                  <td className={styles.name}>
                    <a target="_blank" href={`https://www.google.com/search?q=${encodeURI(author.name)}`}>
                      { PSTextUtil.zenkaku2Hankaku({text:author.name.replace(/　/g, " ")}) } <FontAwesomeIcon icon="fa-solid fa-earth-americas" />
                    </a>
                    <div style={{marginTop:2}}>
                      <PSPublishRightsAuthorInfoConfirmComponent api_base_url={api_base_url} author_id={author.authorId} confirmCautionId={author.confirmCautionId} confirmComment={author.confirmComment} />
                    </div>
                  </td>
                  <td className={`${styles.type} ${authorTypeClass}`}>{DISPLAY_AUTHOR_TYPE[author.type]}</td>
                  <td>{ author.contract }</td>
                  <td className={styles.affiliations} style={{width:65}}>{ author.affiliations }</td>
                  <td className={styles.specialMention}>{ author.specialMention ? DISPLAY_NOTE[author.specialMention] : null }</td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    </>
  )
}
import { Container, Row, Col, Form, Button, Card, Table } from 'react-bootstrap'
import { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'

import React from 'react';

import styles from './PSPublishRightsEditComponent.module.scss'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(far, fas, fab)

import PSAPIClient  from '../common/PSAPIClient.js'

export default function PSPublishRightsEditComponent({ scoreId, apiBaseURL, isAdmin=false }) {

  const [score, setScore] = useState({
    has_rights: false,
    rights_enable_publish: false,
    rights_enable_arrange: false,
    rights_group: '0',
    rights_management_number: '',
    rights_ivt: '',
    rights_translation: '0',
    rights_foreign: false,
    rights_licensed_text: ''
  });
  const [rightsChecked, setRightsChecked] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        let url = `${apiBaseURL}/accounts/score/${scoreId}`
        console.log("url=" + url)

        const res = await PSAPIClient.get({url})
        //console.info(res)
        const score = res.body
        console.info(score)
        
        setScore({
          has_rights: score.has_rights || false,
          rights_enable_publish: score.rights_enable_publish || false,
          rights_enable_arrange: score.rights_enable_arrange || false,
          rights_group: score.rights_group || '0',
          rights_management_number: score.rights_management_number || '',
          rights_ivt: score.rights_ivt || '',
          rights_translation: score.rights_translation || '0',
          rights_foreign: score.rights_foreign || false,
          rights_licensed_text: score.rights_licensed_text || ''
        })
        setRightsChecked(score.rights_enable_publish && score.rights_enable_arrange)
      } catch (e) {
        console.error(e)
        console.info(e.response.data)
        throw e
      }
    })()
  }, [])

  /*
              <script>
                var emptyPdfHtmlSource = "<div style=\"margin:0px 30px;\"></div><div style=\"color:#0083EA; padding:40px 20px;\"><div><span style=\"padding: 5px; border: solid 1px #0083EA; font-size: 30px; letter-spacing: 1px;\">PDF</span></div><br /><div>許諾書PDFを<br />選択<span style=\"font-size:10px;\"><br /><br />または<br /><br /></span>ドロップ</div></div>";

                  function checkForeignNotice(r) {
      if (r) {
                    //$('#foreign-notice').html("外国曲の販売には、サブ出版者（SP）からの許諾が必要です。申請前に<a style=\"color:#0083EA\" href=\"mailto:support+publishrights@piascore.com\">サポート</a>に、許諾書のコピーをお送りください。また、許諾書記載の指示に従って、必要ならば楽譜ファイルへのコピーライト表記もお願いいたします。");
                    $('#foreign-notice').html("外国曲の販売には、サブ出版者の許諾が必要です。下記の「許諾書のアップロード」より、許諾書のコピー（PDF）をアップロードしてください。必要ならば楽譜ファイルへのコピーライト表記も行ってください。");
        //$('#foreign-upload-license').css('display', 'table-row');
      } else {
                    $('#foreign-notice').html("");
        //$('#foreign-upload-license').css('display', 'none');
      }
    }

                  function deleteLicense(index) {
                    let res = confirm('選択した許諾書PDFを削除しますか？');
                  if(res) {
                    deleteImage(index);
                  $("#license_upload_files_" + index)[0].value = "";
                  $("#upload-filename-" + index).html("");
                  $("#delete-button-" + index).css("visibility", "hidden");
                  $("#download-license-pdf-" + index).css("visibility", "hidden");
                  $("#btn-file-license-" + index).removeClass("border-license");
                  $("#btn-file-license-" + index).addClass("border-empty-license");
      }
    }
                  function deleteImage(index) {
                    let imgElem = $("#license-image-" + index)[0];
                  if (imgElem) {
                    imgElem.parentNode.removeChild(imgElem);
                  setDeleteFlag(index);
      }
                  $("#empty-pdf-" + index).html(emptyPdfHtmlSource);
    }

                  function setDeleteFlag(index) {
                    $("#delete_license_file_flg_" + index)[0].value = true;
    }

                  function isPdfFile(file) {
      // PSM-370 拡張子による判断であることに注意
      return (file.type === "application/pdf");
    }

                  // for showing upload file name
                  $(document).on('change', '.btn-file-license :file', function() {
      var input = $(this),
                  numFiles = input.get(0).files ? input.get(0).files.length : 1,
                  label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
                  input.trigger('fileselect', [numFiles, label]);
    });

    // function check_foreign_notice(r) {
                    //   if (r) {
                    //     $('#foreign-notice').html("外国曲の販売には、サブ出版者（SP）からの許諾が必要です。申請前に<a style=\"color:#0083EA\" href=\"mailto:support+publishrights@piascore.com\">サポート</a>に、許諾書のコピーをお送りください。また、許諾書記載の指示に従って、必要ならば楽譜ファイルへのコピーライト表記もお願いいたします。");
                    //   } else {
                    //     $('#foreign-notice').html("");
                    //   }
                    // }

                    $(document).ready(function () {
                      $('.btn-file-license :file').on('fileselect', function (event, numFiles, label) {
                        let file = event.target.files[0];
                        let focusIndex = $(this).attr("id").match(/\d/g).join("");
                        let fileName = numFiles > 1 ? numFiles + ' files selected' : label;
                        if (isPdfFile(file)) {
                          $("#upload-filename-" + focusIndex)[0].innerHTML = fileName;
                          deleteImage(focusIndex);
                          $("#delete-button-" + focusIndex).css("visibility", "visible");
                          $("#empty-pdf-" + focusIndex).html("");
                          $("#btn-file-license-" + focusIndex).removeClass("border-empty-license");
                          $("#btn-file-license-" + focusIndex).addClass("border-license");
                        } else {
                          console.log("It is not PDF file!");
                          $("#license_upload_files_" + focusIndex)[0].value = "";
                          window.alert("PDFではありません");
                          return false;
                        }
                      });

                      let r = $('.is-foreign-work').prop('checked');
                      checkForeignNotice(r);
                      $('[data-toggle="tooltip"]').tooltip()
                      $('.is-foreign-work').change(function () {
                        r = $(this).prop('checked');
                        checkForeignNotice(r);
                      })

                      $(".empty-pdf").html(emptyPdfHtmlSource);

                      $('.btn-file-license :file').on('dragover', function () {
                        let focusIndex = $(this).attr("id").match(/\d/g).join("");
                        $("#btn-file-license-" + focusIndex).addClass("on-drag");
                      });
                      $('.btn-file-license :file').on('dragleave', function () {
                        let focusIndex = $(this).attr("id").match(/\d/g).join("");
                        $("#btn-file-license-" + focusIndex).removeClass("on-drag");
                      });
                      $('.btn-file-license :file').on('drop', function () {
                        let focusIndex = $(this).attr("id").match(/\d/g).join("");
                        $("#btn-file-license-" + focusIndex).removeClass("on-drag");
                      });

                    });</script>

  */

/*
      <style type="text/css">
      .jump_rights_company{padding:0 0 0 5px;color:#428bca}a:visited.jump_rights_company{padding:0 0 0 5px;color:#428bca}a:hover.jump_rights_company{text - decoration:underline}tr.top-border{border - top:1px solid #E1E1E1}tr.top-border td{padding - top:30px}.btn-file-license{display:inline-block;overflow:hidden;text-align:center;width:180px;position:relative;background-color:#fff;height:193px;width:140px;margin:0}.border-empty-license{border:2px dashed #ddd}.border-license{border:2px solid #ddd}.btn-file-license input[type="file"]{opacity:0;filter:alpha(opacity=0);position:absolute;right:0;top:0;margin:0;cursor:pointer}.delete-button-pos{position:absolute;top:-5px;right:35px;z-index:1}a.delete-button{background:#333333;color:#FFFFFF;border-radius:8px;margin:0;padding:0 3px 1px 3px}a.delete-button:hover{text - decoration:none}.filename-box{display:table-cell;vertical-align:middle;height:193px}.filename{text - align:center;padding:0 10px;width:140px;word-wrap:break-word}.on-drag{background - color:#ddd}</style>
                <style type="text/css">label{padding:10px 20px;border-radius:20px}
                label:hover{background:#b6dfff}
                </style>

  */                   
                    
  const handleHasRights = (value) => {
    //console.log(value)
    setScore({...score, has_rights: value })
  };
  
  const changeRightsChecked = (value) => {
    //console.log(value)
    setScore({...score, rights_enable_publish: value, rights_enable_arrange: value})
    setRightsChecked(value)
  };
  
  const validateRightsManagementNumber = (value) => {
    setScore({...score, rights_management_number: value})
  }
  
  const onFileInputChange = (e, index) => {
    console.log(e.target.files);
  }
  
  return (
    <div className={ styles.round_content }>
    
      <table style={{margin:"0 auto"}}>
        <tbody>
          <tr>
            <td colSpan="2" style={{textAlign:"center"}}>
              <span style={{fontSize:"11px", color:"#C0392B"}}>詳しくはこちらをご参照下さい</span>
              <a className={ styles.confirm_button } target="_blank" style={{margin:"0 0 0 5px", fontSize:"12px", color:"#FFFFFF", textDecoration:"none", background:"#C0392B", padding:"7px 15px", borderRadius:"16px"}} href="/rights">販売登録できる楽曲について
                <i style={{margin:"0 auto auto 2px", fontSize:"15px"}} className="fa fa-arrow-right"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    
      <table style={{margin:"0 auto 0"}}>
        <tbody>
          <tr>
            <td className={ styles.item_column }>著作権管理の有無
              <span aria-hidden="true" className={ styles.help } data-toggle="tooltip" title="音楽著作権団体が管理している場合は、チェックONしてください。">ヘルプ</span>
            </td>
            <td className={ styles.require_column }></td>

            <td>
              <label style={{padding:"12px 15px", borderRadius:"30px", margin:"0 0 0 6px"}} htmlFor="score_has_rights">
                <input type="checkbox" checked={score.has_rights} onChange={e => handleHasRights(e.target.checked)}  />
              </label>
            </td>
          </tr>
        </tbody>
      </table>

      <div style={{display:(score.has_rights ? "block" : "none")}}>
        <table style={{margin:"0 auto 0"}}>
          <tbody>
            <tr className={ styles.has_rights } style={{display:"table-row"}}>
              <td className={ styles.item_column } style={{padding:"0 0 30px 0"}}>
                <span style={{fontWeight:"bold"}}></span>
                <a target="_blank" style={{color:"#0083EA", textDecoration:"underline"}} href="/rights/intro#enable_publish">販売登録できること</a>を確認しましたか？
                <span aria-hidden="true" className={ styles.help } data-toggle="tooltip" title="楽曲によっては、出版社から許諾を取得する必要があります">ヘルプ</span>
              </td>
              <td className={ styles.require_column } style={{padding:"0 0 30px 0"}}>
                <span className={ styles.required }>必須</span>
              </td>
              <td style={{padding:"0 0 30px 0"}}>
                <div style={{float:"left"}}>
                  <label className={ styles.radio_label } style={{margin:"0px"}} htmlFor="score_rights_confirm_both_1">
                    <input className={ styles.score_radiobox } data-type="all" type="radio" value="1" checked={rightsChecked == 1} onChange={e => changeRightsChecked(1)} name="score[rights_confirm_both]" id="score_rights_confirm_both_1" />
                    <span style={{padding:"0 0 0 5px"}}>はい</span>
                  </label>
                  <label className={ styles.radio_label } style={{margin:"0px"}} htmlFor="score_rights_confirm_both_0">
                    <input className={ styles.score_radiobox } data-type="all" type="radio" value="0" checked={rightsChecked == 0} onChange={e => changeRightsChecked(0)}  name="score[rights_confirm_both]" id="score_rights_confirm_both_0" />
                    <span style={{padding:"0 0 0 5px"}}>いいえ</span>
                  </label>
                </div>
              </td>
            </tr>
      
            <tr className="has-rights top-border" style={{display:"table-row"}}>
              <td className={ styles.item_column }>著作権管理団体名
                <span aria-hidden="true" className={ styles.help } data-toggle="tooltip" title="JASRAC か NexTone を選択してください。">ヘルプ</span>
              </td>
              <td className={ styles.require_column }>
                <span className={ styles.required }>必須</span>
              </td>
              <td>
                <select style={{width:"200px", height:"40px"}} className="form-control" name="score[rights_group]" id="score_rights_group" value={score.rights_group} onChange={e => setScore({...score, rights_group: e.target.value})}>
                  <option value="0">なし</option>
                  <option value="1">JASRAC</option>
                  <option value="3">NexTone</option>
                </select>
              </td>
            </tr>
      
            <tr className={ styles.has_rights } style={{display:"table-row"}}>
              <td className={ styles.item_column }>作品コード
                <span aria-hidden="true" className={ styles.help } data-toggle="tooltip" title="各著作権管理団体でつけている作品コードです。">ヘルプ</span>
              </td>
              <td className={ styles.require_column }>
                <span className={ styles.required }>必須</span>
              </td>
              <td>
                <div style={{float:"left"}}>
                  <input style={{width:"200px"}} className="form-control" type="text" value={score.rights_management_number} onChange={e => validateRightsManagementNumber(e.target.value)} name="score[rights_management_number]" id="score_rights_management_number" />
                </div>
                <div style={{float:"left", padding:"10px 0 0 20px"}}>
                  <span style={{fontSize:"10px", fontWeight:"bold"}}>作品コードの検索：</span>
                  <a className={ styles.jump_rights_company } href="http://www2.jasrac.or.jp/eJwid/" target="_blank">JASRAC</a> / 
                  <a className={ styles.jump_rights_company } href="https://search.nex-tone.co.jp/" target="_blank">NexTone</a>
                </div>
              </td>
            </tr>
      
            <tr className={ styles.has_rights } style={{display:"table-row"}}>
              <td className={ styles.item_column }>IVT区分
                <span aria-hidden="true" className={ styles.help } data-toggle="tooltip" title="IVT区分です。">ヘルプ</span>
              </td>
              <td className={ styles.require_column }>
                <span className={ styles.required }>必須</span>
              </td>
              <td>
                <select style={{width:"200px", height:"40px"}} className="form-control" name="score[rights_ivt]" id="score_rights_ivt" value={score.rights_ivt} onChange={e => setScore({...score, rights_ivt: e.target.value})}>
                  <option value="">なし</option>
                  <option value="I">曲のみ利用</option>
                  <option value="V">詞・曲ともに利用</option>
                  <option value="T">詞のみ利用</option>
                </select>
              </td>
            </tr>
      
            <tr className={ styles.has_rights } style={{display:"table-row"}}>
              <td className={ styles.item_column }>原詞訳区分
                <span aria-hidden="true" className={ styles.help } data-toggle="tooltip" title="原訳詞区分です。">ヘルプ</span>
              </td>
              <td className={ styles.require_column }>
                <span className={ styles.required }>必須</span>
              </td>
              <td>
                <select style={{width:"200px", height:"40px"}} className="form-control" name="score[rights_translation]" id="score_rights_translation" value={score.rights_translation} onChange={e => setScore({...score, rights_translation: e.target.value})}>
                  <option value="0">なし</option>
                  <option value="1">原詞</option>
                  <option value="2">訳詞</option>
                  <option value="3">不明</option>
                </select>
              </td>
            </tr>
      
            <tr className={ styles.has_rights } style={{height:"75px", display:"table-row"}}>
              <td className={ styles.item_column }>外国作品
                <span aria-hidden="true" className={ styles.help } data-toggle="tooltip" title="外国作品の場合、チェックを入れてください。">ヘルプ</span>
              </td>
              <td className={ styles.require_column }></td>
              <td>
                <div style={{float:"left", margin:"-5px 0 0 -12px", height:"32px", width:"45px", verticalAlign:"middle"}}>
                  <label style={{padding:"12px 15px", borderRadius:"30px", margin:"0"}} htmlFor="score_rights_foreign">
                    <input name="score[rights_foreign]" type="hidden" value="0" checked={score.rights_foreign} onChange={e => setScore({...score, rights_foreign:e.target.checked})}  />
                    <input style={{}} className={ styles.is_foreign_work } type="checkbox" value="1" name="score[rights_foreign]" id="score_rights_foreign" />
                  </label>
                </div>
                <div style={{float:"left", width:"482px", padding:"0", height:"32px"}}>
                  <span id="foreign-notice" style={{fontSize:"10px", fontWeight:"bold", color:"#C0392B"}}></span>
                </div>
              </td>
            </tr>
      
            <tr id="foreign-upload-license">
              <td className={ styles.item_column }>
                <div>販売許諾書のアップロード
                  <span aria-hidden="true" className={ styles.help } data-toggle="tooltip" title="音楽出版社や著作権者から楽譜販売に関する許諾を得ている場合は、それを証する書類（PDF形式）、3ファイルまでアップロードすることができます。メールなどテキスト形式の場合は、PDFに変換してアップロードしてください。">ヘルプ</span>
                </div>
                <div style={{margin:"5px 0 0 0", fontSize:"10px", fontWeight:"bold", color:"#ff3333"}}>楽譜ファイルをアップロードしないでください</div>
              </td>
              <td className={ styles.value_column } colSpan="2">
                
                <div style={{float:"left", position:"relative"}}>
                  <div style={{margin:"0 40px 0 0"}}>
                    <span className={ styles.delete_button_pos }>
                      <a className={ styles.delete_button } style={{visibility:"hidden"}} id="delete-button-0" onClick={e => {deleteLicense(0); return false;}} href="#">×</a>
                    </span>
                    <div className={ `${styles.btn_file_license} ${styles.border_empty_license}`} id="btn-file-license-0">
                      <input type="file" name="license[upload_files][0]" id="license_upload_files_0" accept="application/pdf" style={{height:"193px", width:"140px"}}  onChange={e => onFileInputChange(e, 0)} />
                      <div className={ styles.empty_pdf } id="empty-pdf-0">
                        <div style={{margin:"0px 30px"}}></div>
                        <div style={{color:"#0083EA", padding:"40px 20px"}}>
                          <div>
                            <span style={{padding:"5px", border:"solid 1px #0083EA", fontSize:"30px", letterSpacing:"1px"}}>PDF</span>
                          </div>
                          <br />
                          <div>許諾書PDFを
                            <br />選択
                            <span style={{fontSize:"10px"}}>
                              <br />
                              <br />または
                              <br />
                              <br />
                            </span>ドロップ
                          </div>
                        </div>
                      </div>
                      <div className="filename-box">
                        <div className="filename" id="upload-filename-0"></div>
                      </div>
                    </div>
                  </div>
                </div>

                {
                  /*
                <div style={{float:"left", position:"relative"}}>
                  <div style={{margin:"0 40px 0 0"}}>
                    <span className={ styles.delete_button_pos }>
                      <a className={ styles.delete_button } style={{visibility:"hidden"}} id="delete-button-1" onClick={e => {deleteLicense(1); return false;}} href="#">×</a>
                    </span>
                    <div className={ `${styles.btn_file_license} ${styles.border_empty_license}`} id="btn-file-license-1">
                      <input type="file" name="license[upload_files][1]" id="license_upload_files_1" accept="application/pdf" style={{height:"193px", width:"140px"}} />
                      <div className={ styles.empty_pdf } id="empty-pdf-1">
                        <div style={{margin:"0px 30px"}}></div>
                        <div style={{color:"#0083EA", padding:"40px 20px"}}>
                          <div>
                            <span style={{padding:"5px", border:"solid 1px #0083EA", fontSize:"30px", letterSpacing:"1px"}}>PDF</span>
                          </div>
                          <br />
                          <div>許諾書PDFを
                            <br />選択
                            <span style={{fontSize:"10px"}}>
                              <br />
                              <br />または
                              <br />
                              <br />
                            </span>ドロップ
                          </div>
                        </div>
                      </div>
                      <div className="filename-box">
                        <div className="filename" id="upload-filename-1"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{float:"left", position:"relative"}}>
                  <div style={{margin:"0 40px 0 0"}}>
                    <span className={ styles.delete_button_pos }>
                      <a className={ styles.delete_button } style={{visibility:"hidden"}} id="delete-button-2" onClick={e => {deleteLicense(2); return false;}} href="#">×</a>
                    </span>
                    <div className={ `${styles.btn_file_license} ${styles.border_empty_license}`} id="btn-file-license-2">
                      <input type="file" name="license[upload_files][2]" id="license_upload_files_2" accept="application/pdf" style={{height:"193px", width:"140px"}} />
                      <div className={ styles.empty_pdf } id="empty-pdf-2">
                        <div style={{margin:"0px 30px"}}></div>
                        <div style={{color:"#0083EA", padding:"40px 20px"}}>
                          <div>
                            <span style={{padding:"5px", border:"solid 1px #0083EA", fontSize:"30px", letterSpacing:"1px"}}>PDF</span>
                          </div>
                          <br />
                          <div>許諾書PDFを
                            <br />選択
                            <span style={{fontSize:"10px"}}>
                              <br />
                              <br />または
                              <br />
                              <br />
                            </span>ドロップ
                          </div>
                        </div>
                      </div>
                      <div className="filename-box">
                        <div className="filename" id="upload-filename-2"></div>
                      </div>
                    </div>
                  </div>
                </div>
                */ 
                }
              </td>
            </tr>
            
            <tr className={ styles.has_rights } style={{display:(isAdmin ? "table-row" : "none")}}>
              <td className={ styles.item_column }>コピーライト表記</td>
              <td className={ styles.require_column }></td>
              <td className={ styles.value_column }>
                <textarea placeholder={`（例）\nSTAR MASK by George Air\n© 1990 by Seven Music Publishing/ U. S. A\nPermission granted by Piascore Music Publishing Co., Ltd.\nAuthorized for sale in Japan only.`} style={{width:"100%", height:"140px"}} className="form-control" name="score[rights_licensed_text]" id="score_rights_licensed_text" value={ score.rights_licensed_text } onChange={e => setScore({...score, rights_licensed_text:e.target.value})}></textarea>
              </td>
            </tr>
            
          </tbody>
        </table>
      </div>
      
      <table style={{margin:"0 auto"}}>
        <tbody>
          <tr>
            <td colSpan="2" style={{textAlign:"center"}}>
              <button type="button" className="btn btn-default submit_button" onClick={e => {location.href='/scores/273494'}}>キャンセル</button>
              <input type="submit" name="commit" value="更新する" className="submit_button btn btn-primary" style={{width:"250px"}} />
            </td>
          </tr>
        </tbody>
      </table>
    
    </div>
  )      
}

// import React from "react"
// import PropTypes from "prop-types"

import React from 'react';
// import axios from 'axios';

import './PSPublishContactComponent.scss'; 

// import { FontAwesomeIcon } from "https://cdn.skypack.dev/@fortawesome/react-fontawesome"
// import { faAngleRight, faInfoCircle } from "https://cdn.skypack.dev/@fortawesome/free-solid-svg-icons@5.15.3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

// css https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css
// js https://cdnjs.cloudflare.com/ajax/libs/react-bootstrap/2.1.2/react-bootstrap.min.js 
//const { Button, Modal, Form, Container, Row, Col, Alert }  = ReactBootstrap;
import { Button, Modal, Form, Container, Row, Col, Alert } from 'react-bootstrap';


const FormHeader = props => {
  return <div className="contact_header">お問合せフォーム</div>
}

const FormCaution = props => {
  return (
    <div className="contact_caution">
      ご連絡後、3日以内にご返信差し上げます。
      <br />
      <br />
      返信が来ない場合、<span style={{color:"#f3012d"}}>販売者様のメールにフィルターなどが設定されている可能性がございます</span>。piascore.com ドメインからのメールが受信できるようにフィルターが設定されていることを確認いただき、ゴミ箱などに返信が届いていないかご確認ください。
    </div>
  )
}

class FormBody extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {      
      html_msg:"",
      message:"",
      showModal:false,
      caution_html:<></>
    };
  }
  
  render() {

    let name = this.props.storeName + " " + this.props.chargeName

    //console.log(this.props.opinionType)
    
    return (
      <>
        <Form className="contact_body" style={{fontSize:16}} action="/send_opinion" acceptCharset="UTF-8" method="post">

          <Row className="align-items-center">
            <Form.Label column="sm" lg={3}>お名前</Form.Label>
            <Col><Form.Control name="name" id="name" defaultValue={name} size type="text"  placeholder="ピアスコア太郎" required /></Col>
          </Row>

          <Row className="align-items-center">
            <Form.Label column="sm" lg={3}>メールアドレス</Form.Label>
            <Col><Form.Control name="mail_address" id="mail_address" defaultValue={this.props.mailAddress} size  type="email" placeholder="sample@piascore.com" required /></Col>
          </Row>

          <Row className="align-items-center">
            <Form.Label column="sm" lg={3}>お問い合わせ種別</Form.Label>
            <Col>
              <Form.Select name="opinion_type" id="opinion_type" defaultValue={this.props.opinionType || "improve_service"} aria-label="" onChange={e => this.showOpinionMessage(e.target.value) }  required>
                <option value="improve_service">サービスへのご意見・ご要望</option>
                {/* <option value="company_sale">法人販売のご依頼</option> */}
                <option value="claim_rights">権利侵害曲の申し立て</option>
                
                { this.props.isLogined && <option value="substitution_post">代理投稿の依頼</option> }
                { this.props.isLogined && <option value="unsubscribe">退会申請</option> }

                <option value="other">その他</option>

              </Form.Select>
              { (this.state.html_msg != null && this.state.html_msg != "") ?
                <div className="alert alert-warning" style={{"margin":"10px 0"}}>
                  <span id="msg" style={{fontSize:12}}>{this.state.html_msg}</span>
                </div>
                : ""
              }
            </Col>
          </Row>

          <Row>
            <Form.Label column="sm" lg={3}>内容</Form.Label>
            <Col>
              {/* <Form.Control className="rounded-4" name="message" id="message" as="textarea" rows={5} value={this.state.message} placeholder="内容をお書き下さい" onChange={this.handleChange} onBlur={(e) => {this.state.message=e.target.value}} required /> */}
              <Form.Control name="message" id="message" as="textarea" rows={13} value={this.state.message} placeholder="内容をお書き下さい" onChange={this.handleChange} onBlur={(e) => this.state.message=e.target.value} required />

              {this.state.caution_html}
            </Col>
          </Row>
          
          
          <Row className="text-center button">
            <Col>
              { /* PSM-502 For CSRF*/ }
              <Form.Control name="authenticity_token" value={this.props.formAuthenticityToken} type="hidden" />

              <Button type="submit" style={{"height":"50px", "width":"300px"}} /*onClick={(e) => this.checkMessage(this.state.message)}*/ >送信する
              </Button>
            </Col>
          </Row>

        </Form>
      </>
    )
  }

  handleChange = (e) => {
    
    const caution_html = this.checkMessage(event.target.value)
    
    this.setState({
      caution_html:caution_html,
      message: event.target.value
    });
  }

  hankana2Zenkana(str) {
    var kanaMap = {
        'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
        'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
        'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
        'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
        'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
        'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
        'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
        'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
        'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
        'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
        'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
        'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
        'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
        'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
        'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
        'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
        'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
        'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
        '｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・'
    };

    var reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
    return str
            .replace(reg, function (match) {
                return kanaMap[match];
            })
            .replace(/ﾞ/g, '゛')
            .replace(/ﾟ/g, '゜');
  };

  checkMessage = (msg) => {
    
    if (!msg) { return } 

    //console.log("checkMessage:" + msg)
    
    msg = msg.toLowerCase()
    msg = this.hankana2Zenkana(msg);

    let DEBUG = false;
    //let DEBUG = true;

    let cautions = [];

    //// ここから ///////////////////////////////////////////////////////////////////////////
                
    ///////////////////////////////////////////////////////////////////////////////////////
    // 代理投稿

    // 代理申請 ボタンあり
    if ( DEBUG || (msg.match(/代理申請/))) {
      cautions.push({
        title:"許諾の代理申請について",
        msg:<>一部の出版社様からの楽曲利用許諾について、各販売者様に代わり、取得手続きをいたします。<br /><br />詳しくは下記サイトご参考ください。</>,
        btn_text:"許諾の代理申請について",
        btn_url:"https://publish.piascore.com/accounts/license_agency"
      })
    }
    
    // 代理投稿 ボタンなし
    if ( DEBUG || (msg.match(/代理投稿/))) {
      cautions.push({
        title:"代理投稿について",
        msg:<>「代理投稿」とは数十曲以上の楽譜投稿をされる場合に、所定のエクセルファイルとPDFファイルを頂き、こちらで登録作業を行わせていただくものです。<br />基本的には、下記のファイルをご準備頂くことになります。<br /><br />・ タイトルや価格などが表記された Excel ファイル<br />
・ 楽譜、許諾書のPDFファイル<br /><br />ご希望の場合は、こちらのフォームよりお問い合わせください。</>,
        btn_text:null,
        btn_url:null
      })
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // 承認

    if ( DEBUG || (msg.match(/承認/) && msg.match(/状況|いつ/))) {
      cautions.push({
        title:"承認にかかる日数について",
        msg:<>楽曲によっては、代理の許諾申請が行われている場合がございます。許諾の取得・承認にかかる日数についてはお答えできません。</>,
        btn_text:null,
        btn_url:null
      })
    }

    if ( DEBUG || (msg.match(/代理/) && msg.match(/状況|いつ/))) {
      cautions.push({
        title:"代理申請後、許諾取得までの期間について",
        msg:<>許諾の取得までにかかる日数についてはお答えできません。<br />お急ぎの場合は、販売者様ご自身での取得をお願いいたします。</>,
        btn_text:null,
        btn_url:null
      })
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // 許諾取得

    // 
    if ( DEBUG || (msg.match(/出版社/) && msg.match(/問い合わせ|窓口/))) {
      cautions.push({
        title:"出版社様の連絡先について",
        msg:<>各出版社様の連絡先につきましては、下記よりJASRAC様にお問い合わせいただけますようお願いいたします。</>,
        btn_text:"JASRAC 歌詞・楽譜の利用（商用配信）のお問い合わせ",
        btn_url:"https://www.jasrac.or.jp/info/network/business/score.html"
      })
    }

    // 編曲
    if ( DEBUG || (msg.match(/編曲|アレンジ/) && msg.match(/定義|許諾/))) {
      cautions.push({
        title:"編曲について",
        msg:<>
        原曲に何かしらの手を加える場合、編曲である可能性がございます。原曲を編曲する場合は、著作者より許諾を取っていただくのが宜しいかと存じます。詳しくは、お手数ですが著作者（主にその楽曲を管理する出版社）にお問い合わせください。<br /><br />
（参考）<br />
<a target="_blank" href="https://www.jasrac.or.jp/copyright/use/">「音楽著作権とは」の「編曲・替歌をするときの注意点」</a><br /><br />
なお、JASRACでは編曲権・翻案権の譲渡を受けていないため、編曲することなどについて許諾することはできません。</>,
        btn_text:"「音楽著作権とは」の「編曲・替歌をするときの注意点」",
        btn_url:"https://www.jasrac.or.jp/copyright/use/"
      })
    }
    
    ///////////////////////////////////////////////////////////////////////////////////////
    // アカウント

    if ( DEBUG || (msg.match(/ストア名|担当者名|名前|販売者名|メールアドレス/) && msg.match(/変更/))) {
      cautions.push({
        title:"ストア名、ご担当者様名の変更について",
        msg:<>弊社にて変更手続きをとらせていただきます。<br />新しいストア名・ご担当者様名をフォームよりお送りいただけますようお願いいたします。</>,
        btn_text:null,
        btn_url:null
      })
    }

    // ログイン ボタンあり
    if ( DEBUG || (msg.match(/ログイン/))) {
      cautions.push({
        title:"ログインができない場合",
        msg:<>販売者様用のログインページよりログインしていますか<br /><br />詳しくは下記サイトご参考ください。</>,
        btn_text:"ログインができなくなりました",
        btn_url:"https://help.piascore.com/hc/ja/articles/360057114271-%E3%83%AD%E3%82%B0%E3%82%A4%E3%83%B3%E3%81%8C%E3%81%A7%E3%81%8D%E3%81%AA%E3%81%8F%E3%81%AA%E3%82%8A%E3%81%BE%E3%81%97%E3%81%9F"
      })
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // 投稿
    
    // パート譜 ボタンあり
    if ( DEBUG || (msg.match(/パート譜/))) {
      cautions.push({
        title:"アンサンブルや吹奏楽など総譜とパート譜の販売はできますか？",
        msg:<>詳しくは下記サイトご参考ください。</>,
        btn_text:"アンサンブルや吹奏楽など総譜とパート譜の販売はできますか？",
        btn_url:"https://help.piascore.com/hc/ja/articles/360056660812-%E3%82%A2%E3%83%B3%E3%82%B5%E3%83%B3%E3%83%96%E3%83%AB%E3%82%84%E5%90%B9%E5%A5%8F%E6%A5%BD%E3%81%AA%E3%81%A9%E7%B7%8F%E8%AD%9C%E3%81%A8%E3%83%91%E3%83%BC%E3%83%88%E8%AD%9C%E3%81%AE%E8%B2%A9%E5%A3%B2%E3%81%AF%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%81%8B-"
      })
    }
    
    // 表紙 ボタンなし
    if ( DEBUG || (msg.match(/表紙/))) {
      cautions.push({
        title:"楽譜表紙の変更をしましたが、変わりません。",
        msg:<>キャッシュが残っているため、変更までにお時間を要する場合がございます。<br />
   数日経っても、変更されない場合はこちらのフォームよりご連絡いただけますようお願いいたします。</>,
        btn_text:null,
        btn_url:null
      })
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // ストア

    // キャッシュ
    if ( DEBUG || (msg.match(/表記|表紙|検索結果/) && msg.match(/まま|変わらない/))) {
      cautions.push({
        title:"楽譜情報や楽譜表紙の変更をしたが、ストア上で変わりません",
        msg:<>キャッシュが残っているため、しばらくは元の情報が表示されております。<br /><br />しばらく(24時間以上）経っても変更されない場合、お手数ですがスクリーンショットをお取りいただき、ご連絡いただけますでしょうか。</>,
        btn_text:null,
        btn_url:null
      })
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // 振込

    // キャッシュ
    if ( DEBUG || (msg.match(/振込/))) {
      cautions.push({
        title:"お振込がされていません",
        msg:<>
    振込日につきまして、<a target="_blank" href="/term">ご利用規約「第１０条 （振込申請）」</a>で定めていますように、当該振込申請のあった月の翌月末日までにお振込みいたします。<br /><br />
    例）<br />
    2024年4月10日にお振込申請いただいた場合は、2024年5月31日にまでにお振込みいたします。
        </>,
        btn_text:null,
        btn_url:null
      })
    }


    ///////////////////////////////////////////////////////////////////////////////////////
    // 支払調書・源泉徴収、請求書

    // 支払調書
    if ( DEBUG || (msg.match(/支払調書|支払い調書/))) {
      cautions.push({
        title:"支払調書がほしい",
        msg:<>申し訳ございませんが、一部の販売者様を除き、支払調書の発行を見送らせていただいております。<br />（支払調書は、弊社から販売者様に発行する義務のない法定調書となっております）<br /><br />源泉徴収税額など同様の内容につきましては、<a target="_blank" href="/payments?type=payment">収支レポート</a>よりご参照いただけます。確定申告には、こちらに掲載の額をご利用ください。</>,
        btn_text:"収支レポート（振込のみ）",
        btn_url:"/payments?type=payment"
      })
    }
    
    // 源泉徴収票
    if ( DEBUG || (msg.match(/源泉徴収票/))) {
      cautions.push({
        title:"源泉徴収票がほしい",
        msg:<>源泉徴収票は、事業者が雇用契約を結んだ従業員に対して発行する法定調書となります。弊社が発行できるのは「支払調書」となります。<br /><br />
        また支払調書につきましては、申し訳ございませんが一部の販売者様を除き、支払調書の発行を見送らせていただいております。<br />（支払調書は、弊社から販売者様に発行する義務のない法定調書となっております）<br /><br />源泉徴収税額など同様の内容につきましては、<a target="_blank" href="/payments?type=payment">収支レポート</a>よりご参照いただけます。確定申告には、こちらに掲載の額をご利用ください。</>,
        btn_text:"収支レポート（振込のみ）",
        btn_url:"/payments?type=payment"
      })
    }

    // 源泉徴収
    if ( DEBUG || (msg.match(/源泉徴収/))) {
      cautions.push({
        title:"源泉徴収税額が知りたい",
        msg:<>源泉徴収税額など確定申告に必要な情報につきましては、<a target="_blank" href="/payments?type=payment">収支レポート</a>よりご参照いただけます。<br /></>,
        btn_text:"収支レポート（振込のみ）",
        btn_url:"/payments?type=payment"
      })
    }

    // 請求書
    if ( DEBUG || (msg.match(/請求書|インボイス/))) {
      cautions.push({
        title:"2023年9月分までの請求書（インボイス）をダウンロードしたい",
        msg:<>申し訳ございませんが、請求書の発行機能は、2023年10月分以降のお振込ついてのみのご提供となります。確定申告などに必要な額は、<a target="_blank" href="/payments?type=payment">収支レポート</a>よりご参照いただけます。</>,
        btn_text:"収支レポート（振込のみ）",
        btn_url:"/payments?type=payment"
      })
    }

    // 法人番号
    if ( DEBUG || (msg.match(/法人/) && (msg.match(/番号/))) ) {
      cautions.push({
        title:"Piascoreの法人番号を知りたい",
        msg:<><a target="_blank" href="https://piascore.com/ja/about/info/">会社概要</a>の法人番号欄をご参照ください。</>,
        btn_text:"会社概要",
        btn_url:"https://piascore.com/ja/about/info/"
      })
    }
    
    //// ここまで ///////////////////////////////////////////////////////////////////////////


    return (
      <>
        {cautions.map((c, index) => (
          <Alert className="message-alert" key={index} variant="warning">
            <Alert.Heading>
              <span style={{"padding":"0 3px 0 0"}}>
                <FontAwesomeIcon style={{ padding: 0, fontSize: 16 }} icon={faInfoCircle} />
              </span>
              {c.title}
            </Alert.Heading>
            <p>{c.msg}</p>
            { c.btn_text!=null &&
              <>
                <hr />
                <div className="d-flex justify-content-end">
                  <a target="_blank" href={c.btn_url}>
                    <Button size="sm" variant="warning">
                      {c.btn_text} 
                      <span style={{"padding":"0 0 0 3px"}}>
                        <FontAwesomeIcon style={{ padding: 0, fontSize: 15 }} icon={faAngleRight} />
                      </span>
                    </Button>
                  </a>
                </div>
              </>
            }
          </Alert>
        ))}
      </>
    );
  }

  showOpinionMessage = (opinionType) => {
    //const opinionType     = e.target.value; //document.getElementById("opinion_type").value;
    let jsx_msg = "";
    let message = "";

    switch (opinionType) {
      case "improve_service": // サービスへのご意見・ご要望
        jsx_msg = ""
        break;
      // case "company_sale": // 法人販売
      //   jsx_msg = <>法人様の場合も、アカウントを作成し、ご登録をすすめていただくことになります。<br /><br />
  
      //   下記より、会員登録いただけますと幸いです。<br /><br />
        
      //   <a target="_blank" href="https://publish.piascore.com/sign_up">会員登録 - Piascore 楽譜販売</a><br /><br />
        
        
      //   会員登録後、アカウント情報に法人様として情報を入力いただけますようお願いいたします。<br />
      //   <a target="_blank" href="https://publish.piascore.com/accounts?tax_withholding_modal=1">源泉徴収の情報の申請</a></>
      //   break;
      case  "claim_rights": // 権利侵害局の申し立て
        jsx_msg = ""
        break;             
      case "substitution_post": // 代理投稿の依頼
        jsx_msg = <>
        「<span style={{fontWeight:"bold"}}>代理投稿</span>」とは、楽曲データを記載したエクセルファイルとPDFファイルを頂き、こちらで登録作業を行わせていただくものです。下記をお読みの上、ご希望の方はお問い合わせください。<br /><br />
        <ul style={{lineHeight:"1.8em"}}>
          <li>下記のファイルをご準備頂きます。
            <ol style={{lineHeight:"1.8em"}}>
              <li>タイトルや価格などが表記された Excel ファイル（弊社指定のフォーマットに従っていただきます）</li>
              <li>楽譜のPDFファイル</li>
            </ol>
          </li>
          <li>1回につき、<span style={{fontWeight:"bold"}}>100曲以上</span>からのみ受付いたしましす。</li>
          <li>ご登録と同時に、販売承認審査も行います。</li>
          <li>ご登録内容につきまして保証はいたしません。</li>
        </ul>
        </>
        
        break;             
      case "unsubscribe": //退会申請 
        jsx_msg = <>
        ご退会前に、下記をご確認ください
        <ol style={{margin:"10px 0 0 0", lineHeight:"1.8em"}}>
          <li><a target="_blank" href="/accounts?tax_withholding_modal=1">源泉徴収の情報</a>の入力が完了していることをご確認ください。</li>
          <li><a target="_blank" href="/payments">収支レポートページ</a>より未払収益額計をご確認ください
            <ul style={{lineHeight:"1.8em"}}>
              <li><span style={{fontWeight:"bold"}}>お振込できる場合</span>は、予め振込申請を行ってください。</li>
              <li><span style={{fontWeight:"bold"}}>お振込できない場合</span>は、下記のフォームよりお振込情報をお知らせください。
                <ol style={{listStyleType:"lower-alpha"}}>
                  <li>振込先銀行名</li>
                  <li>支店名</li>
                  <li>口座種別（普通・当座）</li>
                  <li>口座番号</li>
                  <li>口座名義</li>
                </ol>
              </li>
            </ul>
          </li>
        </ol>
        </>
        message = "退会を希望します。\n\n■ 退会理由： \n\n■ お振込先情報\na. 振込先銀行名： \nb. 支店名： \nc. 口座種別（普通・当座）： \nd. 口座番号： \ne. 口座名義（カタカナ）： "
        break;
      case "other": // その他
        jsx_msg = ""
        break;             
    }

    const old = document.getElementById("message").value;
    if(old!=null && old != "") {
      message = old + "\n" + message;
    } 

    // console.log(opinionType)
    // console.log(message)

    this.setState({ html_msg: jsx_msg, message:message });
  }
}

class PSPublishContactComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  render() {

    return (
      <div className="contact_container">
        <FormHeader />
        <FormCaution />
        <FormBody isLogined={this.props.isLogined} storeName={this.props.storeName} chargeName={this.props.chargeName} mailAddress={this.props.mailAddress} formAuthenticityToken={this.props.formAuthenticityToken} opinionType={this.props.opinionType} />
      </div>
    );
    
  }

}

// ========================================

// const isLogined = true;
// //const isLogined = false;

// ReactDOM.render(
//   <PSPublishContactComponent isLogined={isLogined} />,
//   document.getElementById("app")
// );

export default PSPublishContactComponent;

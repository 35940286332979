import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';


const apiConfig = {
  headers: {
    "Content-Type": "application/json",
    'X-Requested-With': 'XMLHttpRequest'
  },
  responseType: "json",
  withCredentials: true,
};

const apiErrorMessage = (error) => {
  switch (error.response?.status) {
    case 400:
      return "入力値が正しくありません";
    case 401:
      return "ログインしてない、または確認パスワードが正しくありません";
    default:
      return "通信またはサーバーでエラーが発生しました";
  }
}

// <FontAwesomeIcon icon={faArrowRight} />

function ArrowLink({text, onClick}) {
  return (
    <a href="#" onClick={e => {e.preventDefault();  onClick();}}
      style={{
        color: "#1676cf", fontWeight: "normal",
        fontSize: 12, marginLeft: 20} }>

      <span class="fa fa-arrow-right" /> {text}
    </a>
  );
}

export default function PSChargeNameComponent({ charge_name, api_base_url, reload_page_if_successful }) {
  const [chargeName, setChargeName] = useState("");
  const [chargeNameInput, setChargeNameInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [show, setShow] = useState(false);

  const closeDialog = () => {
    setShow(false);
  }
  const showDialog = () => {
    setChargeNameInput(chargeName);
    setShow(true);
  }

  const changeChargeName = async() => {
    try {
      await axios.post(`${api_base_url}/companies/change_charge_name`,
        {name: chargeNameInput}, apiConfig);

      setChargeName(chargeNameInput);
      setErrorMessage("");
      closeDialog();
      if (reload_page_if_successful) { window.location.reload(); }
    } catch (e) {
      setErrorMessage(apiErrorMessage(e));
    }
  }

  useEffect(() => {
    setChargeName(charge_name);
  }, [charge_name]);

  return (
    <>
      <span>{ chargeName }</span>
      <ArrowLink text="担当者名の変更" onClick={showDialog} />

      <Modal show={show} onHide={closeDialog} fullscreen="md-down" animation={true} contentClassName="rounded-4 shadow h-auto">
        <Modal.Header closeButton  className="p-5 pb-4 border-bottom-0">
          <Modal.Title as="h3" className="fw-bold"> <FontAwesomeIcon icon={faUser} /> 担当者名の変更</Modal.Title>
        </Modal.Header>

        <Form onSubmit={e => { e.preventDefault(); changeChargeName() }}>
          <Modal.Body className="px-5">
            {errorMessage.length > 0 &&
              <Alert variant="warning">{errorMessage}</Alert>
            }
            <Form.Group className="">
              <Form.Control type="text" className="fs-6 py-3 rounded-2 h-auto"
                required
                placeholder="新しい担当者名"
                value={chargeNameInput} onChange={(e) => setChargeNameInput(e.currentTarget.value)} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="p-5 pt-2 pb-4 border-top-0 text-center">
            <Button variant="primary" type="submit" className="w-100 btn-lg">
              担当者を変更
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

import { useEffect, useState } from 'react'
//import { useNavigate } from 'react-router-dom';
import React from 'react';

import { Container, Row, Col, Form, Button, Card, Table, InputGroup } from 'react-bootstrap'
import { Pagination } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library }         from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { faSearch, fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(far, fas, fab)

import PSAPIClient  from './common/PSAPIClient.js'
import PSTimeUtil   from './common/PSTimeUtil.js'

import PSPublishRightsCPHoldersComponent    from './music_rights/PSPublishRightsCPHoldersComponent.js'
import PSPublishRightsInfoConfirmComponent  from './music_rights/PSPublishRightsInfoConfirmComponent.js'

import styles from './PSPublishRightsListComponent.module.scss'
//import PSEditableText from './common/PSEditableText.js'

/**
 * Get the URL parameter value
 *
 * @param  name {string} パラメータのキー文字列
 * @return  url {url} 対象のURL文字列（任意）
 */
function getParam(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const Item = ({ page, onClick }) => <Pagination.Item onClick={() => onClick(page)}>{page}</Pagination.Item>

function PSPagination({
  page = 1,
  pageCount = 1,
  onClick = () => { },
  ...otherProps
}) {

  return (
    <Pagination {...otherProps}>
      <Pagination.First onClick={() => onClick(1)} />
      <Pagination.Prev onClick={() => onClick(Math.max(page - 1, 1))} />
      {page - 2 >= 1 && <Item page={page - 2} onClick={onClick} />}
      {page - 1 >= 1 && <Item page={page - 1} onClick={onClick} />}
      <Pagination.Item active={true}>{page}</Pagination.Item>
      {page + 1 <= pageCount && <Item page={page + 1} onClick={onClick} />}
      {page + 2 <= pageCount && <Item page={page + 2} onClick={onClick} />}
      <Pagination.Next onClick={() => onClick(Math.min(page + 1, pageCount))} />
      <Pagination.Last onClick={() => onClick(pageCount)} />
    </Pagination>
  );
}

export default function PSPublishRightsListComponent({ api_base_url }) {

  //const navigate = useNavigate();

  const [query, setQuery]         = useState('');
  const [pageIndex, setPageIndex] = useState(1);
  const [works, setWorks]         = useState([]);
  const [pagerInfo, setPagerInfo] = useState({pageIndex:1, pageSize:0, total:0});

  useEffect(() => {
    (async () => {
      
      search({query:(getParam("k") || ""), pageIndex:(getParam("p") || 1)})
      
    })()
  }, [])

  const search = async ({query, pageIndex}) => {

    setQuery(query)

    try {
      const url = api_base_url + "/music_rights/search?k=" + encodeURIComponent(query) + "&page=" + pageIndex;
      //console.debug(url)
      const res = await PSAPIClient.get({url})

      const works = res.body.works
      //console.debug(works)
      
      setPagerInfo({
        pageIndex:pageIndex, 
        pageSize: res.body.num, 
        total:    res.body.total
      })
      setWorks(works)
  
      {
        let pageUrl = location.pathname + "?k=" + encodeURIComponent(query)
        if (pageIndex > 1) {
          pageUrl += "&p=" + pageIndex
        }
        history.replaceState('','', pageUrl);
      }

      window.scrollTo(0, 0)
    } catch (e) {
      console.error(e)
      //console.info(e.response.data)
      throw e
    }
  }
  
  // "musicRightsId": 153,
  // "workCode": "00364428",
  // "title": "さよなら夏の日",
  // "rightsGroup": 1,
  // "caution": null,
  // "areaDistinguish": 10,
  // "authority": "PO(出版者作品届)",
  // "lastCheckTime": "2023-06-26T14:17:32.000Z",

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    //console.log('Form submitted with query:', query);
    search({query, pageIndex})
  };
  
  
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit}>
              <InputGroup className="mb-3"  style={{margin:"0 auto", width:360}}>
                <Form.Control type="text" placeholder="作品コード、曲名" style={{height:38, width:200}} value={query} onChange={handleInputChange} />
                <Button variant="primary" type="submit"><FontAwesomeIcon icon="fa-solid fa-magnifying-glass" /> 検索</Button>
              </InputGroup>
            </Form>
          </Col>
        </Row>
      </Container>

      <div style={{margin:"20px 0 0 0"}}>      
        { (works != null && works.length != 0) ?
          (
            <>
              <div>
                {/* { pagerInfo.pageIndex } / { pagerInfo.pageSize } / { pagerInfo.total } / { Math.ceil(pagerInfo.total/pagerInfo.pageSize) } */ }
                <PSPagination page={pagerInfo.pageIndex} pageCount={Math.ceil(pagerInfo.total/pagerInfo.pageSize)} onClick={(x) => { search({query, pageIndex:x}) }} />
              </div>
              
              <table style={{margin:"20px 0 0 0"}}>
                <thead>
                  <tr>
                    <th style={{width:40}}></th>
                    <th style={{width:124}}>作品コード</th>
                    <th>タイトル</th>
                    <th style={{width:280}}>著作者/出版者</th>
                    <th style={{width:159}}>最終確認日</th>
                    <th style={{width:280}}>注意メモ</th>
                  </tr>            
                </thead>
                
                <tbody>
                  {
                    works.map((work, index) => (
                      <tr key={`work-${ work.musicRightsId }`} style={{borderBottom:"1px solid #DDD"}}>
                        <td style={{textAlign:"right", fontSize:11}}>{ (pagerInfo.pageIndex-1) * pagerInfo.pageSize + index + 1 }</td>
                        <td style={{fontSize:11}}>
                          <a target="_blank" href={`https://www2.jasrac.or.jp/eJwid/main?trxID=F20101&WORKS_CD=${work.workCode}&subSessionID=001&subSession=start`}>{ work.workCode } <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" /></a>
                          <a target="_blank" href={`/scores?keyword=${encodeURI(work.workCode)}`} style={{margin:"0 0 0 5px"}}><FontAwesomeIcon icon="fa-solid fa-magnifying-glass" /></a>
                        </td>
                        <td>{ work.title }</td>
                        <td><PSPublishRightsCPHoldersComponent api_base_url={api_base_url}  authors={work.authors} /></td>
                        <td style={{fontSize:11}}>{ PSTimeUtil.getRelativeTime(work.lastCheckTime)  }（{ new Date(work.lastCheckTime).toLocaleDateString("ja-JP", {year: "numeric",month: "2-digit", day: "2-digit"}).replaceAll('/', '-') }）</td>
                        <td>
                          <PSPublishRightsInfoConfirmComponent api_base_url={api_base_url} work_code={ work.workCode} confirmCautionId={work.confirmCautionId} confirmComment={work.confirmComment} />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>

              <div style={{margin:"40px 0 0 0"}}>
                <PSPagination page={pagerInfo.pageIndex} pageCount={Math.ceil(pagerInfo.total/pagerInfo.pageSize)} onClick={(x) => { search({query, pageIndex:x}) }} />

              </div>
            </>
          )
          :
          ""
        }
      </div>
    </div>
  )      
}
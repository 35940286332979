import React, { useState, useEffect } from 'react';
import { Card, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library }         from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(far, fas, fab);
import PSAPIClient from '../common/PSAPIClient';

export default function PSPublishAccountMailAddressChangeConfirmComponent({ verification_text, api_base_url }) {
  const [errorMessage, setErrorMessage] = useState("");

  const veifyMailAddress = async(v) => {
    try {
      await PSAPIClient.post({
        url: `${api_base_url}/accounts/mail_address/confirm_change`,
        data: {v}
      });
      setErrorMessage("");
    } catch (e) {
      console.error(e);
      if (e?.message?.match(/Bad Request/)) {
        setErrorMessage("確認済みか、時間切れ、または確認トークンが正しくありません");
      } else {
        setErrorMessage(PSAPIClient.errorMessage(e));
      }
    }
  }

  useEffect(() => {
    (async () => {
      await veifyMailAddress(verification_text);
    })();
  }, [verification_text]);

  return (
    <div className="fill d-flex align-items-center justify-content-center">
      <Card className="w-100 border-0">
        <Card.Title as="h3" className="fw-bold p-4 pb-3">
          <FontAwesomeIcon icon="fa-solid fa-envelope" />
          メールアドレスの変更
        </Card.Title>
        <Card.Body style={{fontSize: 15}}>
          {errorMessage.length > 0 ?
            <Alert variant="warning" className="py-5">{errorMessage}</Alert> :
            <Alert variant="info" className="py-5">メールアドレスを変更しました。</Alert>
          }
       </Card.Body>
       <Card.Footer className="pb-4 border-top-0 bg-white text-center">
          <Button variant="secondary" href="/accounts" className="text-white text-decoration-none">
            アカウント情報へ戻る
          </Button>
        </Card.Footer>
      </Card>
    </div>
  );
}


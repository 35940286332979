import React, { useState } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { library }         from '@fortawesome/fontawesome-svg-core'
// import { far } from '@fortawesome/free-regular-svg-icons'
// import { fas } from '@fortawesome/free-solid-svg-icons'
// import { fab } from '@fortawesome/free-brands-svg-icons'
// library.add(far, fas, fab)

function calcRows({text}) {
  // const rows1 = text.split('\n').length;
  // const rows2 = Math.floor(text.length/18) + 1;
  // const rows = Math.max(rows1, rows2);

  let rows = 0;
  let charNumPerLine = 18;
  text.split('\n').forEach((line) => {
    rows += Math.floor(line.length/charNumPerLine) + 1;
  })
  
  return rows
}

function PSEditableText({text, didEditTextHandler}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  text = text ? text : '';
  const [textValue, setTextValue] = useState(text);
  const rowCount = calcRows({text:text})
  const [rows, setRows] = useState(rowCount);

  const handleDivClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    const text = e.target.value
    
    setTextValue(text);
    setRows(calcRows({text}));
  };

  const handleInputBlur = () => {
    setIsEditing(false);
    
    didEditTextHandler(textValue);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const sharedStyles = {
    minHeight: '1em',
    fontSize: '12px',
    lineHeight: '1.5',
    padding: '2px',
    backgroundColor: isEditing ? 'white' : (isHovered ? '#F3F3F3' : 'transparent'),
  };

  return (
    <div 
      onClick={handleDivClick} 
      onMouseEnter={handleMouseEnter} 
      onMouseLeave={handleMouseLeave} 
      style={sharedStyles}
    >
      {
        isEditing ? (
          <textarea 
            value={textValue} 
            onChange={handleInputChange} 
            onBlur={handleInputBlur} 
            autoFocus 
            rows={rows}
            style={{...sharedStyles, width: '100%', border: '1px solid #0083EA', borderRadius: '4px', outline: 'none', resize: 'none'}}
          />
        ) : 
        textValue ?
        (
          <div style={{position:"relative"}}>
            <span>{ textValue.split('\n').map((line, index) => <span key={index}>{line}<br /></span>) }</span>
            <span style={{background:"#F3F3F3", padding:2, visibility: (isHovered ? 'visible' : 'hidden'), fontSize:10, color:"#666", position:"absolute", top:0, right:0}}><FontAwesomeIcon icon="fa-regular fa-pen-to-square" /> 編集</span>
          </div>
        )
        :
        (
          <span style={{fontSize:10, color:"#777"}}>テキストを編集</span>
        )
      }
    </div>
  );
}

export default PSEditableText;
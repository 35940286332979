import { Container, Row, Col, Form, Button, Card, Table } from 'react-bootstrap'
import { useEffect, useState, useRef } from 'react'

import React from 'react';

import styles from './PSPublishNewWorkcodeListComponent.module.scss'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library }         from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(far, fas, fab)

import PSAPIClient  from '../common/PSAPIClient.js'

//export default function PSPublishNewWorkcodeListComponent({ api_base_url }) {
export default function PSPublishNewWorkcodeListComponent({ }) {

  //const [workcodes, setWorkcodes] = useState([])
  const [workInfos, setWorkInfos] = useState(null)

  useEffect(() => {
    (async () => {
      try {
        let url = "https://script.google.com/macros/s/AKfycbxHzGSDAReCBT_KchGqDW4PFHFF58GtRQ-DGvgMesOpgSGdWg2HtxTfA5fQcjtVGrRs0A/exec"

        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "text/plain;charset=utf-8",
          },
          redirect: "follow", // manual, *follow, error
        });
    
        const body = await res.json()
        //console.log("body=" + JSON.stringify(body))

        setWorkInfos(body)
      } catch (e) {
        console.error(e)
        console.info(e.response.data)
        throw e
      }
    })()
  }, [])
    
  const personText = function(workInfo) {
    
    if(workInfo.artist) {
      if(workInfo.composer) {
       return "（" + workInfo.artist + " / " + workInfo.composer + " 作曲）" 
      }else {
        return "（" + workInfo.artist + "）"
      }
    }else{
      if(workInfo.composer) {
        return "（" + workInfo.composer + " 作曲）" 
       }else {
         return ""
       }
    }
  }

  const handleFocus = (event) => event.target.select();
  
  return (
    <Card style={{ }}>
      <Card.Header as="h5"><FontAwesomeIcon icon="fa-solid fa-file" /> 新作の作品コード</Card.Header>
      <Card.Body>
        {
          workInfos ?
          workInfos.length != 0 ?
          workInfos.map((workInfo, index) =>
            (
              <div style={{padding:"15px 0", borderBottom:(index+1!=workInfos.length ? "1px solid #DDD" : "none")  }} key={`work-${index}`}>
                <Card.Title style={{fontSize:12, display:"flex"}}>
                  <div style={{fontWeight:"bold"}}>{workInfo.title}</div>
                  <div>{ personText(workInfo) }</div>
                </Card.Title>
                <Card.Text style={{display:"flex"}}>
                  <div style={{padding:"11px 0 0 0"}}>
                    <span className={`${styles.group_label} ${workInfo.group == "JASRAC" ? styles.jasrac : styles.nextone}` }>{ workInfo.group }</span>
                  </div>
                  {/* <span className={styles.workcode}>{ workInfo.workCode }</span> */}
                  <Form.Control type="input" value={workInfo.workCode} className={styles.workcode} onFocus={handleFocus} />
                </Card.Text>
              </div>
            )
          ) : "ありません"
          : ""
        }
      </Card.Body>
    </Card>
  )      
}


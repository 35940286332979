export default class PSPublishScoreSuspendReasonText {
  
  static getSuspendReason({reasonId, scoreId}) {
    
    const editMainUrl = `https://publish.piascore.com/scores/${scoreId}/edit_main`;
    const editRegionUrl = `https://publish.piascore.com/scores/${scoreId}/edit_region`;
    const editSubUrl = `https://publish.piascore.com/scores/${scoreId}/edit_sub`;
    const editOptionUrl = `https://publish.piascore.com/scores/${scoreId}/edit_option`;
  
    let title = '';
    let content = '';
  
    switch (reasonId) {
      case 0:
        title = '';
        content = '';
        break;
      case 1010:
        title = '著作権管理情報が登録されていません';
        content = `こちらは著作権管理会社による著作権管理楽曲、または各著作権者からの許諾が必要な曲です。つきましては、作品コードなどの<span style="font-weight:bold;">著作権管理情報</span>の登録が必要です。<br />
                  <br />
                  また、改変などある場合、権利者の許諾が必要な場合もあります。<br />
                  <br />
                  詳しくは下記のリンクをご参照下さい。<br />
                  <ul>
                    <li><a target="_blank" href="https://publish.piascore.com/rights/intro">販売登録できる楽曲</a></li>
                  </ul>
                  ご確認いただきましたら、「<a href="${editOptionUrl}">4. 著作権管理情報</a>」より著作権情報を登録頂き、改めて販売申請頂けますでしょうか。<br />`;
        break;
      case 1020:
        title = '外国作品です';
        content = `こちらは「外国作品」となります。<br /><br />「<a href="${editOptionUrl}">4. 著作権管理情報</a>」より修正してください。また、サブ出版から許諾を取得し、楽譜ファイルへのコピーライト表記の追加、並びに「4. 著作権管理情報」より許諾書コピーのPDFファイルをアップロードしてください。<br /><br />その後、再度「販売申請する」をクリックして申請してください。`;
        break;
      case 1030:
        title = '作品コードが間違っています';
        content = `<a target="blank" href="http://www2.jasrac.or.jp/eJwid/">JASRAC</a>、または <a target="blank" href="https://search.nex-tone.co.jp/">NexTone</a>で作品コードを検索し、 「<a href="${editOptionUrl}">4. 著作権管理情報</a>」より修正してください。<br /><br />その後、再度「販売申請する」をクリックして申請してください。`;
        break;
      case 1040:
        title = 'JASRACでは管理されていません';
        content = `JASRACの管理楽曲ではありません。<br /><br /><a target="blank" href="https://search.nex-tone.co.jp/">NexTone</a>の方でお調べいただき、「<a href="${editOptionUrl}">4. 著作権管理情報</a>」を修正してください。<br /><br />その後、再度「販売申請する」をクリックして申請してください。`;
        break;
      case 1050:
        title = '編曲者からの配信許諾の確認が必要です';
        content = `アレンジが入ったライブ音源や動画などからの採譜と考えられる場合、その編曲者からの出版許諾が必要な場合がございます。<br /><br />
                  <h2>編曲者からの許諾を得ている場合</h2>
                  <ol>
                    <li>「4. 著作権管理情報」より許諾書コピーのPDFファイルをアップロードしてください</li>
                    <li>再度、「販売申請する」をクリックして申請してください</li>
                  </ol>
                  <h2>編曲者からの許諾を得ていない場合</h2>
                  <ol>
                    <li>編曲者から、インタラクティブ配信の許諾を取得してください</li>
                    <li>「4. 著作権管理情報」より許諾書コピーのPDFファイルをアップロードしてください</li>
                    <li>再度、「販売申請する」をクリックして申請してください</li>
                  </ol>
                  <h2>編曲者がいない場合</h2>
                  <ol>
                    <li>編曲者について、正確な情報をお調べ頂いた上でご登録をお願いいたします。CDの販売元や放送されたテレビ局などにお問い合わせし、ご確認いただけますようお願いいたします。</li>
                    <!-- <li>編曲者の氏名や番組名などを表記しないなど、誤解を招かないような曲名・説明の表記を修正してください</li> -->
                    <li>再度、「販売申請する」をクリックして申請してください</li>
                  </ol>
                  ご確認後、販売承認させて頂きます。`;
        break;
      case 1060:
        title = '出版・改変許諾の確認が必要です';
        content = `本楽曲の著作権者（作曲者、作詞者）からの、編曲に関する許諾の確認が必要です。<br /><br />
                  <h2>許諾を取得している場合</h2>
                  <ol>
                    <li>「4. 著作権管理情報」より許諾書コピーのPDFファイルをアップロードしてください</li>
                    <li>再度、「販売申請する」をクリックして申請してください</li>
                  </ol>
                  <h2>許諾を取得していない場合</h2>
                  <ol>
                    <li>作曲者（曲を使う場合）より、編曲に関する許諾を取得してください（通常は、JASRACに連絡先を問い合わせ（<a href="https://secure.okbiz.okwave.jp/faq-jasrac/helpdesk?category_id=39&site_domain=jp">JASRAC 問い合わせ先</a>）、直接ご本人に許諾をお願いすることになります）</li>
                    <li>許諾が取得できましたら、「4. 著作権管理情報」より許諾書コピーのPDFファイルをアップロードしてください</li>
                    <li>再度、「販売申請する」をクリックして申請してください</li>
                  </ol>
                  ご確認後、販売承認させて頂きます。`;
        break;
      case 1070:
        title = '日本国内でのみパブリックドメインの楽曲です';
        content = `海外では著作権が切れていない著作権者が含まれております関係上（主に、死後50〜70年の著作権者が関わります）、海外では販売できません。<br /><br />「<a href="${editMainUrl}">1. 基本情報</a>」の「販売対象国」を「日本」に修正し、再度「販売申請する」をクリックして申請してください。</li>`;
        break;
      case 2010:
        title = '外国作品の出版許諾の確認が必要です';
        content = `こちらは外国作品となるため、サブ出版社からの出版許諾が取得されていることを確認させていただきます。<br /><br />
                  <h2>取得済みの場合</h2>
                  <ol>
                    <li>楽譜ファイルに、サブ出版社から指定のコピーライト表記を追加していることを確認してください</li>
                    <li>「4. 著作権管理情報」より許諾書コピーのPDFファイルをアップロードしてください</li>
                    <li>再度、「販売申請する」をクリックして申請してください</li>
                  </ol>
                  <h2>取得済みではない場合</h2>
                  <ol>
                    <li>サブ出版社から許諾を取得してください<br />
                    取得方法などについては、下記のページをご覧ください。
                    <ul>
                      <li><a target="_blank" href="https://publish.piascore.com/rights/foreign">著作権管理状況の確認手順（外国曲）</a></li>
                      <li><a target="_blank" href="https://publish.piascore.com/rights/inquiry">出版・改変許諾の問い合わせ方法</a> ※ 「外国曲の場合」をご覧ください。</li>
                    </ul>
                    </li>
                    <li>楽譜ファイルに、サブ出版社から指定のコピーライト表記を追加してください</li>
                    <li>「4. 著作権管理情報」より許諾書コピーのPDFファイルをアップロードしてください</li>
                    <li>再度、「販売申請する」をクリックして申請してください</li>
                  </ol>
                  <br />
                  ご確認後、販売承認させて頂きます。`;
        break;
      case 2020:
        title = 'コピーライト表記が必要です';
        content = `こちらの楽曲には、出版社の許諾表記（コピーライト表記）が必要です。 <br />
                  <br />
                  コピーライト表記を追加した楽譜ファイル（PDF）を、「<a href="${editSubUrl}">3. 配信情報</a>」より再度アップロード頂き、再度「販売申請する」をクリックして申請してください。<br />
                  <br />
                  ご確認後、販売承認させて頂きます。`;
        break;
      case 3010:
        title = '出版許諾の確認が必要です';
        content = `こちらの楽曲は、出版許諾の確認が必要な楽曲となります。<br />
                  <br />
                  楽曲の権利者に、下記の権利者を含む場合、出版許諾の確認が必要です。<br />
                  <ul>
                    <li>ミュージックマインド お問い合わせ先は<a href="https://secure.okbiz.okwave.jp/faq-jasrac/helpdesk?category_id=39&site_domain=jp">JASRAC</a>にご確認下さい</li>
                    <li>NHK出版 <a href="https://www.nhk-book.co.jp/contact/edit.html?q=2">お問い合わせ</a></li>
                    <li>セントラルミュージック <a href="http://www.jcm.gr.jp/music/">お問い合わせ</a></li>
                    <li>東宝ミュージック</li>
                    <li>ワタナベエンターテインメント</li>
                  </ul>
                  <br />
                  また、他の出版社でも出版許諾が必要な場合があります。下記の「メッセージ」をお読みください。
                  <br /><br />
                  <h2>取得手順</h2>
                  <ol>
                    <li>出版社から許諾を取得してください<br />
                    取得方法などについては、下記のページをご覧ください。
                    <ul>
                      <li><a target="_blank" href="https://publish.piascore.com/rights/jasrac">著作権管理状況の確認手順（JASRAC）</a></li>
                      <li><a target="_blank" href="https://publish.piascore.com/rights/nextone">著作権管理状況の確認手順（NexTone）</a></li>
                      <li><a target="_blank" href="https://publish.piascore.com/rights/inquiry">出版・改変許諾の問い合わせ方法</a> ※ 「内国曲の場合」をご覧ください。</li>
                    </ul>
                    </li>
                    <li>楽譜ファイルに、出版社から指示に従ってコピーライト表記を追加してください ※ 必要ない場合もございます</li>
                    <li>「4. 著作権管理情報」より許諾書コピーのPDFファイルをアップロードしてください</li>
                    <li>再度、「販売申請する」をクリックして申請してください</li>
                  </ol>
                  <br />
                  ご確認後、販売承認させて頂きます。`;
        break;
      case 3020:
        title = '出版許諾の確認が必要です';
        content = `出版社による著作権管理がない楽曲の編曲については、著作者である「作曲者」「作詞者」からの編曲許諾の取得が必要となります。
                  <br /><br />
                  著作者より許諾の方は取得済みでしょうか。販売にあたり、「4. 著作権管理情報」より許諾書コピーのPDFファイルをアップロードしてください。
                  <br /><br />
                  <h2>取得手順</h2>
                  <ol>
                    <li>著作者より編曲許諾を取得してください。<br />
                    ホームページ等よりご連絡をお取りください。
                    </li>
                    <li>楽譜ファイルに、コピーライト表記を追加してください ※ 必要ない場合もございます</li>
                    <li>「4. 著作権管理情報」より許諾書コピーのPDFファイルをアップロードしてください</li>
                    <li>再度、「販売申請する」をクリックして申請してください</li>
                  </ol>
                  <br />
                  ご確認後、販売承認させて頂きます。`;
        break;
      case 4010:
        title = 'ブライト・ノート・ミュージック（旧ジャニーズ出版）の楽曲です';
        content = `ブライト・ノート・ミュージックの楽曲については、ブライト・ノート・ミュージックとの取り決めにより下記の注意事項がございます。各項目をチェック頂き、修正してください。
                  <br />
                  <ul>
                    <li>「<a href="${editRegionUrl}">2. 楽曲情報</a>」の「曲名」には、正しい楽曲名を記載してください（アルファベット表記がカタカナ表記になっているものなどはNG）</li>
                    <li>「<a href="${editRegionUrl}">2. 楽曲情報</a>」の「曲名」、楽譜本体に、アーティスト名を表記しないでください</li>
                    <!-- <li>「<a href="${editRegionUrl}">2. 楽曲情報</a>」の「曲名」「説明」に、アーティスト名を表記しないでください</li> -->
                    <!-- <li>「<a href="${editRegionUrl}">2. 楽曲情報</a>」の「Youtube URL」に、値を入力しないでください（YouTube などへの模範演奏・参考音源のアップロードが禁止されております）</li> -->
                    <li>「<a href="${editSubUrl}">3. 配信情報</a>」の人名に、正しく全ての作曲者・作詞者の情報を入力してください</li>
                  </ul>
                  <br />
                  また、下記に該当する楽曲については販売することが出来ません。
                  <ul>
                    <li>メドレー</li>
                    <li>ソロ曲、ユニット曲の楽譜出版</li>
                    <li>CD発売前の楽曲</li>
                  </ul>
                  修正が完了しましたら、再度「販売申請する」をクリックして申請してください。
                  <br /><br />
                  また、ブライト・ノート・ミュージックの楽曲については、こちらで出版・編曲に関する許諾の代理申請（ブライト・ノート・ミュージック部分のみ）を行っております。販売可能楽曲につきましては、出版者様の方での許諾申請・コピーライト表記は不要です。<br /><br />
                  なお代理申請作業は、おおよそ1〜2週間ごとに行っておりますので、販売承認まで時間がかかります。ご了承ください。<br />`;
        break;
      case 4020:
        title = '久石譲氏、またはスタジオジブリの楽曲です';
        content = `久石譲氏、またはスタジオジブリの楽曲には、編曲やコピーライト表記、タイトルのつけかたに決まりがございます。下記のいずれかの理由により、楽曲データの修正が必要です。
                  <ul>
                    <li>原曲のイメージ・雰囲気を損ねない編曲、採譜ミス等に気をつけてください（メドレーや曲途中でのぶつ切り等は禁止です）</li>
                    <li>「<a href="${editRegionUrl}">2. 楽曲情報</a>」の「曲名」には、正しい楽曲名を記載してください（アルファベット表記がカタカナ表記になっているものなどはNG）</li>
                    <!-- <li>「<a href="${editSubUrl}">3. 配信情報</a>」の人名、およびPDFファイルに、「作曲 久石譲」とあわせて、編曲者の氏名を明記してください</li> -->
                    <li>「<a href="${editSubUrl}">3. 配信情報</a>」の人名、およびPDFファイルに、作曲者名の表記（例:作曲 久石譲）とあわせて、編曲者（楽譜作成者）の氏名を明記してください</li>
                    </ul>
                    修正しましたら、再度「販売申請する」をクリックして申請してください。
                    <br /><br />
                    また、スタジオジブリの楽曲については、こちらで出版・編曲に関する許諾の代理申請（スタジオジブリ部分のみ）を行っております。販売可能楽曲につきましては、出版者様の方での許諾申請・コピーライト表記は不要です。<br /><br />
                    なお代理申請作業は、おおよそ1〜2週間ごとに行っておりますので、販売承認まで時間がかかります。ご了承ください。<br />`;
          break;
      case 4030:
        title = '販売者IDの取得・登録をしてください';
        content = `株式会社日音社が管理する外国曲（日音Synch事業部、ピアーミュージック、ホットワイヤー ）は、許諾の代理申請をいたします。<br />「<a target="_blank" href="https://publish.piascore.com/accounts/license_agency">許諾の代理申請について</a>」をお読みいただき、販売者IDの取得をお願いいたします。`;
        break;
      case 4050:
        title = 'コピーライトを記載するスペースを空けてください';
        content = `代理申請をした楽譜の1ページ目下部にコピーライトを表記します。<br />1ページ目下部は、2センチ程度スペースを空けてください。`;
        break;
      case 5010:
        title = 'YouTube IDが間違っています';
        content = `「<a href="${editRegionUrl}">2. 楽曲情報</a>」より修正し、再度「販売申請する」をクリックして申請してください。`;
        break;
      case 5020:
        title = '楽曲の説明に、歌詞を掲載';
        content = `楽曲の説明に、掲載が掲載されています。<br /><br />「<a href="${editRegionUrl}">2. 楽曲情報</a>」より削除し、再度「販売申請する」をクリックして申請してください。`;
        break;
      case 5110:
        title = '「作曲者」にエラーがあります';
        content = `作曲者について、誤っている、または不足しています。全ての作曲者について、正しい表記を記載してください。<br /><br />「<a href="${editSubUrl}">3. 配信情報</a>」の人名より追加・修正し、再度「販売申請する」をクリックして申請してください。`;
        break;
      case 5120:
        title = '「作詞者」にエラーがあります';
        content = `作詞者について、誤っている、または不足しています。全ての作詞者について、正しい表記を記載してください。<br /><br />「<a href="${editSubUrl}">3. 配信情報</a>」の人名より追加・修正し、再度「販売申請する」をクリックして申請してください。`;
        break;
      case 5130:
        title = '「アーティスト」にエラーがあります';
        content = `アーティストについて、誤っています。全てのアーティストについて、正しい表記を記載してください。<br /><br />「<a href="${editSubUrl}">3. 配信情報</a>」の人名より追加・修正し、再度「販売申請する」をクリックして申請してください。`;
        break;
      case 5140:
        title = '「アレンジャー」にエラーがあります';
        content = `アレンジャー（編曲者）について、誤っている、または不足しています。全てのアレンジャー（編曲者）について、正しい表記を記載してください。<br /><br />「<a href="${editSubUrl}">3. 配信情報</a>」の人名より追加・修正し、再度「販売申請する」をクリックして申請してください。`;
        break;
      case 5150:
        title = '楽譜上には作曲者・作詞者・編曲者を正しく記載してください';
        content = `作曲者・作詞者・編曲者について、正しい人名を記載してください。<br /><br />作曲者・作詞者を数名記載する場合は、「作曲・〇〇  作詞・△△」とわかるように表記してください。`;
        break;
      case 5190:
        title = '楽曲データにエラーがあります';
        content = `いずれかの楽曲データについて、誤っている、または不足しています。<br /><br />詳しくは、下記の「メッセージ」をお読みください。`;
        break;
      case 5195:
        title = '確認事項があります';
        content = `詳しくは、下記の「メッセージ」をお読みください。`;
        break;
      case 9010:
        title = '他者が無料で提供する楽曲データを有料で販売することは出来ません';
        content = `こちらのデータは、IMSLPなど他者により無料に提供されている楽譜データではないでしょうか。<br /><br />その場合、Piascore 楽譜ストアでは、パブリックドメインかどうかに関わらず、有料で販売することはできません。<br /><br />申請する場合は、「<a href="${editSubUrl}">3. 配信情報</a>」より価格を「無料」とし、再度「販売申請する」をクリックして申請してください。`;
        break;
      default:
        title = 'エラー';
        content = '不明な理由です。';
    }
      
    return { title, content };
  }
  
}